import { useEffect, useState } from 'react';
import stylesPages from '../../pages/Pages.module.scss';
import styles from './Alerts.module.scss';
import AlertsMenu from './AlertsMenu';
// import { UserContext } from '../../UserContext';
import { AlertItem } from './Alerts';
import Modal from '../Ui/Modal';
import { AlertSetup } from './AlertSetup';
import { deleteAlert, deleteMultipleAlerts, saveAlert, saveMultipleAlerts } from './AlertsApi';
import { NavLink } from 'react-router-dom';
import Seo from '../Seo/Seo';
import ButtonLoad from '../Ui/ButtonLoad';
import { useUserDetails } from '../../hooks/useUserDetails';

export default function AlertsPage(props) {

    const {mutateUser, userDetails} = useUserDetails();
    const [alertsList, setAlertsList] = useState(userDetails?.alerts || [])

    const [showEditModal, setShowEditModal] = useState(false);
    const [currentAlert, setCurrentAlert] = useState({});
    const [alertAsset, setAlertAsset] = useState("");
    const [alertTimeFrame, setAlertTimeFrame] = useState("")


    useEffect(()=>{
        setAlertsList(userDetails?.alerts || [])
    }, [userDetails])

    function editThisAlert(alert, asset, timeframe) {
        setCurrentAlert(alert)
        setAlertAsset(asset)
        setAlertTimeFrame(timeframe)
        setShowEditModal(true)
    }

    async function saveThisAlert(alertSetup, asset, timeframe) {
        const alertToSave = {}
        alertToSave.config = alertSetup;
        alertToSave.message = alertSetup.message;
        delete alertToSave.config.message;
        alertToSave.rules = alertSetup.rules;
        delete alertToSave.config.rules;
        alertToSave.active = alertSetup.active || true
        delete alertToSave.config.active;
        alertToSave.frequency = alertSetup.frequency || "tf"
        delete alertToSave.config.frequency;        

        const r = await saveAlert(asset, timeframe, alertToSave);

        if (r.result) {
            updateAlertsState(r.result)
            setShowEditModal(false)
        }
    }

    async function deleteThisAlert(id, asset, selectedTimeframe) {
        if (window.confirm("Excluir alerta?")) {
            const newAlertList = [...alertsList]
            for(const assetAlert of newAlertList) {
                if(assetAlert.asset === asset && assetAlert.timeframe === selectedTimeframe) {
                    const newAlerts = assetAlert.alerts.filter(a=>a.id!==id)
                    assetAlert.alerts = newAlerts
                }
            }
            setAlertsList(newAlertList)
            await deleteAlert(asset, selectedTimeframe, id);
        }
    }

    async function toggleThisAlert(alert, asset, timeframe) {
        alert.active = !alert.active;
        await saveAlert(asset, timeframe, alert)
    }

    async function ativar(ligar) {
        console.log(ligar? "ligando" : "desligando")
        const postArray = []
        const _alertsList = [...alertsList]
        for(const a of _alertsList) {
            for(const al of a.alerts) {
                al.active = ligar
                const alertConfig = {...al};
                postArray.push({asset: a.asset, timeframe: a.timeframe, id: al.id || null, alertConfig})
            }
        }
        await saveMultipleAlerts(postArray);
        await mutateUser();

        // setAlertsList([..._alertsList])

        return postArray;
    }

    async function removeAll() {
        if (!window.confirm("Tem certeza que quer remover todos os seus alertas?")) {
            return;
        }
        const postArray = []
        for(const a of alertsList) {
            for(const al of a.alerts) {
                postArray.push({asset: a.asset, timeframe: a.timeframe, id: al.id})
            }
        }
        await deleteMultipleAlerts(postArray);
        await mutateUser();
        return postArray;
    }

    function updateAlertsState(newAlertObj) {
        const newAlerts = [...alertsList]       
        // update alertsList
        for(const a of newAlerts) {
          if((a.timeframe === newAlertObj.timeframe && a.asset === newAlertObj.asset)) {
            a.alerts = newAlertObj.alerts
          }
        }
        setAlertsList(newAlerts)
    }


    return (<>
    <Seo
        title="Alertas"
        description="Todos os seus alertas listados em um só lugar"
        name="Agulhada.com"
        type="website" />
        <header className={`header`}>
            <h1 className="page-name">Alertas</h1>
        </header>
        <div className={stylesPages.container}>
            <AlertsMenu />
            <h2 className='text-3xl font-medium my-4'>Meus alertas</h2>
            { alertsList?.length === 0 &&
                <div>
                    <p>Não há alertas configurados.</p>
                    <p>Para configurar alertas, entre na <NavLink to="/app">lista de ativos</NavLink>, escolha o ativo e clicque no ícone em formato de sino.</p>
                </div>
            }

            { alertsList?.length > 0 &&
                <div className="mb-4">
                    <div className='inline-block w-48 text-center'>
                        <ButtonLoad className="m-2 hover:text-orange-600" svg={{height:"20px", width:"30px"}} onClick={async()=>{await ativar(false)}}>Ligar todos</ButtonLoad>
                    </div>
                    <div className='inline-block w-48 text-center'>
                        <ButtonLoad className="m-2 hover:text-orange-600" svg={{height:"20px", width:"30px"}} onClick={async()=>{await ativar(true)}}>Desligar todos</ButtonLoad>
                    </div>
                    <div className='inline-block w-48 text-center'>
                        <ButtonLoad className="m-2 hover:text-orange-600" svg={{height:"20px", width:"30px"}} onClick={async()=>{await removeAll()}}>Excluir todos</ButtonLoad>
                    </div>
                </div>
            }
            <div bp="grid 12@sm 6@md 4@lg">
                {alertsList?.map((a) => {
                    return (
                        <div className={`${styles.alerts} ${styles["allalerts-card"]}`} key={a._id}>
                            <h3>{a.asset} - {a.timeframe}</h3>
                            <ul className={styles.list}>
                                {a.alerts.map((alert) => {
                                    return <AlertItem
                                        key={alert.id}
                                        alert={alert}
                                        isActive={alert.active}
                                        onToggleAlert={() => { toggleThisAlert(alert, a.asset, a.timeframe) }}
                                        onEditAlert={() => { editThisAlert(alert, a.asset, a.timeframe) }}
                                        onDeleteAlert={() => { deleteThisAlert(alert.id, a.asset, a.timeframe) }} />
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
        {showEditModal &&
            <Modal
                closeHandle={() => { setShowEditModal(false) }}
                style={{ maxWidth: "95%", display: "flex", flexDirection: "column" }}
                className={`${styles["modal"]}`}
                contentStyle={{ display: "flex", flexDirection: "column", flex: "1" }}
            >
                <h3>Edit Alert</h3>
                <AlertSetup
                    currentAlert={currentAlert}
                    asset={alertAsset}
                    timeFrame={alertTimeFrame}
                    resizeContainer={() => { }}
                    onCancel={() => { setShowEditModal(false) }}
                    onSave={(a)=>saveThisAlert(a, alertAsset, alertTimeFrame)}
                    testNotify={() => { }}
                />
            </Modal>
        }
    </>)
}