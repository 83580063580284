import React, { useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

import styles from './Pages.module.scss';

const Success = () => {
    const [, setCartProducts] = useLocalStorage("cartProducts", []);
    // setCartProducts([])
    useEffect(()=>{
        setCartProducts([])
    },[setCartProducts])

    return (
        <>
            <header className={`header`}>
                <h1 className="page-name">Obrigado!</h1>
            </header>
            <div className={styles.container}>
                <h2>Obrigado por comprar no agulhada.com.</h2>
            </div>
        </>
    )
}

export default Success