import React, { useState, useContext } from 'react';
import { UserContext } from '../../UserContext';
import { NavLink } from 'react-router-dom';
// import ControlPanel from '../ControlPanel/ControlPanel';
import styles from './NavBar.module.scss'
import { ReactComponent as Logo } from "../../resources/logo_agulhada_reverse.svg";

const NavBar = (props) => {
   const user = useContext(UserContext)
   const [menuOpen, setMenuOpen] = useState(false);

   const toggleMenu = () => {
      setMenuOpen(!menuOpen);
      window.gtag('event', 'menu', {
         'event_category': 'ui_interection',
         'event_label': menuOpen ? "Closed" : "Opened"
      });
   }


   return (
      <>
         <aside className={`${styles['side-menu']} ${!menuOpen ? styles["side-menu--hidden"] : ""}`}>
            <div className={`${styles['menu-button-wrap']}`} onClick={toggleMenu}>
               <div className={`${styles['icon']} ${menuOpen ? styles["open"] : ""}`} >
                  <span></span>
                  <span></span>
                  <span></span>
               </div>
            </div>
            <div className={`${styles['logo']}`}>
               <NavLink to="/" onClick={toggleMenu}>
                  <Logo />
               </NavLink>
            </div>
            <nav className={`${styles['navbar']}`}>
               <ul className={styles["navbar__list"]}>
               { user.clientPrincipal && 
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/app/BMFBOVESPA" onClick={toggleMenu}>Lista de ativos</NavLink>
                  </li>
               }
               { user.clientPrincipal && user.clientPrincipal?.userRoles?.includes('VIP') &&
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/appuser/BMFBOVESPA" onClick={toggleMenu}>Meus ativos</NavLink>
                  </li>
               }  
               { user.clientPrincipal && 
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/notificacoes" onClick={toggleMenu}>Alertas e Notificações</NavLink>
                  </li>
               }
               {/* { user.clientPrincipal && 
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/lista-de-agulhadas-do-didi" onClick={toggleMenu}>Todas as agulhadas</NavLink>
                  </li>
               } */}
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/faq" onClick={toggleMenu}>Perguntas mais frequentes</NavLink>
                  </li>
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/saiba-mais" onClick={toggleMenu}>Como usar</NavLink>
                  </li>
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/assinatura" onClick={toggleMenu}>Assinatura</NavLink>
                  </li>
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/lojinha" onClick={toggleMenu}>Lojinha</NavLink>
                  </li>
                  {/* <li className={styles["navbar__list-item"]}>
                     <NavLink to="/mesa-proprietaria" onClick={toggleMenu}>Seja um prop trader</NavLink>
                  </li> */}
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/entre-em-contato" onClick={toggleMenu}>Entre em contato</NavLink>
                  </li>
                  <li className={styles["navbar__list-item"]}>
                     <NavLink to="/politica-de-privacidade" onClick={toggleMenu}>Política de privacidade</NavLink>
                  </li>
                  { user.clientPrincipal && 
                  <li className={styles["navbar__list-item"]}>
                     <a className='calltoaction' href="/.auth/logout" rel="noreferrer">Sair</a>
                  </li>
                  }
               </ul>
            </nav>
            {/* { user.clientPrincipal?.userRoles?.indexOf('Registered') > -1 &&
            <div>
               <ControlPanel
                  userOptions={props.userOptions}
                  timeframesHandler={props.timeframesHandler}
                  tvLayoutHandler={props.tvLayoutHandler}
               />
            </div>
            }   */}
         </aside>
      </>
   );
};

export default NavBar;