import { useContext, useRef, useState } from "react"
import styles from './Alerts.module.scss';
import { timeframeLabel } from "./AlertsApi";
import { UserContext } from "../../UserContext";



export function AlertSetup(props) {
    const user = useContext(UserContext)

    const { asset, timeFrame, currentAlert, resizeContainer } = props;
    
    const timeFrameLabel = timeframeLabel(timeFrame)

    const [alertConfig, setAlertConfig] = useState(currentAlert.config || {})
    const [rules, setRules] = useState(currentAlert.rules || [])
    const [alertMessage, setAlertMessage] = useState(currentAlert.message || "")
    const [alertFrequency, setAlertFrequency] = useState(currentAlert.frequency || user.options.alertFrequency || "tf")
    const rulesSelectBox = useRef();

    const componentMapping = { Tendencia, TendenciaAcelerante, TendenciaExistente, TendenciaEntrante, Kick, Agulhada, Alerta,Confirmacao, PontoFalso, Bollinger, BollingerMudou, Stochastic, Trix };

    const alertTypes = [
        { label: "Direcão da tendência", value: "Tendencia", keys: ["tendenciaCompra", "tendenciaVenda", "temTendencia"] }, // Compra | Venda | Inexistente
        { label: "Tendência acelerante", value: "TendenciaAcelerante", keys: ["tendenciaAcelerante"] }, // TRUE | FALSE
        { label: "Tendência existente", value: "TendenciaExistente", keys: ["temTendencia"] }, // TRUE | FALSE
        { label: "Entrou em tendência", value: "TendenciaEntrante", keys: ["tendenciaEntrante"] }, // TRUE | FALSE
        { label: "Kick do ADX", value: "Kick", keys:["tendenciaAcelerante", "mudoutendencia"] }, // TRUE | FALSE
        { label: "Agulhada", value: "Agulhada", keys: ["agulhado"] }, // Compra | Venda
        { label: "Alerta Didi Index", value: "Alerta", keys: ["alerta"] }, // Compra | Venda
        { label: "Confirmação Didi Index", value: "Confirmacao", keys: ["confirmacao"] }, // Compra | Venda
        { label: "Ponto falso", value: "PontoFalso", keys: ["pontofalso"] }, // Compra | Venda | Nenhum
        { label: "Bollinger", value: "Bollinger", keys: ["bollingerStatus"] }, // Aberto | Fechado | Paralelo
        { label: "Mudança de Bollinger", value: "BollingerMudou", keys:["bbabriu", "bbfechou"] }, // Abriu | Fechou
        { label: "Estocástico", value: "Stochastic", keys: ["stockStatus"] }, // Comprado | Vendido
        { label: "Trix", value: "Trix", keys: ["trixStatus"] }, // Comprado | Vendido
    ]

    function addRule() {
        const newRules = [...rules]
        const ruleName = rulesSelectBox.current.value
        const existingRule = newRules.findIndex(rule => rule.name === ruleName)
        if(existingRule === -1) {
            newRules.push({
                name: ruleName,
                value: null
            })
        }
        setRules(newRules)
        resizeContainer()
    }

    function removeRule(name) {
        const newRules = rules.filter(rule => rule.name !== name);
        updateAlertObject("delete", alertTypes.filter(at => at.value === name)[0].keys || [])
        setRules(newRules);
        resizeContainer()
    }

    async function saveAlert() {
        if(rules.length > 0) {
            alertConfig.message=alertMessage
            alertConfig.rules=rules
            alertConfig.active = currentAlert?.id ? currentAlert.active : true
            alertConfig.frequency = alertFrequency;
            await props.onSave(alertConfig)
            resizeContainer()
        } else {
            alert("O alerta precisa de pelo menos uma regra.")
        }
    }

    function cancelAlert() {
        props.onCancel()
        resizeContainer()
    }

    function updateAlertObject(action, data, ruleName, ruleValue) {
        const newConfig = {...alertConfig}
        if(action === "update") {
            for (const [key, value] of Object.entries(data)) {
                newConfig[key] = value;
            }
        }
        if(action === "delete") {
            for (const key of data) {
                delete newConfig[key]
            }            
        }
        setAlertConfig(newConfig)

        const newRules = [...rules];
        for(const rule of newRules) {
            if(rule.name === ruleName) {
                rule.value = ruleValue
            }
        }
        setRules(newRules);
    }

    function changeFrequency (val) {
        const options = user.options;
        options.alertFrequency = val;
        user.setOptions(options);
        setAlertFrequency(val);
    }

    return (
        <>
            <h4> { currentAlert?.id ? `Editar ` : `Criar `  } alerta {timeFrameLabel} </h4>

            {currentAlert?.id && 
                <div className={styles["alert-info"]}>    
                    <div> O alerta está {currentAlert.active?"ligado":"desligado"}</div>
                    <div> Criado em {new Date(currentAlert.created).toLocaleString()}</div>
                    <div> Alterado em {new Date(currentAlert.lastUpdate).toLocaleString()}</div>
                    {currentAlert.lastNotification && <div> Última notifição: {currentAlert.lastNotification}</div>}
                    
                </div>
            }

            <div className={styles["alert-message"]} >
                <label>
                    Mensagem:<br />
                    <input type="text" value={alertMessage} onChange={(e)=>{setAlertMessage(e.currentTarget.value)}} />
                </label>
                <fieldset>
                <legend>Frequência</legend>
                <label><input type="radio" value="15" onChange={(e)=>{changeFrequency(e.currentTarget.value)}} checked={alertFrequency==="15"} /> 15 min </label>
                <label><input type="radio" value="tf" onChange={(e)=>{changeFrequency(e.currentTarget.value)}} checked={alertFrequency==="tf"} /> Tempo-gráfico </label>
                <label><input type="radio" value="once" onChange={(e)=>{changeFrequency(e.currentTarget.value)}} checked={alertFrequency==="once"} /> Só uma vez </label>
                </fieldset>
                {/* <div><button  className={styles["alert-button"]} onClick={()=>{testNotify("", `${asset} ${alertMessage} ${timeFrameLabel}`)}}>Testar notificação.</button></div> */}
            </div>

            {/* <div>{JSON.stringify(alertConfig)}</div> */}
            <div className={styles["rule-add"]}>
                <label htmlFor={"cr"+asset}>Adicionar regra: <br />
                <select id={"cr"+asset} ref={rulesSelectBox}>
                    {alertTypes.map(function mapAlertTypes(alertType) {
                        return(<option key={alertType.value} value={alertType.value}>{alertType.label}</option>)
                    })}
                </select>
                </label>
                <button onClick={addRule}>+</button>
            </div>

            <div>
                {rules.map(function (rule) {
                    const Component = componentMapping[rule.name];
                    return (
                        <div key={rule.name} className={styles["rule-row"]}>
                            <Component className={styles["rule"]} onChange={updateAlertObject} value={rule.value} />
                            <button className={styles["delete"]} onClick={()=>{removeRule(rule.name)}}>&#x2715;</button>
                        </div>
                    )
                })
                }
            </div>

            <div className={styles["rule-actions-row"]} >
                <button className={styles["rule-save-button"]} onClick={saveAlert}>{ currentAlert?.id ? `Salvar ` : `Criar `  } alerta</button>
                <button className={styles["rule-cancel-button"]} onClick={cancelAlert}>Cancelar { currentAlert?.id ? `mudanças ` : ``  }</button>
            </div>
        </>
    )
}


function Tendencia (props) {
    const {onChange} = props
    const [tendencia, setTendencia] = useState(props.value || null);

    //tendenciaCompra | tendenciaVenda

    function changeValue(e) {
        setTendencia(e.target.value)

        let obj = {
            tendenciaCompra: false, 
            tendenciaVenda: false,
            temTendencia: false
        }

        if( e.target.value === "1" ) { obj.tendenciaCompra = true; obj.temTendencia = true }
        if( e.target.value === "-1" ) { obj.tendenciaVenda = true; obj.temTendencia = true }
        onChange("update", obj, "Tendencia", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>Direção da tendência</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={tendencia==="1"} /> Compra </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={tendencia==="-1"} /> Venda </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={tendencia==="0"} /> Sem tendência </label>
        </div>
    )
}

function TendenciaAcelerante (props) {
    // true or false
    const [tendenciaAcelerante, setTendenciaAcelerante] = useState(props.value || "")
    function changeValue(e) {
        setTendenciaAcelerante(e.target.value)
        let obj = {
            tendenciaAcelerante: false, 
        }

        if( e.target.value === "true" ) { obj.tendenciaAcelerante = true }
        props.onChange("update", obj, "TendenciaAcelerante", e.target.value)
    }
    return (
        <div className={props.className}>
            <h4>Tendência acelerante</h4>
            <label><input type="radio" value="true" onChange={changeValue} checked={tendenciaAcelerante==="true"} /> Sim </label>
            <label><input type="radio" value="false" onChange={changeValue} checked={tendenciaAcelerante==="false"} /> Não </label>
        </div>
    )
}

function TendenciaExistente (props) {
    // true or false
    const [tendenciaExistente, setTendenciaExistente] = useState(props.value || "")
    function changeValue(e) {
        setTendenciaExistente(e.target.value)
        let obj = {
            tendenciaExistente: false, 
        }

        if( e.target.value === "true" ) { obj.tendenciaExistente = true }
        props.onChange("update", obj, "TendenciaExistente", e.target.value)
    }
    return (
        <div className={props.className}>
            <h4>Tendência existente</h4>
            <label><input type="radio" value="true" onChange={changeValue} checked={tendenciaExistente==="true"} /> Sim </label>
            <label><input type="radio" value="false" onChange={changeValue} checked={tendenciaExistente==="false"} /> Não </label>
        </div>
    )
}

function TendenciaEntrante (props) {
    // tendenciaEntrante: 0 | 1
    const [tendenciaEntrante, setTendenciaEntrante] = useState(props.value || "-1")
    function changeValue(e) {
        setTendenciaEntrante(e.target.value)
        let obj = {
            tendenciaEntrante: 0, 
        }

        if( e.target.value === "1" ) { obj.tendenciaEntrante = 1 }
        props.onChange("update", obj, "TendenciaEntrante", e.target.value)
    }
    return ( 
        <div className={props.className}>
            <h4>Entrou em tendência</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={tendenciaEntrante==="1"} /> Sim </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={tendenciaEntrante==="0"} /> Não </label>
        </div>
    )
}

function Kick (props) {
    // mudoutendencia === 1 && + adx < adxadxanterior
    const [kick, setKick] = useState(props.value || null)
    function changeValue(e) {
        setKick(e.target.value)
        let obj = {}
        if( e.target.value === "1" ) { obj.tendenciaAcelerante = false; obj.mudoutendencia = 1 }
        props.onChange("update", obj, "Kick", e.target.value)
    }
    return ( 
        <div className={props.className}>
            <h4>Kick do ADX</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={kick==="1"} /> Sim </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={kick==="0"} /> Não </label>
        </div>
    )
}

function Agulhada (props) {
    // agulhado: 0 | 1 | -1
    const [agulhado, setAgulhado] = useState(props.value || "");
    function changeValue(e) {
        setAgulhado(e.target.value)

        let obj = {
            agulhado: 0, 
        }

        if( e.target.value === "1" ) { obj.agulhado = 1 }
        if( e.target.value === "-1" ) { obj.agulhado = -1 }
        props.onChange("update", obj, "Agulhada", e.target.value)

    }

    return (
        <div className={props.className}>
            <h4>Agulhada</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={agulhado==="1"} /> Compra </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={agulhado==="-1"} /> Venda </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={agulhado==="0"} /> Sem agulhada </label>
        </div>
    )
}

function Alerta (props) {
    // alerta: 0 | 1 | -1
    const [alerta, setAlerta] = useState(props.value || "");
    function changeValue(e) {
        setAlerta(e.target.value)
        let obj = {
            alerta: 0, 
        }

        if( e.target.value === "1" ) { obj.alerta = 1 }
        if( e.target.value === "-1" ) { obj.alerta = -1 }
        props.onChange("update", obj, "Alerta", e.target.value)

    }

    return (
        <div className={props.className}>
            <h4>Alerta Didi Index</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={alerta==="1"} /> Compra </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={alerta==="-1"} /> Venda </label>
        </div>
    )
}

function Confirmacao (props) {
    // confirmacao: 0 | 1 | -1
    const [confirmacao, setConfirmacao] = useState(props.value || "");
    function changeValue(e) {
        setConfirmacao(e.target.value)
        let obj = {
            confirmacao: 0, 
        }

        if( e.target.value === "1" ) { obj.confirmacao = 1 }
        if( e.target.value === "-1" ) { obj.confirmacao = -1 }
        props.onChange("update", obj, "Confirmacao", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>Confirmação Didi Index</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={confirmacao==="1"} /> Compra </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={confirmacao==="-1"} /> Venda </label>
        </div>
    )
}

function PontoFalso (props) {
    // pontofalso: 0 | 1 | -1
    const [pontofalso, setPontofalso] = useState(props.value || "");
    function changeValue(e) {
        setPontofalso(e.target.value)
        let obj = {
            pontofalso: 0, 
        }

        if( e.target.value === "1" ) { obj.pontofalso = 1 }
        if( e.target.value === "0" ) { obj.pontofalso = 0 }
        if( e.target.value === "-1" ) { obj.pontofalso = -1 }
        props.onChange("update", obj, "PontoFalso", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>Ponto Falso Didi Index</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={pontofalso==="1"} /> Compra </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={pontofalso==="-1"} /> Venda </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={pontofalso==="0"} /> Nenhum </label>
        </div>
    )
}

function Bollinger (props) {
    // bollingerStatus: 0 | 1 | -1
    const [bollingerStatus, setBollingerStatus] = useState(props.value || "");
    function changeValue(e) {
        setBollingerStatus(e.target.value)
        let obj = {
            bollingerStatus: 0, 
        }

        if( e.target.value === "1" ) { obj.bollingerStatus = 1 }
        if( e.target.value === "-1" ) { obj.bollingerStatus = -1 }
        props.onChange("update", obj, "Bollinger", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>Bollinger</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={bollingerStatus==="1"} /> Aberto </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={bollingerStatus==="-1"} /> Fechado </label>
            <label><input type="radio" value="0" onChange={changeValue} checked={bollingerStatus==="0"} /> Paralelo </label>
        </div>
    )
}

function BollingerMudou (props) {
    // bbabriu === 1 || bbafechou === -1
    const [bb, setBb] = useState(props.value || "");
    function changeValue(e) {
        setBb(e.target.value)
        let obj = {
            bbabriu: 0, 
            bbafechou: 0
        }

        if( e.target.value === "1" ) { obj.bbabriu = 1 }
        if( e.target.value === "-1" ) { obj.bbfechou = 1 }
        props.onChange("update", obj, "BollingerMudou", e.target.value)

    }

    return (
        <div className={props.className}>
            <h4>Mudança de Bollinger</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={bb==="1"} /> Abriu </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={bb==="-1"} /> Fechou </label>
        </div>
    )
}

function Stochastic (props) {
    // stockStatus true || false
    const [stockStatus, setStockStatus] = useState(props.value || "");
    function changeValue(e) {
        setStockStatus(e.target.value)
        let obj = {
             
        }

        if( e.target.value === "1" ) { obj.stockStatus = 1 }
        if( e.target.value === "-1" ) { obj.stockStatus = -1 }
        props.onChange("update", obj, "Stochastic", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>Estocástico</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={stockStatus==="1"} /> Comprado </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={stockStatus==="-1"} /> Vendido </label>
        </div>
    )
}

function Trix (props) {
    // trixStatus true || false
    const [trixStatus, setTrixStatus] = useState(props.value || "");
    function changeValue(e) {
        setTrixStatus(e.target.value)
        let obj = {
             
        }

        if( e.target.value === "1" ) { obj.trixStatus = 1 }
        if( e.target.value === "-1" ) { obj.trixStatus = -1 }
        props.onChange("update", obj, "Trix", e.target.value)
    }

    return (
        <div className={props.className}>
            <h4>TRIX</h4>
            <label><input type="radio" value="1" onChange={changeValue} checked={trixStatus==="1"} /> Comprado </label>
            <label><input type="radio" value="-1" onChange={changeValue} checked={trixStatus==="-1"} /> Vendido </label>
        </div>
    )
}

