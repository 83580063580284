import { useContext, useEffect, useState } from 'react';
import styles from './Alerts.module.scss';
import stylesPage from '../../pages/Pages.module.scss';
import stylesAsset from '../Asset/Asset.module.scss';
import AlertsMenu from './AlertsMenu';
import { saveAlert, timeframeLabel } from './AlertsApi';
import { TempoGraficoRow } from '../Asset/Asset';
import { UserContext } from '../../UserContext';
import { useUserDetails } from '../../hooks/useUserDetails';
import Seo from '../Seo/Seo';

export default function AlertsPage(props) {
    const user = useContext(UserContext)
    const [notificationsList, setNotificationsList] = useState([])
    const [carregando, setCarregando] = useState(true)
    const {mutateUser} = useUserDetails();


    useEffect(function firstUseEffect() {
        const dataFetch = async () => {
            const data = await (
                await fetch("/api/userNotifications")
            ).json();

            // set state when the data received
            const notifications = [...data.notifications];
            for(let notification of notifications) {
                notification.lastNotification = notification.lastNotification ? new Date(notification.lastNotification) : ""
            }
            notifications.sort((na, nb) => nb.lastNotification - na.lastNotification)
            setNotificationsList(notifications);
            setCarregando(false)
        };

        dataFetch();
        // eslint-disable-next-line
    }, [user]);

    async function toggleAlert(notification, active) {
        const _config = {...notification}

        _config.active = active;

        await saveAlert(notification.asset, notification.timeframe, _config)

        setTimeout(async () => {
            mutateUser('/api/getUserInfo');
        }, 1000);
    }

    async function deleteNotification(id) {
        if(id === "alll") {
            if (!window.confirm("Tem certeza que quer apagar todas as notificações?")) {
                return
            }
        }
        const response = await fetch(`/api/userNotifications`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id })
        })
        const r = await response.json();
        if(r.result.deletedCount && r.result.deletedCount > 0) {
            setNotificationsList([])
        }
        if(r.result.value?._id) {
            const notifications = notificationsList.filter(n=>n._id!==r.result.value._id)
            setNotificationsList(notifications)
        }

    }

    function alertStatus(id) {
        const alerts = []
        if(user.alerts) {
            for (const userAlerts of user.alerts) {
                for (const alert of userAlerts.alerts) {
                    alerts.push(alert)
                }
            }
        }
        const alertIds = alerts.map(a=>a.id)

        let active = false;
        if(alertIds.includes(id)) {
            const alert = alerts.find(a=>a.id===id)
            active = alert.active || false
        }

        return {
            exists: alertIds.includes(id),
            active
        }
    }

    return (<>
    <Seo
        title="Últimas notificações"
        description="Confira os últimos alertas enviados para seu telefone"
        name="Agulhada.com"
        type="website" />
        <header className={`header`}>
            <h1 className="page-name">Alertas</h1>
        </header>
        <div className={`${stylesPage.container} ${styles["log"]}`} >
            <AlertsMenu />
            <h2 className='text-2xl font-medium my-4'>Últimas 100 notificações {!carregando && notificationsList.length > 0 && <><button onClick={()=>{deleteNotification("alll")}}>apagar todas as notificacões</button></>}</h2>
            {carregando && <p>Carregando...</p>}
            {(notificationsList.length === 0 && !carregando) && <>Nenhuma notificação</>}
            <ul className={stylesAsset["asset-list"]} bp="grid 12@sm 6@md 4@lg">
                {
                    notificationsList.map((n)=>{
                        const timeFrameLabel = timeframeLabel(n.timeframe);
                        const assetData = {}
                        for(const ad of n.assetData) {
                            assetData[ad.timeframe === "60" ? "H" : ad.timeframe] = {
                                ...ad,
                                lastUpdate: n.lastNotification
                            }
                        }
                        return (
                        <li key={n._id} className={`${stylesAsset["asset-item"]} ${styles["log-item"]}`} id={`${n.asset}_${n.timestamp}`}>
                            <div className={`${stylesAsset["asset-header"]}  ${styles["asset-header"]}`}>
                                <button onClick={()=>{deleteNotification(n._id)}}>&#x2715;</button>
                                <div>{n.asset} {n.message} {timeFrameLabel}</div>
                                { alertStatus(n.id).exists && alertStatus(n.id).active && <button onClick={()=>{toggleAlert(n, false)}}>Desligar alerta</button> }
                                { alertStatus(n.id).exists && !alertStatus(n.id).active && <button onClick={()=>{toggleAlert(n, true)}}>Reativar alerta</button> }
                            </div>
                            <div className={`${stylesAsset["asset-info"]}`} style={{height:"auto"}}>
                                <TempoGraficoRow {...assetData} tf="W" fullOpacity={true} asset={n.asset} />
                                <TempoGraficoRow {...assetData} tf="D" fullOpacity={true} asset={n.asset} />
                                {assetData["240"] && <TempoGraficoRow {...assetData} tf="240" fullOpacity={true} asset={n.asset} />}     
                                <TempoGraficoRow {...assetData} tf="H" fullOpacity={true} asset={n.asset} />
                                <TempoGraficoRow {...assetData} tf="15" fullOpacity={true} asset={n.asset} />
                                <div className={`${stylesAsset["asset-date"]}`} style={{color: "white", fontSize: "0.8em"}}>
                                    { n.lastNotification && 
                                        <span>Enviada em {n.lastNotification.toLocaleString()} – </span>
                                    }
                                     <span>${assetData[15].preco}</span>
                                </div>
                            </div>
                        </li>)
                    })
                }
            </ul>
        </div>
    </>)
}