const cj_nordvpn = `
<div class="ad">
<h2>Proteja-se!</h2>
<h3>Instalar uma VPN é tão importante quanto colocar o Stop-Loss.</h3>
<p>
<a href="https://www.anrdoezrs.net/click-100841193-15494141" target="_top">
<img src="https://www.ftjcfx.com/image-100841193-15494141" width="300" height="250" alt="" border="0"/></a>
</p>
<a class="calltoaction" target="_blank" href="https://www.anrdoezrs.net/click-100841193-15494141">Aproveite a oferta</a>
</div>
`
export default cj_nordvpn;





