const btc = `
<div class="ad">
<h2>Moeda Bitcoin por R$14.99</h2>
<h3>O Bitcoin mais barato da história</h3>
<a href="https://www.amazon.com.br/Moeda-Bitcoin-pe%C3%A7a-de-colecionador/dp/B09JZDHCT3?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=XI4XNMNV3A0N&keywords=moeda+bitcoin&qid=1685685272&sprefix=moeda+bitcoin%2Caps%2C336&sr=8-3&linkCode=li3&tag=agulhada-20&linkId=b711c5c2d62d67bc02dae58c3240bedb&language=pt_BR&ref_=as_li_ss_il" target="_blank">
<img border="0" width="80%" src="https://m.media-amazon.com/images/I/61DW2IRk16L._AC_SX679_.jpg" >
</a>
<img src="https://ir-br.amazon-adsystem.com/e/ir?t=agulhada-20&language=pt_BR&l=li3&o=33&a=B09JZDHCT3" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
<a class="calltoaction" href="https://www.amazon.com.br/Moeda-Bitcoin-pe%C3%A7a-de-colecionador/dp/B09JZDHCT3?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=XI4XNMNV3A0N&keywords=moeda+bitcoin&qid=1685685272&sprefix=moeda+bitcoin%2Caps%2C336&sr=8-3&linkCode=li3&tag=agulhada-20&linkId=b711c5c2d62d67bc02dae58c3240bedb&language=pt_BR&ref_=as_li_ss_il" target="_blank">
Compre na Amazon
</a>
</div>
`

export default btc;

//https://m.media-amazon.com/images/I/61DW2IRk16L._AC_SX679_.jpg