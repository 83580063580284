import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import ReactGA from "react-ga";
import Seo from '../components/Seo/Seo';

// import styles from './Pages.module.scss'


const Contact = () => {
	// window.gtag('event', 'page_view', {
	// 	page_title: "Entre em contato",
	// 	page_path: window.location.pathname + window.location.search,
	// 	page_location: window.location.href
	//   })
	// ReactGA.pageview(window.location.pathname + window.location.search, [], "Entre em contato");
	// const navigate = useNavigate();
	return (
		<>
		<Seo
			title="Entre em contato com o agulhada.com"
			description="Entre em contato com o pessoal que desenvolveu a melhor ferramenta para achar as agulhadas do Didi."
			name="Agulhada.com"
			type="website" />
		<header className={`header`}>
			<h1 className="page-name">Entre em contato</h1>
		</header>
		<div className='container max-w-5xl mx-auto p-4 py-20'>
			{/* <button className="btn" onClick={() => navigate(-1)}>
				Go Back
			</button> */}
			<div className="title">
				<h2 className='text-2xl font-medium my-4'>Email ou Instagram</h2>
			</div>
			<div>
			<p>
					Email: <a href="mailto:contato@agulhada.com" target='_blank' rel="noreferrer" >contato@agulhada.com</a>
				</p>
				<p>
					Instagram <a href="https://www.instagram.com/agulhada_com" target='_blank' rel="noreferrer" >@agulhada_com</a>
				</p>
			</div>
		</div>
		</>
	);
};

export default Contact;