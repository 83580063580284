const assetTypes = [
    {label: "Ações BR", exchanges: "BMFBOVESPA", assetType: "stock"},
    {label: "Futuros BR", exchanges: "BMFBOVESPA", assetType: "futures"},
    {label: "Fundos BR", exchanges: "BMFBOVESPA", assetType: "fund"},
    {label: "NASDAQ", exchanges: "NASDAQ", assetType: "stock"},
    {label: "NYSE", exchanges: "NYSE", assetType: "stock"},
    {label: "Fundos EUA", exchanges: "AMEX,OTC,NASDAQ,NYSE", assetType: "fund"},
    {label: "Forex", exchanges: "ACTIVTRADES", assetType: "forex"},
    {label: "Indices CFDs", exchanges: "ACTIVTRADES", assetType: "index"},
    {label: "Commodities CFDs", exchanges: "ACTIVTRADES", assetType: "cfd,futures"},
    {label: "Crypto", exchanges: "BINANCE,BYBIT", assetType: "crypto"},
    {label: "Crypto CFDs", exchanges: "ACTIVTRADES", assetType: "crypto"},
]

export default assetTypes;