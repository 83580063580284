import React, { useState, useContext } from 'react';
import { UserContext } from '../../UserContext';
import styles from './Asset.module.scss'

const AssetSingle = (props) => {
    const user = useContext(UserContext)
    const userOptions = user.options;
    const userTimeFrames = [...userOptions.timeframes]
    const lastUpdate = new Date(props?.lastUpdate)
    // const isAgulhadoCompra = (props.W.agulhado === 1 || props.D.agulhado === 1 || props.H.agulhado === 1 || props['15'].agulhado === 1) ? styles["agulhado--compra"] : ""
    // const isAgulhadoVenda = (props.W.agulhado === -1 || props.D.agulhado === -1 || props.H.agulhado === -1 || props['15'].agulhado === -1) ? styles["agulhado--venda"] : ""

    // const highlightStyle =  (new Date().getTime() - new Date(props["15"].lastUpdate)?.getTime()) > 900000 ? {"color":"white"} : {}


    return (
        <div className={styles["asset-item"]}>
            <h2 className={styles["asset-header"]}>
                <span className={styles.symbol}>
                    {getTicker(props.asset)}<br />
                    <span className={styles.exchange}>{getExchange(props.asset)}</span>
                </span>
                <span className={styles.name}>{props.nome}</span>
                {/* <span className={styles.favorite}>F</span> */}
            </h2>
            <div className={`${styles["asset-info"]}`}>
                {userTimeFrames.indexOf("W") > -1 && <TempoGraficoRow {...props} tf={props.timeframe} />}
                <div className={`${styles["asset-date"]}`}>&Uacute;ltima captura: {lastUpdate?.toLocaleString()}</div>
            </div>
        </div> 
    )
}

const TempoGraficoRow = (props) => {
    const tf = props.tf
    // console.log(props)
    // const timeDiff = new Date().getTime() - new Date(props[tf].lastUpdate)?.getTime()
    let opacity = 1;

    // if (tf === "W" && timeDiff > 604800000) { opacity = 0.3 };
    // if (tf === "D" && timeDiff > 86400000) { opacity = 0.3 };
    // if (tf === "H" && timeDiff > 3600000) { opacity = 0.3 };
    // if (tf === "15" && timeDiff > 900000) { opacity = 0.3 };
    return (
    <div className={styles["tempo-grafico"]} style={{"opacity": opacity}}>
        <Adx {...props} tf={tf} />
        <Didi {...props} tf={tf} />
        <Bollinger status={props.bollingerStatus}/>
        <Saida {...props} tf={tf} />
        <MessageComponent {...props} tf={tf} />
        <TempoGraficoLetter {...props} tf={tf} />
    </div>
    )
}

const trendClass = (obj, tf) => {
    if(!obj) {return ""};
    const {tendenciaCompra, tendenciaVenda, tendenciaAcelerante} = obj; 

    if(tendenciaCompra && tendenciaAcelerante) {
        return `trend__square-${tf}--compra-acelerante`
    }
    if(tendenciaVenda && tendenciaAcelerante) {
        return `trend__square-${tf}--venda-acelerante`
    }
    if(tendenciaCompra && !tendenciaAcelerante) {
        return `trend__square-${tf}--compra`
    }
    if(tendenciaVenda && !tendenciaAcelerante) {
        return `trend__square-${tf}--venda`
    }
    return '';
}

const MessageComponent = (props) => {
    // const tf = props.tf==="S"?"W":props.tf
    return (
        <div className={`${styles.message}`}>
            {props.message}&nbsp;
        </div>
    )
}

const TempoGraficoLetter = (props) => {
    const user = useContext(UserContext)
    const tf = props.tf
    const isAgulhado = (props.agulhado === 1 || props.agulhado === -1)
    const isAgulhadoCompra = (props.agulhado === 1) ? styles["agulhado--compra"] : ""
    const isAgulhadoVenda = (props.agulhado === -1) ? styles["agulhado--venda"] : ""
    const sharedLayout = user.options.tvlayout?"":"/Cot1ISUS"

    const gtagClick = (asset, tf, agulhada) => {
        window.gtag('event', 'click', {
            action: "Open chart",
            asset: asset,
            timeframe: tf,
            agulhada: agulhada
        })
    }

    return(
    <a className={`${styles.tfletter} ${isAgulhadoCompra} ${isAgulhadoVenda}`}
       href={`https://www.tradingview.com/chart${sharedLayout}/?symbol=${props.asset}&interval=${tf}`}  rel="noreferrer"  target="_blank"
       onClick={()=>gtagClick(props.asset, tf, isAgulhado)}>
        {tf==="W"?"S":tf}
    </a>
    )
}

const Adx = (props) => {
    const tf = props.tf
    let tClass = trendClass(props, tf);
    const [adxAngle] = useState((props.adxanterior-props.adx)/2)
    // setTimeout(() => {
    //     setAdxAngle((props[tf].adxanterior-props[tf].adx)/2);
    // }, 1500);
    return (
    <div className={`${styles["trend__square"]} ${styles["trend__square-"+tf]} ${styles[tClass]||""}`}>
        <div className={styles["trend__square-adx"]} style={{"transform":`rotate(atan(${adxAngle}))`}}></div>
    </div>
    )
}

const Bollinger = (props) => {
    const closedOn = props.status === -1 ? styles.on : ""
    const openOn = props.status === 1 ? styles.on : ""
    return (<div className={styles.bollinger}>
                <div className={`${styles.light} ${styles.closed} ${closedOn}`}>
                </div>
                <div className={`${styles.light} ${styles.open} ${openOn}`}>    
                </div>
            </div>)
}

const Didi = (props) => {
    // const tf = props.tf
    const confirmacao = props.confirmacao !== 0 ? "C" : ""
    const alerta = props.alerta !== 0 ? "A" : ""
    const pontofalso = props.pontofalso !== 0 ? "F" : ""

    let dirClass = ""
    if(props.confirmacao === 1 || props.alerta === 1 || props.pontofalso === -1) {
        dirClass = styles["didi--compra"]
    }
    if(props.confirmacao === -1 || props.alerta === -1 || props.pontofalso === 1) {
        dirClass = styles["didi--venda"]
    }
    return(
        <div className={`${styles.didi} ${dirClass}`}>
            {pontofalso || confirmacao || alerta}
        </div>
    )
}

const Saida = (props) => {
    // const tf = props.tf
    let dirStocClass = props.stockStatus ? styles["stoc--compra"] : styles["stoc--venda"] ;
    let dirTrixClass = props.trixStatus ? styles["trix--compra"] : styles["trix--venda"] ;
    return (
        <div className={`${styles.saida}`}>
            <div className={`${styles.trix} ${dirStocClass}`}>STOC</div>
            <div className={`${styles.stoc} ${dirTrixClass}`}>TRIX</div>
        </div>
    )
}


const getTicker = (s) => {
    return s.substring(s.indexOf(":")+1)
}

const getExchange = (s) => {
    return s.substring(0,s.indexOf(":"))
}

export default AssetSingle