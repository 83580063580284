import styles from './Pages.module.scss';
import { NavLink } from 'react-router-dom';

const FourOFour = () => {
    return (
    <>
        <header className={`header`}>
            <h1 className="page-name">Opa...</h1>
        </header>
        <div className={styles.container}>
            <h2>404 - Página inexistente</h2>
            <p>Se perdeu?</p>
            <p>Use o menu para se encontrar, ou vá para a <NavLink to="/">página inicial</NavLink>.</p>
            <p>Faça o <a href="/logout" rel="noreferrer">Logout</a> se você tenta entrar e continua caindo nesta página.</p>
        </div>
    </>
    )
}

export default FourOFour