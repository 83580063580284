import { createContext, useState } from "react";
import { useLocalStorage } from "./hooks/useLocalStorage";

export const CartContext = createContext({
    items: [],
    products: [],
    prices: [],
    getProductQuantity: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getTotalCost: () => {},
    setAllProducts: () => {},
    setAllPrices: () => {}
})

export const CartProvider = ({children}) => {
    const [cartProducts, setCartProducts] = useLocalStorage("cartProducts", []);
    const [allProducts, setAllProducts] = useState([]);
    const [allPrices, setAllPrices] = useState([]);

    const getProductQuantity = (id) => {
        const quantity = cartProducts.find(product => product.id === id)?.quantity
        if(quantity === undefined) {
            return 0
        }
        return quantity;
    }

    const addOneToCart = (id, data = {}) => {
        const quantity = getProductQuantity(id);

        if (quantity === 0) {
            setCartProducts([
                ...cartProducts,
                { id: id,  quantity: 1, data:data}
            ])
        } else {
            setCartProducts(
                cartProducts.map(
                    product=>
                    product.id === id
                    ? {...product, quantity: product.quantity+1}
                    : product
                )
            )
        }
    }

    const removeOneFromCart = (id) => {
        const quantity = getProductQuantity(id);
        if (quantity === 1) {
            deleteFromCart(id)
        } else {
            setCartProducts(
                cartProducts.map(
                    product=>
                    product.id === id
                    ? {...product, quantity: product.quantity-1}
                    : product
                )
            )
        }
    }

    const deleteFromCart = (id) => {
        setCartProducts(
            cartProducts =>
            cartProducts.filter(currentProduct => {
                return currentProduct.id !== id
            })
        )
    }

    const getTotalCost = () => {
        let totalCost = 0;
        cartProducts.map((cartItem=>{
            const amount = cartItem.data.prices.find(p=>p.id===cartItem.id).unit_amount
            // const productData = getProductById(cartItem.id);
            totalCost += ((amount/100) * cartItem.quantity)
            return cartItem;
        }))
        return totalCost;
    }

    const contextValue = {
        items: cartProducts,
        products: allProducts,
        prices: allPrices,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        setAllProducts,
        setAllPrices
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;