import React, {Fragment,useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext';
import styles from './AssetList.module.scss'
import Asset from '../Asset/Asset'
import AssetMock from '../Asset/AssetMock'
import anuncios from '../Ads/Data';
// import { useInView } from 'react-intersection-observer';
import { ReactComponent as Logo } from "../../resources/logo_agulhada_reverse.svg";



function AssetList(props) {   
    const {favoriteLists, allowFavorites, localFavorites, toggleFavorite, addComment, deleteComment, saveAlert, deleteAlert} = props
    
    const user = useContext(UserContext)
    const userOptions = user.options;
    const userTimeFrames = [...userOptions.timeframes]

    useEffect(()=>{

    },[user])

    // const addAdScript = (visible, entry) => {
    //     // <script data-cfasync='false' type='text/javascript' src='//p457261.clksite.com/adServe/banners?tid=457261_894271_0'></script>
    //     // <script>(function(d,z,s){s.src='https://'+d+'/400/'+z;try{(document.body||document.documentElement).appendChild(s)}catch(e){}})('abazelfan.com',5882642,document.createElement('script'))</script>    
    //     // <script>(function(d,z,s){s.src='https://'+d+'/401/'+z;try{(document.body||document.documentElement).appendChild(s)}catch(e){}})('glizauvo.net',5882653,document.createElement('script'))</script>
    //     // <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    //     const script = document.createElement("script");
    //     script.src = "//platform.twitter.com/widgets.js"
    //     // script.innerHTML = `(function(d, s, id) {
    //     //     var js, fjs = d.getElementsByTagName(s)[0];
    //     //     if (d.getElementById(id)) return;
    //     //     js = d.createElement(s); js.id = id;
    //     //     js.src = "https://woobox.com/js/plugins/woo.js";
    //     //     fjs.parentNode.insertBefore(js, fjs);
    //     //     }(document, 'script', 'woobox-sdk'));`
    //     script.type = "text/javascript"; 
    //     // script["data-cfasync"] = false;
    //     if(visible) {
    //         entry.target.appendChild(script)
    //     }

    // }

    // const { ref } = useInView({
    //     /* Optional options */
    //     threshold: 1,
    //     triggerOnce: true,
    //     onChange: (visible, entry) => { addAdScript(visible, entry) }
    // });


    if(props.loadingState) {
        return (
            <ul className={styles["asset-list"]} bp="grid 12@sm 6@md 4@lg">
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
                <li><AssetMock /></li>
            </ul>
        )
    }

    if(props.list.length === 0 && props.loadingState) {
        return (<h2 style={{"textAlign": "center"}}>
            Nenhum ativo aqui.
        </h2>)
    }

    function injectUserComments(list) {
        const l = [...list]
        for (let symbol of l) {
          const comments = user.comments.filter(c=>c.asset===symbol.asset)
          symbol.comments = comments
        }
        return l
    }

    function injectUserAlerts(list) {
        const l = [...list]
        for(let symbol of l) {
            const alerts = user.alerts.filter(a=>a.asset===symbol.asset)
            symbol.alerts = alerts
        }
        return l
    }

    let list = addOtherTrends(props.list);
    list = filterByTimeframe(list, userTimeFrames)
    list = sortBy(list, props.sortBy);
    list = groupByField(list, "asset")
    list = injectTimeFrameArray(list)
    list = injectUserComments(list)
    list = injectUserAlerts(list)

    return (
        <ul className={styles["asset-list"]} bp="grid 12@sm 6@md 4@lg">
            {list.map((asset, i)=>{
                let anuncio = ""
                if(props.showAds) {


                    if(i===3 || i===28 || i===48) {
                        anuncio = <li dangerouslySetInnerHTML={anuncios[16].code} key={`ad${i}16`}></li> // Telegram
                    }
                    if(i===8 || i===32 || i===52) {
                        anuncio = <li dangerouslySetInnerHTML={anuncios[19].code} key={`ad${i}17`}></li> // Chrome
                    }
                    if(i===13 || i===38 || i===58) {
                        anuncio = <li dangerouslySetInnerHTML={anuncios[17].code} key={`ad${i}18`}></li> // Chrome
                    }
                    if(i===18 || i===42 || i===68) {
                        anuncio = <li dangerouslySetInnerHTML={anuncios[18].code} key={`ad${i}19`}></li> // Amazon Music
                    }
                    if(i === 23) {  
                        anuncio =<li dangerouslySetInnerHTML={anuncios[2].code} key={`ad${i}2`}></li> // IB
                    }

                    // if(i===25) {
                    //     anuncio = <li dangerouslySetInnerHTML={anuncios[6].code} key={`ad${i}1`}></li> // Didius
                    // }    
                    // Pool
                    // if(i===8) {
                    //     anuncio = <li ref={ref} dangerouslySetInnerHTML={{"__html":`<blockquote class="twitter-tweet" data-theme="dark"><p lang="pt" dir="ltr">Qual a próxima funcionalidade você quer no <a href="https://t.co/f32lXIqIUc">https://t.co/f32lXIqIUc</a>?</p>&mdash; Vitor Gomes (@vitorgomes) <a href="https://twitter.com/vitorgomes/status/1651009714413793281?ref_src=twsrc%5Etfw">April 25, 2023</a></blockquote>`}} key={`ad${i}p`}></li>
                    // }

                    // if(i===23) {
                    //     anuncio = <li dangerouslySetInnerHTML={anuncios[8].code} key={`ad${i}6`}></li> // Markdouglas
                    // }
                    // if(i===31) {
                    //     anuncio = <li dangerouslySetInnerHTML={anuncios[9].code} key={`ad${i}5`}></li> // Candle Stick
                    // }
                    // if(i===28) {
                    //     anuncio =<li dangerouslySetInnerHTML={anuncios[14].code} key={`ad${i}13`}></li> // Tourinho
                    // }
                    // if(i===33) {
                    //     anuncio =<li dangerouslySetInnerHTML={anuncios[7].code} key={`ad${i}7`}></li> // Prop trader
                    // }
                    // if(i===38) {
                    //     anuncio =<li dangerouslySetInnerHTML={anuncios[8].code} key={`ad${i}8`}></li> // Didi Index
                    // }
                    // if(i === 54) {
                    //     anuncio = <li dangerouslySetInnerHTML={anuncios[5].code} key={`ad${i}0`}></li> // Tradingview
                    // }

                    if(i===list.length-1 || i===53) {
                        anuncio = <li key={`ad${i}4`}>
                                    <div dangerouslySetInnerHTML={anuncios[4].code}></div>
                                    <div style={{"width":"50%", "margin":"auto"}}><Logo /></div>
                                    
                                </li> // PIX
                    }
                }
                return (
                <Fragment key={asset._id}>
                    <li>
                        <Asset {...asset}
                            localFavorites={localFavorites}
                            toggleFavorite={toggleFavorite}
                            favoriteLists={favoriteLists}
                            allowFavorites={allowFavorites}
                            addComment={addComment}
                            deleteComment={deleteComment}
                            saveAlert={saveAlert}
                            deleteAlert={deleteAlert}
                         />
                    </li>
                    {anuncio}
                </Fragment>
                )
            })}
        </ul>
    )
}

export const addOtherTrends = (list) => {
    const rList = list.map(item => {
        // let datesAr = []
        const trendW = list.filter(a => a.asset === item.asset && a.timeframe === "W" )[0]
        const trendD = list.filter(a => a.asset === item.asset && a.timeframe === "D" )[0]
        const trend240 = list.filter(a => a.asset === item.asset && a.timeframe === "240" )[0]
        const trendH = list.filter(a => a.asset === item.asset && a.timeframe === "60" )[0]
        const trend15 = list.filter(a => a.asset === item.asset && a.timeframe === "15" )[0]
        
        // const lastAlert = Math.max(...datesAr.map(e => e.lastUpdate))
        // const lastAlertTf = datesAr.filter(ad => ad.lastUpdate===lastAlert)[0]

        return {
            ...item,
            W: trendW,
            D: trendD,
            H: trendH,
            "240": trend240,
            "15": trend15,
            // lastAlert: new Date(lastAlert),
            // lastAlertTf: lastAlertTf.alertTf
        }
    })

    return rList
}

const sortBy = (list, mode) => {
    const getTicker = (s) => {
        return s.substring(s.indexOf(":") + 1)
    }

    list.sort((a,b) => getTicker(a.asset).localeCompare(getTicker(b.asset)))

    switch(mode) {
        case ('nome') : 
            list.sort((a,b) => getTicker(a.asset).localeCompare(getTicker(b.asset)))
            break;
        case ('tendencia') : 
            list.sort((a,b) => a.tendenciaEntrante - b.tendenciaEntrante)
            break;
        case ('bollinger') : 
            list.sort((a,b) => b.bbabriu - a.bbabriu)
            break;
        case ('alertac') : 
            list.sort((a,b) => b.alerta - a.alerta)
            break;
        case ('alertav') : 
            list.sort((a,b) => a.alerta - b.alerta)
            break;
        case ('confirmac') : 
            list.sort((a,b) => b.confirmacao - a.confirmacao)
            break;
        case ('confirmav') : 
            list.sort((a,b) => a.confirmacao - b.confirmacao)
            break;
        case ('pfv') : 
            list.sort((a,b) => a.pontofalso - b.pontofalso)
            break;
        case ('pfc') : 
            list.sort((a,b) => b.pontofalso - a.pontofalso)
            break;
        case ('barato') : 
            list.sort((a,b) => parseFloat(a.preco) - parseFloat(b.preco))
            break;
        case ('caro') : 
            list.sort((a,b) => parseFloat(b.preco) - parseFloat(a.preco))
            break;
        default :
            list.sort((a, b) => {
                if (a.D?.agulhado === 1 || a.D?.agulhado === -1 || a.H?.agulhado === 1 || a.H?.agulhado === -1 || a.W?.agulhado === 1 || a.W?.agulhado === -1 || a["15"]?.agulhado === 1 || a["15"]?.agulhado === -1 || a["240"]?.agulhado === 1 || a["240"]?.agulhado === -1) {
                    return -1;
                }
                if (a.D?.agulhado === 0) {
                    return 1;
                }
        
                return 0;
            })
    }
    return list

}

const filterByTimeframe = (list, tfArray) => {
    const filteredList = list.filter(a => tfArray.indexOf(a.timeframe) > -1)
    return filteredList
}


// const soTrendInfo = (obj) => {
//     if(!obj) {return}
//     const {lastUpdate, message, mudoutendencia, tendenciaAcelerante, tendenciaCompra,tendenciaEntrante, tendenciaVenda} = obj;
//     const tendencia = !tendenciaCompra && !tendenciaVenda ? 0 : (tendenciaVenda ? -1 : (tendenciaCompra ? 1 : 0))

//     return {lastUpdate, message, mudoutendencia, tendencia, tendenciaAcelerante, tendenciaEntrante}
// }

const groupByField = (arr, field) => {
    let rArr = [];

    for (const s of arr){
        if (!rArr.find(e => e[field] === s[field])) {
            rArr.push(s)
        }
    }
    return rArr
}

function injectTimeFrameArray(arr) {
    const rArr = [...arr]
    const timeFrames = ["15", "H", "240", "D", "W"] 
    for (const a of rArr) {
        a.timeFrames = [];
        for (const tf of timeFrames) {
            if (a[tf]) {
                a.timeFrames.push(tf)
            }
        }
    }
    return rArr
}


// const sortByLastrUpdate = (arr, tf) => {
//     return arr.sort((a, b) => new Date(b[tf]?.lastUpdate) - new Date(a[tf]?.lastUpdate))
// }


// const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});

export default AssetList