import React, { useEffect, useMemo, useState } from 'react';
import Seo from '../components/Seo/Seo';
import stylesAsset from '../components/Asset/Asset.module.scss';
import { useLocation } from 'react-router-dom';
import { TempoGraficoRow } from '../components/Asset/Asset';

const Telegram = () => {
    const [telegramUser, ] = useState(window.Telegram.WebApp.initDataUnsafe.user || {id:"123"})
    const [loadingData, setLoadingData] = useState(true)
    const [, setUserId] = useState(null)
    const [assetData, setAssetData] = useState({})
    const [notificationData, setNotificationData] = useState(null);

    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(()=>{
      setLoadingData(true)
      const dataFetch = async () => {
        console.log(query.get("notification"))
        let notificationQueryString = ""
        if(query.get("notification")) {
            notificationQueryString = `&nid=${query.get("notification")}`
        }
        const data = await (
            await fetch(`/api/getTelegramNotification?telegram=${telegramUser?.id}&asset=${query.get("asset")}&timeframe=${query.get("tf")}&u=${query.get("u")}&timestamp=${query.get("ts")}&${notificationQueryString}`)
        ).json()
        setLoadingData(false)
        setUserId(data.userId)

        const _assetData = {}
        for(const ad of data.assetInfo) {
            _assetData[ad.timeframe === "60" ? "H" : ad.timeframe] = {
                ...ad
            }
        }
        setAssetData(_assetData)

        if(data.notification) {
            const _notification = {}
            for(const ad of data.notification.assetData) {
                _notification[ad.timeframe === "60" ? "H" : ad.timeframe] = {
                    ...ad
                }
            }
            _notification.lastNotification = new Date(data.notification.lastNotification).toLocaleDateString()
            setNotificationData(_notification)
        }
     };

     dataFetch();


    },[telegramUser, setLoadingData, setAssetData, setNotificationData, query])

	return (
		<>
            <Seo
				title="Agulhada.com"
				description="Aprenda como o Agulhada.com funciona e assita a um video explicativo sobre como usar a ferramenta para achar as agulhadas do Didi."
				name="Agulhada.com"
				type="website" />
            <header className="header px-4">
				<h1 className="page-name">{query.get("asset")}</h1>
			</header>
            { telegramUser && loadingData &&
                <h2 className='pt-20 text-center text-xl'>Carregando dados...</h2>
            }
            { telegramUser && !loadingData &&
			<div className='pt-20 px-4'>
                <div className={`${stylesAsset["asset-info"]}`} style={{height:"auto"}}>
                    <TempoGraficoRow {...assetData} tf="W" fullOpacity={true} asset={assetData[15].asset} />
                    <TempoGraficoRow {...assetData} tf="D" fullOpacity={true} asset={assetData[15].asset} />
                    {assetData["240"] && <TempoGraficoRow {...assetData} tf="240" fullOpacity={true} asset={assetData[15].asset} />}     
                    <TempoGraficoRow {...assetData} tf="H" fullOpacity={true} asset={assetData[15].asset} />
                    <TempoGraficoRow {...assetData} tf="15" fullOpacity={true} asset={assetData[15].asset} />
                    <div className={`${stylesAsset["asset-date"]}`} style={{color: "white", fontSize: "0.8em"}}>
                        <span>Última leitura {new Date(assetData[15].lastUpdate).toLocaleString()} – ${assetData[15].preco}</span>
                    </div>
                </div>

                {notificationData &&
                <>
                <h2 className='text-center text-xl p-4'>No momento do alerta</h2>
                <div className={`${stylesAsset["asset-info"]}`} style={{height:"auto"}}>
                    <TempoGraficoRow {...notificationData} tf="W" fullOpacity={true} asset={notificationData[15].asset} />
                    <TempoGraficoRow {...notificationData} tf="D" fullOpacity={true} asset={notificationData[15].asset} />
                    {notificationData["240"] && <TempoGraficoRow {...notificationData} tf="240" fullOpacity={true} asset={notificationData[15].asset} />}     
                    <TempoGraficoRow {...notificationData} tf="H" fullOpacity={true} asset={notificationData[15].asset} />
                    <TempoGraficoRow {...notificationData} tf="15" fullOpacity={true} asset={notificationData[15].asset} />
                    <div className={`${stylesAsset["asset-date"]}`} style={{color: "white", fontSize: "0.8em"}}>
                        <span>Alerta em {notificationData.lastNotification}  – ${notificationData[15].preco}</span>
                    </div>
                </div>
                </>
                }
			</div>

            
            }
            {!telegramUser &&
                <h1 className='text-3xl font-medium my-4 mt-14 text-center'>Essa pagina só funciona no Telegram</h1>
            }
		</>
	);
};

export default Telegram;
