import { useContext } from "react";
import { CartContext } from "../../CartContext";
import styles from './Product.module.scss';

// import { getProductById } from "../../mockData/products";

const ProductCart = (props) => {
    const {id, quantity, product } = props;
    const { unit_amount, nickname } = product.prices.find(price=>price.id===id)
    const cart = useContext(CartContext);
    // const product = getProductById(id);
    return (
        <div className={`${styles.product}`} bp="grid">
            { product.images?.length > 0 && <img src={product.images[0]}  width="100%" alt="product"  bp="3" /> }
            <div bp="9">
                <h2 className={`${styles['product__name']}`}>{quantity} x {product.name} {nickname ? " - " + nickname : ""}</h2>
                <p>R$ {(quantity * (unit_amount/100)).toFixed(2)}</p>
                <button className={`${styles['button']}`} onClick={()=>{cart.removeOneFromCart(id)}}>-</button>
                <button className={`${styles['button']}`} onClick={()=>{cart.addOneToCart(id)}}>+</button>
                <button className={`${styles['button']}`} onClick={()=>{cart.deleteFromCart(id)}}>Remover</button>
            </div>
        </div>
    )
}

export default ProductCart