import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../UserContext";
import styles from "./ControlPanel.module.scss";

const ControlPanel = (props) => {
  const user = useContext(UserContext);
  const [, setSubscribed] = useState(false)

  useEffect(()=>{
    async function check() {
        setSubscribed(await isSubscribed())
    }
    check();
  }, [setSubscribed])

  const timeframes = user.options.timeframes;

  const changeTimeframeOptions = (tf) => {
    toggleTimeframe(tf);
  };

  const toggleTimeframe = (tf) => {
    let cOptions = { ...user.options };
    cOptions.timeframes = toggleElement(cOptions.timeframes, tf);
    user.setOptions(cOptions);
  };

  const toggleTvLayout = () => {
    let cOptions = { ...user.options };
    cOptions.tvlayout = !cOptions.tvlayout;
    user.setOptions(cOptions);
  };

  const toggleElement = (arr, val) => arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val];


  async function isSubscribed() {
    if ("Navigator" in window) {
      if(window.navigator.serviceWorker) {
        const registrations = await window.navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
            if (registration.active.scriptURL.includes("alerts-sw.js")) {
                return true;
            }
        }
      }
    }
    return false
  }


//   async function registerServiceworker() {
//     if ("Navigator" in window) {
//       await navigator.serviceWorker.register(`alerts-sw.js`);
//       setSubscribed(true);
//     }
//   }

//   async function ungeristerSw() {
//     if ("Navigator" in window) {
//         if ('serviceWorker' in navigator) {
//             navigator.serviceWorker.ready
//               .then((registration) => {
//                 registration.unregister();
//                 setSubscribed(false);
//               })
//               .catch((error) => {
//                 console.error(error.message);
//               });
//           }
//     }
//   }

  return (
    <div className={styles["control-panel"]}>
      <h3 className={styles["control-panel__h3"]}>Painel de controle</h3>
      <fieldset>
        <legend>Tempos gráficos:</legend>
        <TfCheckBox
          tf="W"
          label="Semanal"
          isChecked={timeframes.indexOf("W") > -1 ? true : false}
          onChangeHandler={changeTimeframeOptions}
        />
        <TfCheckBox
          tf="D"
          label="Di&aacute;rio"
          isChecked={timeframes.indexOf("D") > -1 ? true : false}
          onChangeHandler={changeTimeframeOptions}
        />
        <TfCheckBox
          tf="240"
          label="4 horas"
          isChecked={timeframes.indexOf("240") > -1 ? true : false}
          onChangeHandler={changeTimeframeOptions}
        />
        <TfCheckBox
          tf="H"
          label="1 hora"
          isChecked={timeframes.indexOf("H") > -1 ? true : false}
          onChangeHandler={changeTimeframeOptions}
        />
        <TfCheckBox
          tf="15"
          label="15 minutos"
          isChecked={timeframes.indexOf("15") > -1 ? true : false}
          onChangeHandler={changeTimeframeOptions}
        />
      </fieldset>

      <fieldset>
        <legend>Outros:</legend>
        <TfCheckBox
          tf="tv"
          label="Abrir meu pr&oacute;prio layout no TradingView"
          isChecked={user.options.tvlayout}
          onChangeHandler={toggleTvLayout}
        />
      </fieldset>

      {/* <div>
        {subscribed === false ? (
          <button onClick={registerServiceworker}>
            Receber alertas neste dispositivo
          </button>
        ) : (
          <button onClick={ungeristerSw}>
            Parar de receber alertas neste dispositivo
          </button>
        )}
      </div> */}
    </div>
  );
};

const TfCheckBox = (props) => {
  const tf = props.tf;
  const [isChecked, setIsChecked] = useState(props.isChecked);
  const handleOnChange = () => {
    props.onChangeHandler(tf);
    setIsChecked(!isChecked);
  };
  return (
    <div>
      <input
        type="checkbox"
        id={`checkbox_${tf}`}
        name={tf}
        value={tf}
        checked={isChecked}
        onChange={handleOnChange}
      />
      <label htmlFor={`checkbox_${tf}`}>{props.label}</label>
    </div>
  );
};

export default ControlPanel;
