import { useRef, useState, useEffect } from 'react';
import styles from './PopupDialog.module.scss';
import { ReactComponent as IconCross } from "../../resources/cross.svg";

const PopupDialog = (props) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [leftOffset, setLeftOffset] = useState('-50%')
    const constainerDiv = useRef();

    const togglePopUp = () => {
        setShowPopUp(!showPopUp)
    }

    const hidePopUp = () => {
        if(props.hidePopUpOnClick === true) {
            setShowPopUp(false)
        }
    }

    useEffect(()=>{
        const { x, width } = constainerDiv.current.getBoundingClientRect()
        if((x + width) > window.innerWidth) {
            const diff = (x + width) - window.innerWidth + 20;
            setLeftOffset(`calc(-50% - ${diff+20}px)`)
        } else {
            setLeftOffset('-50%')
        }
    },[showPopUp, constainerDiv])

    return (
    <div className={styles["popup"]}>
        <button type="button" className={`${styles["popup__button"]} ${showPopUp ? styles["popup__button--selected"] : ''}`} onClick={togglePopUp}>
            { showPopUp ?
                <IconCross style={{"height":"20px"}} />
                :
                props.icon
            }
        </button>
        <div className={`${styles["arrow-up"]} ${showPopUp ? styles["arrow-up--visible"] : ''}`}></div>
        <div ref={constainerDiv}
            style={{"left":leftOffset}}
            className={`${styles["popup__container"]}
            ${showPopUp ? styles["popup__container--visible"] : ""}`}
            onClick={hidePopUp}>
            {props.children}
        </div>
        <div className={`${styles["popup__overlay"]} ${showPopUp ? styles["popup__overlay--visible"] : ''}`}
        onClick={togglePopUp}></div>
    </div>
    )
}

export default PopupDialog;