const markdouglas = `
<div class="ad">
<h2>Trading in the zone</h2>
<h3>(Não é o que você está pensando...)</h3>
<a href="https://www.amazon.com.br/trader-vencedor-dominar-mercado-confian%C3%A7a/dp/8582851502?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3OUK8GL8B1ZXM&keywords=psicologia+trade&qid=1681884384&sprefix=psicologia+trade%2Caps%2C342&sr=8-10&linkCode=li3&tag=agulhada-20&linkId=688ee851f035f5831e4e5af276bc98fe&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8582851502&Format=_SL250_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=agulhada-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=agulhada-20&language=pt_BR&l=li3&o=33&a=8582851502" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
<p>Finalmente chega ao mercado a edição brasileira de Trading in the Zone, um clássico de psicologia do trading que há anos ajuda traders ao redor do mundo a obter ganhos consistentes.</p>
<a class="calltoaction" target="_blank" href="https://www.amazon.com.br/trader-vencedor-dominar-mercado-confian%C3%A7a/dp/8582851502?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3OUK8GL8B1ZXM&keywords=psicologia+trade&qid=1681884384&sprefix=psicologia+trade%2Caps%2C342&sr=8-10&linkCode=li3&tag=agulhada-20&linkId=688ee851f035f5831e4e5af276bc98fe&language=pt_BR&ref_=as_li_ss_il">
Compre na Amazon
</a>
</div>
`

export default markdouglas;