import camiseta from './lojinha.png';

const lojinha = `
<div class="ad">
<h2>Abriu a <a href="https://www.agulhada.com/lojinha">lojinha</a> do agulhada.com</h2>
<p>Corre lá e garanta logo a sua camiseta ou caneca porque a edição é limitada!<br>Frete gratis para todo o Brasil.</p>
<a href="https://www.agulhada.com/lojinha">
<img height="300" src=${camiseta} alt="Camiseta agulhada.com" />
</a>
</br />
<a class="calltoaction" href="https://www.agulhada.com/lojinha">Visite a Lojinha</a>
</div>
`

export default lojinha;