import useSWR from "swr"

const fetcher = (...args) => fetch(...args).then(res => res.json())

export function useUserDetails (id) {
    const { data, error, isLoading, mutate, isValidating } = useSWR(`/api/getUserInfo`, fetcher);

    return {
      userDetails: data,
      isLoadingUser: isLoading,
      isUserError: error,
      mutateUser: mutate,
      isValidatingUser: isValidating
    }
}