import styles from '../../pages/Pages.module.scss'

export default function Assinou() {
    return (<>
        <header className={`header`}>
            <h1 className="page-name">Obrigado</h1>
        </header>
        <div className={`${styles.container} max-w-5xl mx-auto p-4 py-20`}>
            <h2 className="text-3xl my-2 mt-8">Obrigado por assinar o Agulhada.com</h2>
            <h3 className="text-2xl my-2 mt-8">E agora?</h3>
            <p>Para acessar os recursos para assinantes, é necessário <a className='calltoaction' href="/.auth/logout" rel="noreferrer">sair do site</a> e entrar novamente.</p>
            <p>Somente no final do período de testes você será cobrado.</p>
            <h3 className="text-2xl my-2 mt-8">Aprenda</h3>
            <p>Assista aos vídeos explicativos no <a href="https://www.youtube.com/@agulhada_com" target="_blank" rel="noreferrer">nosso canal no YouTube</a>. </p>
            <h2 className="text-2xl my-2 mt-8">Encontre outros assinantes</h2>
            <p> Troque idéias no grupo de usu&aacute;rios do agulhada.com: <a href="https://t.me/agulheiros" target="_blank" rel="noreferrer" >https://t.me/agulheiros</a></p>
            <p className="my-2 mt-8">Boa sorte e bons trades!</p>
        </div>
    </>)
}