import AlertsMenu from './AlertsMenu';
import Seo from '../Seo/Seo';
import templates from "../StaticData/templates";
import assetTypes from '../StaticData/assetTypes';
import { useAllAssets } from '../../hooks/useAllAssets';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../UserContext';
import { saveAlert, saveMultipleAlerts } from './AlertsApi';
import { ReactComponent as IconCross } from "../../resources/cross.svg";
import AssetAlert from '../Asset/AssetAlert';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserDetails } from '../../hooks/useUserDetails';
import ButtonLoad from '../Ui/ButtonLoad';

// import { useUserDetails } from '../../hooks/useUserDetails';

export default function Templates() {
    const user = useContext(UserContext)
    // console.log(user)

    const {mutateUser} = useUserDetails();

    const isSubscriber = user.clientPrincipal?.userRoles?.includes('VIP') || user.clientPrincipal?.userRoles?.includes('Assinante') ;
    const isVip = user.clientPrincipal?.userRoles?.includes('VIP');
    const isBetaTester = user.clientPrincipal?.userRoles?.includes('Beta');

    const [ exchanges, setExchages ] = useState(["BMFBOVESPA"]);
    const [ assetType, setAssetType ] = useState(["stock"]);
    const [ assetList, setAssetList ] = useState([]);
    const [ currentPanel, setCurrentPanel ] = useState("");
    const [ currentTemplate, setCurrentTemplate ] = useState(null);
    const [ template, setTemplate ] = useState(null);
    const [ has4H, setHas4H ] = useState(false);
    const [ readyToRender, setReadyToRender ] = useState(false);
    const [ userAssets, setUserAssets ] = useState(false);
    const [ allOn, setAllOn ] = useState({"15":false, "60": false, "240": false, "D": false, "W": false});
    const { listData, isLoading, isValidating, isError } = useAllAssets(exchanges, userAssets);

    const tfs = [{key:'15', lbl:"15 Min"}, {key:'60', lbl:"60 Min"}, {key:'240', lbl:"4 Horas"}, {key:'D', lbl:"Diário"}, {key:'W', lbl:"Semanal"}]

    const sameRules = useCallback( function sameRules (templateObj, userAlerts = []) {
        // From a list of alerts, return the ones that match the template criteria
        if(!templateObj) return [];
        const returnArray = [];

        for (const userAlert of userAlerts) {
            for (const alert of userAlert.alerts) {
                    // LOOP through key and values of alert.
                    const alertConfig = {...alert.config}
                    delete alertConfig.message;
                    delete alertConfig.active;
                    delete alertConfig.rules;

                    const tObj = {...templateObj}
                    delete tObj.message;
                    delete tObj.active;
                    delete tObj.rules;

                    if(matches(alertConfig, tObj)) {
                        returnArray.push({
                            ...alert,
                            asset: userAlert.asset,
                            timeframe: userAlert.timeframe,
                        })
                    }
            }
        }
        return (returnArray)
    },[]);

    useEffect(() => {
        setReadyToRender(false)
        let has4H = false;
        if(!isError && !isLoading) {
            const _list = [];

            const filteredListData = listData.filter(a => assetType.includes(a.assetType))
            for (const asset of filteredListData) {
                const _asset = _list.find(a => a.nome === asset.nome)
                if (_asset) {
                    if (asset.timeframe === '240') {
                        _asset.timeframes = ['15', '60', '240', 'D', 'W']
                        has4H = true;
                    }
                } else {
                    _list.push({
                        asset: asset.asset,
                        nome: asset.nome,
                        assetType: asset.assetType,
                        timeframes: ['15', '60', null, 'D', 'W']
                    })
                }
            }
            _list.sort((a, b) => a.asset.localeCompare(b.asset))

            const templateUserAlerts = sameRules(template,  user.alerts);
            const listWithAlerts = injectUserAlerts(_list, templateUserAlerts)            
            setAssetList(listWithAlerts)
            setReadyToRender(true)
            setHas4H(has4H);
        }
        

    }, [isLoading, listData, setAssetList, assetType, isError, template, isValidating, user.alerts, sameRules])

    function filterAssetData(e) {
        const xs = stringParts(e.target.value, "|")[0]
        const at = stringParts(e.target.value, "|")[1]
        setExchages(xs.split(","))
        setAssetType(at.split(","))
    }

    function editTemplate(key, obj) {
        setTemplate(obj)
        setCurrentTemplate(key)
        setCurrentPanel('edit')
    }

    function applyTemplate(key, obj) {
        // console.log(key, obj)
        setTemplate(obj)
        setCurrentTemplate(key)
        setCurrentPanel('apply')
    }

    function closePanel() {
        setCurrentPanel(null);
        setCurrentTemplate(null);
        setTemplate(null)
    }

    function injectUserAlerts(list, templateUserAlerts) {
        const l = [...list]
        for(const asset of l) {
            const timeframeAlert = [];
            for (const timeframe of asset.timeframes) {
                const alert = templateUserAlerts.find(tua => tua.asset === asset.asset && tua.timeframe === timeframe)
                const alertId = alert ? alert.id : null;
                const active = alert ? alert.active : false;
                timeframeAlert.push({ timeframe, id: alertId, active})
            }
            asset.alerts = timeframeAlert
        }
        return l
    }

    async function saveTimeframeAlert(asset, timeframe, id = null, active = true) {
        const alertToSave = {}
        const _template = {...template}
        alertToSave.config = _template;
        alertToSave.message = _template.message;
        delete alertToSave.config.message;
        alertToSave.rules = _template.rules;
        delete alertToSave.config.rules;
        alertToSave.active = active;
        delete alertToSave.config.active;
        alertToSave.frequency = _template.frequency || "tf"
        delete alertToSave.config.frequency;
        alertToSave.id = id;

        const r = await saveAlert(asset, timeframe, alertToSave);
        if (r.result) {
            // mutateUser('/api/getUserInfo');
            
            user.setAlerts(ua => {
                const alertId =  r.result._id
                const userAlerts = [...ua];
                const index = userAlerts.findIndex(a=>a._id===alertId);
                if(index !== -1) {
                    userAlerts[index] = r.result
                } else {
                    userAlerts.push(r.result)
                }
                return userAlerts
            });

            if(r.result.error) {
                toastAlert(r.result.error)
            }

        }

        return r
    }

    function toastAlert(msg) {
        toast.error(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
        });
    }

    async function prepareMultiplePost(tf, active = false) {
        const alertConfig = {}
        const _template = {...template}
        alertConfig.config = _template;
        alertConfig.message = _template.message;
        delete alertConfig.config.message;
        alertConfig.rules = _template.rules;
        delete alertConfig.config.rules;
        alertConfig.active = active;
        delete alertConfig.config.active;
        alertConfig.frequency = _template.frequency || "tf"
        delete alertConfig.config.frequency;


        // TODO: Check is only VIP.
        const postArray = []
        for(const asset of assetList) {
            for(const alert of asset.alerts) {
                const { timeframe, id } = alert
                if(isVip) {
                    // Move this afeter beta version
                    if(timeframe === tf) {
                        postArray.push({asset: asset.asset, timeframe, id: id || null, alertConfig})
                    }
                } else {
                    if(timeframe === tf && id && active === true ) {
                        postArray.push({asset: asset.asset, timeframe, id: id || null, alertConfig})
                    }
                }
            }
        }

        // if(isBetaTester) {
            const _allOn = {...allOn};
            _allOn[tf] = !allOn[tf]
            setAllOn(_allOn);
            await saveMultipleAlerts(postArray);
            await mutateUser();
            // toastAlert(`${r.result.upsertedCount + r.result.insertedCount} alertas novos. ${r.result.modifiedCount} alertas modificados. `)
        // }

        return postArray;
    }


    return (<>
        <Seo
            title="Templates de alertas"
            description="Configure seu alerta"
            name="Agulhada.com"
            type="website" />
        <header className={`header`}>
            <h1 className="page-name">Templates</h1>
        </header>

        <div className='max-w-5xl mx-auto p-4 pt-20'>
            <ToastContainer />
            <AlertsMenu />

            {!isSubscriber && <>
                <h2 className='text-2xl font-medium my-4'>Templates</h2>
                <AssetAlert />
            </>}
            
            {isSubscriber &&
                <div className='relative mt-4 lg:grid lg:grid-cols-3'>
                    <div className='mr-4'>
                        <h2 className='text-2xl font-medium my-4'>Templates</h2>
                        <ul>
                            {
                                Object.entries(templates).map(([key, value]) => {
                                    const classCurrent = currentTemplate === key ? "border-amber-500" : "border-slate-800";
                                    return (
                                    <TemplateItem
                                        key={key}
                                        templateKey={key}
                                        value={value}
                                        classCurrent={classCurrent}
                                        applyTemplate={applyTemplate}
                                        editTemplate={editTemplate} /> )
                                })
                            }
                        </ul>
                    </div>
                    {currentPanel &&
                    <div className='absolute top-0 left-0 w-full h-full bg-appbg lg:col-span-2 lg:relative'>
                        <button onClick={closePanel} className='absolute top-8 right-0 w-8'>
                            <IconCross className='fill-apporange' />
                        </button>
                        {currentPanel === "edit" &&
                            <>
                                <h2 className='text-2xl font-medium my-4'>Editar</h2>
                                <div>
                                    {template.message}
                                </div>
                            </>
                        }
                        {currentPanel === "apply" &&
                            <>
                                <h2 className='text-2xl font-medium my-4 pr-8'>
                                    Ativos <span className='text-xl font-normal'> – {template.message}</span>
                                </h2>
                                {/* <div>{currentTemplate || ""}</div> */}
                                <label>Filtrar: { }
                                    <select className='text-black p-1' onChange={filterAssetData}>
                                        {assetTypes.map(assetType => {
                                            return (
                                                <option
                                                    value={assetType.exchanges +"|"+assetType.assetType}
                                                    key={assetType.label}
                                                    >
                                                        {assetType.label}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </label>
                                <label className='ml-9'>
                                    <input type='checkbox' checked={userAssets} onChange={()=>{setUserAssets(!userAssets)}} /> { }
                                    Meus ativos do TradingView
                                </label>
                            

                                    

                                <div className='h-6 my-4 text-center'>
                                { isLoading && "Carregando..."}
                                { isError && "Deu erro carregando os dados."}
                                { isValidating && !isLoading && "Atualizando os dados."}
                                </div>

                                { readyToRender &&
                                <>
                                    <div className='grid grid-cols-6 rounded p-2 mb-4 mt-4 border border-slate-700 hover:border-orange-500'>
                                        <div className='col-span-6 sm:col-span-2'>
                                            { isBetaTester && 
                                                <span>Ativar ou desligar todos os ativos por tempo gráfico</span>
                                            }
                                            { !isBetaTester && 
                                                <span>Desligar todos os ativos por tempo gráfico</span>
                                            }
                                        </div>
                                        <div className={`col-span-6 sm:col-span-4 grid grid-rows-1 grid-flow-col place-items-center`}>
                                            { tfs.map(tf=> {
                                                if(!has4H && tf.key === "240") {
                                                    return (<Fragment key={tf.key}></Fragment>)
                                                } else {
                                                    return (<div className="text-center"  key={tf.key}>
                                                            <ButtonLoad
                                                                onClick={async () => { await prepareMultiplePost(tf.key, true) }}
                                                                svg={{height:"28px", width:"60px"}}
                                                                className={`text-sm relative p-4 py-1 border border-slate-700 hover:border-orange-500 rounded-lg`}
                                                            >
                                                                    ligar
                                                            </ButtonLoad>

                                                            <div className='font-bold'>{tf.lbl}</div>
                                                            <ButtonLoad
                                                                onClick={async () => { await prepareMultiplePost(tf.key, false) }}
                                                                svg={{height:"28px", width:"60px"}}
                                                                className={`text-sm relative p-4 py-1 border border-slate-700 hover:border-orange-500 rounded-lg`}
                                                            >
                                                                    desligar
                                                            </ButtonLoad>
                                                        </div>)
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                    <ul>
                                        { assetList.map(asset => <AssetRow key={asset.asset} asset={asset} saveAlert={saveTimeframeAlert} alerts={asset.alerts} />) }
                                    </ul>
                                </>
                                }
                            </>
                        }
                    </div>
                    }
                </div>
            }
        </div>
    </>)
}


function TemplateItem(props) {
    const {templateKey, value, classCurrent, applyTemplate } = props
    return(
        <li className={`flex place-items-center bg-slate-800 rounded mb-2 border ${classCurrent}`}>
            <button className='flex-1 p-3 text-left text-amber-50 hover:text-amber-500'
                onClick={()=>applyTemplate(templateKey, value)}>
                {value.message}
            </button>
            {/* <button className='flex-none p-2 my-2 text-xs bg-amber-500 text-black rounded-sm'
                onClick={()=>applyTemplate(templateKey, value)}>
                Ativos
            </button> */}
            {/* <button className='flex-none p-2 my-2 mx-1 ml-2  text-xs border-amber-500 border rounded-sm'
                onClick={()=>editTemplate(templateKey, value)}>
                Editar
            </button> */}
        </li>
    )
}

function AssetRow(props) {
    const { asset, saveAlert, alerts } = props;
    return (
        <li className='grid grid-cols-6 bg-slate-800 rounded p-2 mb-4'>
            <div className='col-span-6 sm:col-span-2 grid grid-cols-6 mb-2'>
                <div className='col-span-2 sm:col-span-6' >
                    <div className='text-xs'>{stringParts(asset.asset, ":")[0]}</div>
                    <div className='text-lg font-bold'>{stringParts(asset.asset, ":")[1]}</div>
                </div>
                <div className='col-span-4 sm:col-span-6 text-sm'>{asset.nome}</div>
            </div>
            <div className={`col-span-6 sm:col-span-4 grid grid-rows-1 grid-flow-col place-items-center`}>
                {alerts.map(alert => {
                    // if (alert.id) console.log(alert)
                    let tfLabel = alert.timeframe;
                    // if(alert.id) console.log(alert.id)
                    if(alert.timeframe === "240") tfLabel = "4H"
                    if(alert.timeframe === "W") tfLabel = "S"
                    return (<Fragment key={asset.asset + (alert.timeframe || "0")}>
                        {alert.timeframe &&
                            <>
                                <OnOffButton key={"bt_" + asset.asset + (alert.timeframe || "0")}
                                    isOn={alert.active}
                                    onClick={async ()=>{return await saveAlert(asset.asset, alert.timeframe, alert.id, alert.active?false:true)}}>
                                    {tfLabel}
                                </OnOffButton>
                            </>
                        }
                    </Fragment>)                                                        
                    }
                )}
            </div>
        </li>
    )
}

function OnOffButton(props) {

    const [isChanging, setIsChanging] = useState(false);

    const onClick = async () => {
        if(isChanging) return;
        setIsChanging(true);
        const result = await props.onClick();
        if(result.result) {setIsChanging(false)}
    }

    return (
        <button
            className={`relative p-6 py-2 border ${props.isOn ? 'border-green-700':'border-slate-500'} hover:border-orange-500 rounded-lg`}
            onClick={onClick}
        >
            {props.children}
            <div className={`absolute right-1 top-1 rounded-full w-3 h-3 ${isChanging?'bg-orange-500':props.isOn?'bg-green-400':'bg-slate-700'} transition-colors`}>
                
            </div>
        </button>
    )
}

function matches(a, b) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    const keys = new Set([...Object.keys(a), ...Object.keys(b)])
    if(keys.size !== keysA.length || keys.size !== keysB.length) return false
    return [...keys].every(key => a.hasOwnProperty(key) && b.hasOwnProperty(key) && a[key] === b[key])
}

function stringParts(asset, separator) {
    const str1 = asset.substring(0, asset.indexOf(separator))
    const str2 = asset.substring(asset.indexOf(separator) + 1)
    return [str1, str2]
}

