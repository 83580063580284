import styles from './Modal.module.scss'
import RoundButton from './RoundButton'
const Modal = ( props ) => {
    const { children, closeHandle, style, className, contentStyle } = props;

    return (
        <div className={props.className ? props.className: ""}>
            <div className={styles['modal__overlay']} onClick={closeHandle}></div>
            <div className={`${styles['modal']} ${className?className:""}`} style={style}>
                <div className={`${styles['modal__close']} modal__close`}>
                    <RoundButton onClick={closeHandle} tip="Close"/>
                </div>
                <div className={`${styles['modal__content']} modal__content`}  style={contentStyle}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal