import React from 'react';
// import ReactGA from 'react-ga';
import { NavLink } from 'react-router-dom';
import Seo from '../components/Seo/Seo';
// import { useNavigate } from 'react-router-dom';
// import styles from './Pages.module.scss';
// import { useDetectScroll } from "@smakss/react-scroll-direction";

const About = () => {
	// window.gtag('event', 'page_view', {
	// 	page_title: "Saiba mais",
	// 	page_path: window.location.pathname + window.location.search,
	// 	page_location: window.location.href
	// })

	return (
		<>
			<Seo
				title="Saiba mais sobre o agulhada.com"
				description="Aprenda como o Agulhada.com funciona e assita a um video explicativo sobre como usar a ferramenta para achar as agulhadas do Didi."
				name="Agulhada.com"
				type="website" />
			<header className={`header`}>
				<h1 className="page-name">Saiba mais</h1>
			</header>
			<div className={`container max-w-5xl mx-auto p-4 pt-20`} >
				<h2 className='text-2xl font-medium my-4'>
					Sobre o Agulhada.com
				</h2>
				<p className='mb-4'>Este site foi criado para nos ajudar a achar as oportunidades nos mercados mundo afora.</p>
				<p className='mb-4'>O agulhada.com &eacute; baseado no trading system do Didi Aguiar.</p>
				<p className='mb-4'>Quem fez o curso do Didi e sabe falar grafiqu&ecirc;s, entende como ler os sinais. Se ainda n&atilde;o fez o curso <a href="https://didiaguiar.com/" target="_blank" rel="noreferrer">Falando Grafiqu&ecirc;s</a>,
					tamb&eacute;m conhecido como M&eacute;todo CST - (Cansei de Ser Trouxa), recomendo!</p>
				<p className='mb-4'>Fa&ccedil;a o curso e depois volta aqui no agulhada.com.</p>
				<p className='mb-4'>Ou ent&atilde;o procure a <a href="https://www.google.com/search?q=apostila+doji+star" target="_blank" rel="noreferrer">Apostila Doji Star</a> e d&aacute; uma &quot;papirada&quot;.</p>

				<h2 className='text-2xl font-medium my-4'>Como funciona</h2>
				<p className='mb-4'>Nosso robô com algoritmos customizados e super complexos lê os gráficos de 600 ativos nos vários tempos-gráficos e envia os dados dos indicadores para o agulhada.com.</p>
				<p className='mb-4'>A calibragem dos indicadores segue a mesma indicada pelo Didi.</p>
				<ul>
					<li>DMI / ADX - 8, 8</li>
					<li>Bollinger - 8, 2</li>
					<li>Didi index, 3, 8, 20</li>
					<li>Estoc&aacute;stico - 8, 3, 3</li>
					<li>Trix - 9, 4</li>
				</ul>
				<p className='mb-4'>Os dados s&atilde;o enviados de 15 em 15 minutos e alguns ativos tem atraso de 15 minutos.</p>
				<p className='mb-4'>Nenhuma informa&ccedil;&atilde;o pessoal &eacute; coletada pelo agulhada.com</p>
				<p className='mb-4'>Sentiu falta de algum ativo? <NavLink to="/entre-em-contato">Entre em contato</NavLink></p>

				<h2 className='text-2xl font-medium my-4'>Aten&ccedil;&atilde;o</h2>
				<p className='mb-4'>Nenhum dos sinais que voc&ecirc; encontra neste site pode ser considerado recomenda&ccedil;&atilde;o de investimento ou trade.</p>
				<p className='mb-4'>Verifique sempre os gr&aacute;ficos e fa&ccedil;a a sua pr&oacute;pria an&aacute;lise.</p>
				<p className='mb-4'>N&atilde;o nos responsabilizamos por perdas e decis&otilde;es baseadas em informa&ccedil;&otilde;es deste site.</p>

				<h2 className='text-2xl font-medium my-4'>Veja como ler os sinais.</h2>
				<p className='mb-4'>Assine <a href="https://www.youtube.com/@agulhada_com" target="_blank" rel="noreferrer">nosso canal no youtube.</a></p>

				<iframe width="100%" height="315" src="https://www.youtube.com/embed/mDYBUvHGY7o"
					style={{ "maxWidth": "560px" }}
					title="YouTube video player" frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

				<h2>Aprenda como criar alertas.</h2>
				<p className='mb-4'>Assine <a href="https://www.youtube.com/@agulhada_com" target="_blank" rel="noreferrer">nosso canal no youtube.</a></p>

				<iframe width="100%" height="315" src="https://www.youtube.com/embed/ehFYMr_jHQY?si=5ABo-np4HhO5qwIr"
					style={{ "maxWidth": "560px" }}
					title="YouTube video player" frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

				<p className='mb-4'>Boa sorte e bons trades!</p>
			</div>
		</>
	);
};

export default About;
