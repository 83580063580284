const didiindex = `
<div class="ad">
<h2>Didi-Index além das "agulhadas"</h2>
<a href="https://www.amazon.com.br/Didi-Index-al%C3%A9m-das-agulhadas-Diverg%C3%AAncias-ebook/dp/B0B9JM5WZN?&linkCode=li3&tag=agulhada-20&linkId=02456104fcf7b44b523e27b28d394694&language=pt_BR&ref_=as_li_ss_il" target="_blank">
<img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0B9JM5WZN&Format=_SL250_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=agulhada-20&language=pt_BR" ></a>
<img src="https://ir-br.amazon-adsystem.com/e/ir?t=agulhada-20&language=pt_BR&l=li3&o=33&a=B0B9JM5WZN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
<br />
<a class="calltoaction" target="_blank" href="https://www.amazon.com.br/Didi-Index-al%C3%A9m-das-agulhadas-Diverg%C3%AAncias-ebook/dp/B0B9JM5WZN?&linkCode=li3&tag=agulhada-20&linkId=02456104fcf7b44b523e27b28d394694&language=pt_BR&ref_=as_li_ss_il">
Compre na Amazon
</a>
</div>
`

export default didiindex;