const albrooks = `
<div class="ad">
<h2>Price action</h2>
<h3>Para quem leva trading a sério </h3>
<a href="https://amzn.to/3Ll63fd" target="_blank">
<img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0470443952&Format=_SL250_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=agulhada-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=agulhada-20&language=pt_BR&l=li3&o=33&a=0470443952" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
<p>Para ser um trader completo, além de saber ler o indicadores, tem que saber price action. O livro é inglês, mas vale a pena.</p>
<a class="calltoaction" target="_blank" href="https://amzn.to/3Ll63fd">
Compre na Amazon
</a>
</div>
`

export default albrooks;