import { useState } from "react"
import { ReactComponent as IconLoading } from "../../resources/rolling.svg";

export default function ButtonLoad(props) {
    const [loading, setLoading] = useState(props.loading || false)

    async function click() {
        setLoading(true)
        props.onClick().then(()=>{
            if(!props.keeploading) {
                setLoading(false)
            }
        });      
    }

    return (
        <>
        { loading === true ?
            <IconLoading {...props.svg} />
            :
            <button {...props} onClick={click} className={props.className}>{props.children}</button>
        }
        </>
    )
}