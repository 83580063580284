const assine1 = `
<div class="ad">
<h2 class="text-3xl my-2">Alertas no seu Telegram</h2>
<h3 class="text-2xl my-2">Nunca mais deixe uma oportunidade passar</h3>
<a href="/assinatura" class="m-auto">
<img class="mx-auto my-4" border="0" width="100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png?20220101141644" ></a>
<p>Fácil de configurar e usar.<br />7 dias para testar.</p>
<a class="calltoaction mx-auto my-4" href="/assinatura">
Assine já
</a>
</div>
`

const assine2 = `
<div class="ad">
<h2 class="text-3xl my-2">Alertas ilimitados no seu celular ou computador</h2>
<h3 class="text-2xl my-2">Deixe nosso robô trabalhar para você</h3>
<a href="/assinatura">
<img class="mx-auto my-4" border="0" width="100" src="https://static.cdnlogo.com/logos/g/59/google-chrome.svg" ></a>
<p>7 dias para testar.<br />Cancele quando quiser.</p>
<a class="calltoaction  mx-auto my-4" href="/assinatura">
Assine já
</a>
</div>
`

const assine3 = `
<div class="ad">
<h2 class="text-3xl my-2">Tá de saco cheio desses anúncios?</h2>
<h3 class="text-2xl my-2">Eles não aparecem para os assinantes do agulhada.com</h3>
<p>É só <a href="/assinatura">escolher um plano e assinar</a>.<br />7 dias para testar. Cancele quando quiser.</p>
<a class="calltoaction  mx-auto my-4" href="/assinatura">
Assine já
</a>
</div>
`

const assine4 = `
<div class="ad">
<h2 class="text-3xl my-2">Se você está vendo este anúncio, já está atrasado.</h2>
<h3 class="text-2xl my-2">Os dados para não assinantes tem atraso de 15 minutos.</h3>
<p>Vai ficar pra trás? <a href="/assinatura">Escolha um plano, assine</a> e não perca o próximo trade.<br />7 dias para testar. Cancele quando quiser.</p>
<a class="calltoaction mx-auto my-4" href="/assinatura">
Assine já
</a>
</div>
`

export {assine1, assine2, assine3, assine4};