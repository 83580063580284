import capa from './capa.jpeg';
const candlestick = `
<div class="ad">
<h2>O livro que o Didi recomenda está aqui.</h2>
<a target="_blank" href="https://www.amazon.com.br/Candlestick-Charting-Explained-Timeless-Techniques/dp/007146154X/ref=sr_1_1?keywords=candlestick+charting+explained&amp;qid=1681816257&amp;sprefix=candlestick%252Caps%252C289&amp;sr=8-1&amp;ufe=app_do%253Aamzn1.fos.25548f35-0de7-44b3-b28e-0f56f3f96147&_encoding=UTF8&tag=agulhada-20&linkCode=ur2&linkId=ae918b1fc797c572025a3f6ac8160f34&camp=1789&creative=9325">
<img height="210" src=${capa} alt="QR Code" />
</a>
<br /><br />
<a class="calltoaction" target="_blank" href="https://www.amazon.com.br/Candlestick-Charting-Explained-Timeless-Techniques/dp/007146154X/ref=sr_1_1?keywords=candlestick+charting+explained&amp;qid=1681816257&amp;sprefix=candlestick%252Caps%252C289&amp;sr=8-1&amp;ufe=app_do%253Aamzn1.fos.25548f35-0de7-44b3-b28e-0f56f3f96147&_encoding=UTF8&tag=agulhada-20&linkCode=ur2&linkId=ae918b1fc797c572025a3f6ac8160f34&camp=1789&creative=9325">
Compre na Amazon
</a>
</div>
`

export default candlestick;