const touro = `
<div class="ad">
<h2>Estatueta Touro de Wall Street</h2>
<a href="https://www.amazon.com.br/Est%C3%A1tua-estatuetas-presentes-representam-carreira/dp/B0C6Q549BX?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PYMR95QECDA1&keywords=mercado+financeiro&qid=1685683932&sprefix=mercado+financeiro%2Caps%2C395&sr=8-1&ufe=app_do%3Aamzn1.fos.6121c6c4-c969-43ae-92f7-cc248fc6181d&linkCode=li3&tag=agulhada-20&linkId=ad96d53cf442bed8c528e4e4edd9acc9&language=pt_BR&ref_=as_li_ss_il" target="_blank">
<img border="0" width="80%" src="https://m.media-amazon.com/images/I/419+Avd7haL._AC_.jpg" >
</a>
<img src="https://ir-br.amazon-adsystem.com/e/ir?t=agulhada-20&language=pt_BR&l=li3&o=33&a=B0C6Q549BX" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />
<a class="calltoaction" href="https://www.amazon.com.br/Est%C3%A1tua-estatuetas-presentes-representam-carreira/dp/B0C6Q549BX?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=PYMR95QECDA1&keywords=mercado+financeiro&qid=1685683932&sprefix=mercado+financeiro%2Caps%2C395&sr=8-1&ufe=app_do%3Aamzn1.fos.6121c6c4-c969-43ae-92f7-cc248fc6181d&linkCode=li3&tag=agulhada-20&linkId=ad96d53cf442bed8c528e4e4edd9acc9&language=pt_BR&ref_=as_li_ss_il" target="_blank">
Compre na Amazon
</a>
</div>
`

export default touro;