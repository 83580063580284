import React, {useState, useContext} from 'react';
import { UserContext } from '../UserContext';

// import { NavLink } from 'react-router-dom';

// import { useNavigate } from 'react-router-dom';
// import ReactGA from "react-ga";
import Seo from '../components/Seo/Seo';

import styles from './Pages.module.scss'


const Contratar = () => {
	const user = useContext(UserContext)

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [name, setName] = useState(user.displayName)
	const [email, setEmail] = useState(user.clientPrincipal?.userDetails)

	function handleSubmit(e) {
		// Prevent the browser from reloading the page
		e.preventDefault();
	
		// Read the form data
		const form = e.target;
		const formData = new FormData(form);
		// console.log(FormData)
		const formJson = Object.fromEntries(formData.entries());

		// You can pass formData as a fetch body directly:
		fetch('/api/catchEmail', {
			method: form.method,
			headers: {
				"Content-Type": "application/json",
			  },
			body: JSON.stringify(formJson)
		});

		setFormSubmitted(true)
		// const assetsArray = await (await fetch(`/api/getAllAssets`)).json();
	
		// Or you can work with it as a plain object:
		// console.log(formJson);
	  }

	return (
		<>
		<Seo
			title="Assinaturas esgotadas"
			description="As assinaturas para a melhor ferramenta para achar as agulhadas do Didi esgotaram."
			name="Agulhada.com"
			type="website" />
		<header className={`header`}>
			<h1 className="page-name">Contratar assinatura</h1>
		</header>
		<div className={styles.container}>
			{/* <button className="btn" onClick={() => navigate(-1)}>
				Go Back
			</button> */}
			<div className="title">
				<h1>Assinaturas esgotadas.</h1>
				<p>Devido ao enorme sucesso do { } <strong>agulhada.com</strong> { } os planos disponíveis esgotaram.</p>
                <p>Limitamos o número de usuários assinantes e convidados para manter o alto padrão 
                    de serviços prestados e da experiência de todos os usuários.</p>


				{
				!formSubmitted &&
				<form method="post" onSubmit={handleSubmit} encType='application/x-www-form-urlencoded' className='form__subscribe'>
					<p>Se desejar, deixe seu nome e endereço de email abaixo e entraremos em contato quando
						a assinatura que deseja estiver disponível.</p>					<div>
						Qual plano gostaria de assinar?
						<br />
						<input id="assinantem" name="plano" type='radio' value="Assinante mensal" /> { }
						<label htmlFor="assinantem">Assinante mensal - R$ 150/mês</label>
						<br />
						<input id="assinantea" name="plano" type='radio' value="Assinante anual" /> { }
						<label htmlFor="assinantea">Assinante anual - R$ 1500/ano</label>
						<br />

						<input id="vipm" name="plano" type='radio' value="VIP mensal" /> { }
						<label htmlFor="vipm">VIP mensal - R$ 200/mês</label><br />
						<input id="vipa" name="plano" type='radio' value="VIP anual" /> { }
						<label htmlFor="vipa">VIP anual - R$ 2000/ano</label><br />
					</div>

					<div>
						<label htmlFor="nome">Nome</label><br />
						<input id="nome" name="nome" type='text' value={name} onChange={e => setName(e.target.value)} />
					</div>
					<div>
						<label htmlFor="email">Email</label><br />
						<input id="email" name="email"  type='email' value={email} onChange={e => setEmail(e.target.value)} />
					</div>

					<div>
							<button className="calltoaction" type="submit">Cadastrar</button>
						</div>
				</form>}

				{
				formSubmitted &&
				<div>
					<h2>
						Obrigado!
					</h2>
					<p>Entraremos em contato assim que novas assinaturas estiverem disponíveis.</p>
					<p>Em breve você poderá fazer um "Free Trial" das funcionalidades exclusivas para assinantes.</p>
				</div>
				}
				
			</div>
			
		</div>
		</>
	);
};

export default Contratar;