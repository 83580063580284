
import styles from './ui.module.scss'

const RoundButton = (props) => {

    const {tip, icon, onClick, disabled, className, style} = props

    const allStyles = {
        opacity: disabled===true?"0.4":"1",
        ...style,
    }
    return (
        <div role='button' 
            title={tip || "Close"} className={`${styles["round-button"]} ${className || ""}`}
            style={allStyles}
            onClick={!disabled?onClick:()=>{}}>
            {icon || "✗"}
        </div>
    )
}

export default RoundButton;
