const ib = `
<div class="ad">
<h2 class="text-3xl my-2">Quer começar a operar no exterior e ainda ganhar at&eacute; US$1000 em a&ccedil;&otilde;es da Interactive Brokers?</h2>
<p>Você ganha $1 para cada $100 que depositar durante o primeiro ano.<br />
É 1% de gain, já na largada.</p>
<a class="calltoaction mx-auto my-4" target="_blank" href="https://ibkr.com/referral/vitor264">Abra uma conta na IB</a>
<br/>
<a class="mx-auto my-4 tc" target="_blank" href="https://ndcdyn.interactivebrokers.com/aces/Agreement/AgreementVersion/4051">Leia os termos &amp; condições (em ingl&ecirc;s)</a>
</div>
`

export default ib;