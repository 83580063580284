import React from 'react';
import Seo from '../components/Seo/Seo';

function Faq() {

	return (
		<>
			<Seo
				title="Perguntas mais frequentes sobre o agulhada.com"
				description="Veja o que as pessoas mais perguntam sobre o Agulhada.com"
				name="Agulhada.com"
				type="website" />
			<header className={`header`}>
				<h1 className="page-name">Perguntas mais frequentes</h1>
			</header>
			<div className={`container max-w-5xl mx-auto p-4 pt-20`} >
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Posso pagar com boleto bancário?
				</h2>
				<p className='mb-10'>
					Sim. O pagamento por boleto bancário está disponível no Portal Stripe. O pagamento por boleto demora 3 dias úteis para ser processado.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Posso pagar com PIX?
				</h2>
				<p className='mb-10'>
					Para pagar com PIX, é necessário entrar em contato com pelo email contato@agulhada.com e organizar o pagamento. O processamento do pagamento por PIX não é automático e pode demorar tanto quanto o boleto bancário.			</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Tem como testar os alertas sem informar o cartão de crédito?
				</h2>
				<p className='mb-10'>
					Você pode fazer a assinatura com Boleto bancário e aproveitar o período de testes. Se o Boleto não for pago, a assinatura será cancelada automaticamente.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Posso adicionar ativos que não estão listados no Agulhada.com?
				</h2>
				<p className='mb-4'>
					Os assinantes do plano VIP podem solicitar a inclusão de ativos para monitoramento através do email contato@agulhada.com. O sistema tem uma limitação técnica, então nem toda solicitação será necessariamente atendida.</p>
				<p className='mb-10'>Os assinantes pagos do TradingView podem usar um indicador próprio do Agulhada.com que possibilita o envio de alertas para os usuários VIP.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Funciona no Profit (e outras plataformas)?
				</h2>
				<p className='mb-10'>
					O Agulhada.com é um sistema independente de qualquer plataforma. Nenhuma instalação é necessária.
				</p>

				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Posso configurar alertas no tempo-gráfico de 5 minutos?
				</h2>
				<p className='mb-4'>
					Não. O Agulhada.com é um robô scanner desenvolvido para os traders de tempos-gráficos maiores (swing traders) que não querem passar horas olhando a tela do computador.
				</p>
				<p className='mb-10'>
					Os tempos-gráficos disponívis são: 15, 60, Diário e Semanal. 4 Horas nos ativos Forex.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					É possível filtrar e mostrar só os ativos que estão agulhando?
				</h2>
				<p className='mb-10'>
					Sim. Existe a funcionalidade de ordenar ativos por agulhada, tendência entrante, ponto falso, abertura de Bollinger, etc.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Se eu fizer o Curso CST do Didi, eu ganho um voucher?
				</h2>
				<p className='mb-10'>
					O agulhada.com não tem vinculo formal com a equipe de cursos do Didi Aguiar. As promoções de voucher acontecem de tempos em tempos e não há garantia de que acontecerão no futuro.
				</p>
				<h2 className='text-2xl font-medium my-4 text-orange-400'>
					Tenho outra dúvida, como posso entrar em contato?
				</h2>
				<p className='mb-10'>
					Envie um email para contato@agulhada.com e teremos prazer em responder.
				</p>
			</div>
		</>
	);
};

export default Faq;

// 
// 



