import capa from './Hub_Premiere_1200x1200.jpeg';
const link = "https://www.primevideo.com/offers/?benefitId=premierefcbr&_encoding=UTF8&linkCode=ib1&tag=agulhada-20&linkId=9574d2cf0172e8f8ab44cfd8fcae3b22&ref_=ihub_curatedcontent_daedafda-85f2-4085-a0c7-42c4b650c7fa"
const amazonfutebol = `
<div class="ad">
<h2>Brasileirão no Prime Video</h2>
<p>Canal Premiere - Aqui tem Futebol e muito mais.</p>
<a target="_blank" href="${link}">
<img width="300" src="${capa}" alt="Brasileirão no Prime Video" />
</a>
<br />
<a class="calltoaction" target="_blank" href="${link}">Assine Prime Video
</a>
</div>
`

export default amazonfutebol;