import { useContext, useState, useRef } from 'react';
import { CartContext } from '../../CartContext';
import styles from './Product.module.scss';
import { ReactComponent as IconCross } from "../../resources/cross.svg";


const ProductCard = (props) => {
    // console.log(props)
    const { prices, images, name, description, default_price } = props;
    const cart = useContext(CartContext)
    const [selectedPriceId, setSelectedPriceId] = useState(default_price);
    const defaultPrice = prices.find(p=>p.id===default_price)
    const [selectedPrice, setSelectedPrice] = useState(defaultPrice.unit_amount||0);
    const [modalImageSize, setModalImageSize] = useState({width:"100%", height:""})

    const imagesModal = useRef(null)

    const handleChangePrice = (evTarget) => {
        setSelectedPriceId(evTarget.value)
        setSelectedPrice(evTarget.getAttribute("data-amount"))
    }

    const openDialog = () => {
        console.log(images)

        const modal = imagesModal.current;
        modal.showModal();
        if(modal.clientHeight > modal.clientWidth) {
            setModalImageSize({width:"100%", height:""})
        } else {
            setModalImageSize({width:"", height:"100%"})
        }
    }

    const closeDialog = () => {
        imagesModal.current.close();
    }


    return ( 
        <>
        <div className={`${styles.product}`} bp="grid">
            { images.length > 0 && <img src={images[0]}  width="100%" alt="product" bp="5" className={styles.product__image} onClick={openDialog}/> }
            <div bp="7">
                <h2 className={`${styles['product__name']}`}>{name}</h2>
                <p>{description}</p>
                <span className={`${styles['product__price']}`}>R$ {(selectedPrice/100).toFixed(2)}</span>                
            { prices.length > 1 && 
                <fieldset className={`${styles['fieldset']}`}>
                <legend>Escolha uma opção</legend>
                <div>
                    { prices.map((price, i) => {
                        return (
                            <div key={`op_${price.id}`} >
                                <input type="radio"
                                        id={`option_${price.id}`}
                                        value={price.id} 
                                        data-amount={price.unit_amount}
                                        onChange={(e)=>{handleChangePrice(e.target)}}
                                        checked={price.id===selectedPriceId}
                                />
                                <label htmlFor={`option_${price.id}`}>{price.nickname} - R$ {(price.unit_amount/100).toFixed(2)}</label>
                            </div>
                        )
                    }) } 

                </div>
            </fieldset>
            }
            <button
                className={`${styles['button']} ${styles['button--add']}`}
                onClick={()=>{cart.addOneToCart(selectedPriceId, props)}}>Adicionar ao carrinho</button>
            </div>
        </div>
        <dialog ref={imagesModal} className={`${styles['images-dialog']}`}>
            <div style={{"height":"100%","width":"100%"}}>
                {
                images.length > 0 && images.map((imageUrl, i)=>
                        <img src={imageUrl}
                        key={`id_${i}`}
                        width={modalImageSize.width}
                        height={modalImageSize.height}
                        alt="product" />
                        )
                }
            </div>
            <button onClick={closeDialog} className={`${styles['images-dialog__close-button']}`}><IconCross /></button>
        </dialog>
        </>
        )
}

export default ProductCard;