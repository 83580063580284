import { useEffect, useRef, useState } from "react";
import { AlertSetup } from "./AlertSetup";
import styles from './Alerts.module.scss';
import { Permission } from "./Permission";
import ButtonLoad from "../Ui/ButtonLoad";
import templates from "../StaticData/templates";
// import { titleize } from "i/lib/methods";

export function isGranted() {
  if (!("Notification" in window)) {
    return false;
  } else {
    if(Notification.permission === "granted") {
      return true
    }
  }
  return false
}

export default function Alerts(props) {
  const { asset, timeFrames, saveAlert, deleteAlert, resizeContainer, alertTimeFrame } = props;
  const [alerts, setAlerts] = useState(props.alerts);
  const [showForm, setShowForm] = useState(false);
  const [autorizado, setAutorizado] = useState(isGranted());
  const [selectedTimeframe, setSelectedTimeframe] = useState(alertTimeFrame);
  const [alertsInTimeframe, setAlertsInTimeframe] = useState(props.alerts.filter(a => a.timeframe === alertTimeFrame)[0]?.alerts || []);
  const [currentAlert, setCurrentAlert] = useState({});
  const container = useRef();

  useEffect(() => {
    if (alerts.length > 0) {
      setAlertsInTimeframe(alerts.filter(a => a.timeframe === selectedTimeframe)[0]?.alerts || [])
    }
    resizeContainer()
  }, [selectedTimeframe, setAlertsInTimeframe, alerts, resizeContainer])

  useEffect(()=>{setSelectedTimeframe(alertTimeFrame)},[alertTimeFrame, setSelectedTimeframe])

  if (!("Notification" in window)) {
    return <div>Notificações push não funcionam com este navegador.</div>
  }

  if (autorizado === false) {
    return (
      <Permission setAutorizado={setAutorizado} />
    )
  }

  function editAlert(id) {
    setCurrentAlert(alertsInTimeframe.filter(a => a.id === id)[0] || {})
    setShowForm(true)
  }

  function testNotify(title, body) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("Notificações push não funcionam com este navegador");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      setAutorizado(true)
      new Notification(title,
        {
          icon: "https://www.agulhada.com/logo512.png",
          body: body,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
        });
      // console.log(notification)
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then(async (permission) => {
        // If the user accepts, let's create a notification
        registerServiceworker()
        if (permission === "granted") {
          setAutorizado(true)
          // …
        } else {
          setAutorizado(false)
        }
      });
    }
  }

  async function saveThisAlert(alertConfig) {
    const alertToSave = {}
    alertToSave.config = alertConfig;
    alertToSave.message = alertConfig.message;
    delete alertToSave.config.message;
    alertToSave.rules = alertConfig.rules;
    delete alertToSave.config.rules;
    alertToSave.active = alertConfig.active || true
    delete alertToSave.config.active;
    alertToSave.frequency = alertConfig.frequency || "tf"
    delete alertToSave.config.frequency;


    if (currentAlert.id) {
      alertToSave.id = currentAlert.id;
    }

    if (alertConfig.id) {
      alertToSave.id = alertConfig.id;
    }

    const r = await saveAlert(asset, selectedTimeframe, alertToSave);
    if (r.result) {
      setCurrentAlert({});
      setShowForm(false)
      updateAlertsState(r.result)
    }

    return r
  }

  async function deleteThisAlert(id) {
    if (window.confirm("Excluir alerta?")) {
      const r = await deleteAlert(asset, selectedTimeframe, id);
      if (r.result) {
        updateAlertsState(r.result)
      }
    }
  }

  async function toggleThisAlert(alert) {
    const alertToSave = alert
    alertToSave.active = !alert.active;
    const r = await saveAlert(asset, selectedTimeframe, alertToSave);
    if (r.result) {
      updateAlertsState(r.result)
    }
  }

  function updateAlertsState(newAlertObj) {
    const newAlerts = [...alerts]
    // insert alert in alerts collection if the timeframe is not existing
    if (newAlerts.find((a) => a.timeframe === newAlertObj.timeframe) === undefined) {
      newAlerts.push(newAlertObj)
    }

    // update alerts
    for (const a of newAlerts) {
      if (a.timeframe === newAlertObj.timeframe) {
        a.alerts = newAlertObj.alerts
      }
    }
    setAlerts(newAlerts)
  }

  function timeframeLabel(tf) {
    let timeFrameLabel = ""
    switch (tf) {
      case '15':
        timeFrameLabel = "nos 15 minutos"
        break;
      case 'H':
        timeFrameLabel = "nos 60 minutos (H)"
        break;
      case '60':
        timeFrameLabel = "nos 60 minutos (H)"
        break;
      case '240':
        timeFrameLabel = "nas 4H"
        break;
      case 'D':
        timeFrameLabel = "no diário"
        break;
      case 'W':
        timeFrameLabel = "no semanal"
        break;
      default:
        timeFrameLabel = ""
    }
    return timeFrameLabel
  }

  async function registerServiceworker() {
    if (("Navigator" in window)) {
      await navigator.serviceWorker.register(`alerts-sw.js`);
    }
  }



  return (
    <div>
      <div>
        <h3>Alertas</h3>
        <div className={styles.tabs}>
          {timeFrames.map((tf) => {
            let tfLabel = tf
            let dbTimeframe = tf
            if (tf === "240") { tfLabel = "4H" }
            if (tf === "60") { tfLabel = "H" }
            if (tf === "W") { tfLabel = "S" }
            if (tf === "H") { dbTimeframe = "60" }
            return (
              <div
                key={tf}
                className={`${styles.tab} ${selectedTimeframe === dbTimeframe && styles.selected}`}
                onClick={() => { setSelectedTimeframe(dbTimeframe) }}>
                {tfLabel}
              </div>
            )
          })}
        </div>
      </div>
      <div ref={container} className={styles.alerts}>
        {showForm &&
          <AlertSetup
            currentAlert={currentAlert}
            asset={asset}
            timeFrame={selectedTimeframe}
            resizeContainer={props.resizeContainer}
            onCancel={setShowForm}
            onSave={saveThisAlert}
            testNotify={testNotify}
          />
        }
        {!showForm &&
          <>

            <button className={styles["alert-button"]} onClick={() => { setCurrentAlert({}); setShowForm(true) }}>
              Criar alerta customizado {timeframeLabel(selectedTimeframe)}
            </button>

            {alertsInTimeframe.length > 0 &&
              <>
                <h4>Alertas {timeframeLabel(selectedTimeframe)}</h4>
                <ul className={styles.list}>
                  {/* REDO THIS LOOP */}
                  {alertsInTimeframe.map((alert) => {
                    return (
                      <AlertItem
                        key={alert.id}
                        alert={alert}
                        onToggleAlert={toggleThisAlert}
                        onEditAlert={editAlert}
                        onDeleteAlert={deleteThisAlert} />
                    )
                  })}
                </ul>
              </>
            }

            {/* <h5>Configurações gerais</h5>
              <div>Notificações {autorizado===true?"":"não"} autorizadas</div>
              <button className={styles["alert-button"]} onClick={testNotify}>Enviar uma notificação de teste.</button> */}

            <h4>Templates</h4>
            <div className={styles.template}>
              <span>Agulhada + Tendência acelerante + Bollinger aberto</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["agulhadaCompra"]) }} svg={{height:"26px", width:"60px"}}>Compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["agulhadaVenda"]) }} svg={{height:"26px", width:"60px"}}>Venda</ButtonLoad>
            </div>
            <div className={styles.template}>
              <span>Agulhada santa</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["agulhadaCompraSanta"]) }} svg={{height:"26px", width:"60px"}}>Compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["agulhadaVendaSanta"]) }} svg={{height:"26px", width:"60px"}}>Venda</ButtonLoad>
            </div>
            <div className={styles.template}>
              <span>Alerta + Tendência acelerante + Bollinger aberto</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["alertaCompra"]) }} svg={{height:"26px", width:"60px"}}>Compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["alertaVenda"]) }} svg={{height:"26px", width:"60px"}}>Venda</ButtonLoad>
            </div>
            <div className={styles.template}>
              <span>Tendência entrante + Bollinger aberto</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["compraEntrante"]) }} svg={{height:"26px", width:"60px"}}>Compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["vendaEntrante"]) }} svg={{height:"26px", width:"60px"}}>Venda</ButtonLoad>
            </div>
            <div className={styles.template}>
              <span>Saída do trade com 4 sinais</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["saidaCompra"]) }} svg={{height:"26px", width:"60px"}}>Saída da compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["saidaVenda"]) }} svg={{height:"26px", width:"60px"}}>Saída da venda</ButtonLoad>
            </div>
            <div className={styles.template}>
              <span>Saída do trade pelo Didi Index</span>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["saidaCompraDidi"]) }} svg={{height:"26px", width:"60px"}}>Saída da compra</ButtonLoad>
              <ButtonLoad onClick={async () => { await saveThisAlert(templates["saidaVendaDidi"]) }} svg={{height:"26px", width:"60px"}}>Saída da venda</ButtonLoad>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export function AlertItem(props) {
  const { alert, onToggleAlert, onEditAlert, onDeleteAlert } = props
  const [isActive, setIsActive] = useState(alert.active);

  useEffect(()=>{
    setIsActive(alert.active)
  }, [alert.active, setIsActive])

  return (
    <li key={alert.id}>
      <label className={styles.onoff}>
        <input type="checkbox" checked={isActive} onChange={async () => { setIsActive(!isActive); await onToggleAlert(alert) }} />
      </label>
      <span className={styles.name} onClick={() => { onEditAlert(alert.id) }}>{alert.message}</span>
      <button className={styles["delete"]} onClick={async () => { await onDeleteAlert(alert.id) }}>&#x2715;</button>
    </li>
  )
}

// function template(key) {

//   const templates = {
//     alertaCompra: {
//       "message": "deu alerta de compra",
//       "active": true,
//       "tendenciaCompra": true,
//       "tendenciaVenda": false,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "alerta": 1,
//       "bollingerStatus": 1,
//       "rules": [
//         { "name": "Tendencia", "value": "1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Alerta", "value": "1" },
//         { "name": "Bollinger", "value": "1" }
//       ],
//     },
//     alertaVenda: {
//       "message": "deu alerta de venda",
//       "active": true,
//       "tendenciaCompra": false,
//       "tendenciaVenda": true,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "alerta": -1,
//       "bollingerStatus": 1,
//       "rules": [
//         { "name": "Tendencia", "value": "-1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Alerta", "value": "-1" },
//         { "name": "Bollinger", "value": "1" }
//       ],
//     },
//     agulhadaCompra: {
//       "message": "deu agulhada de compra",
//       "active": true,
//       "tendenciaCompra": true,
//       "tendenciaVenda": false,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "agulhado": 1,
//       "bollingerStatus": 1,
//       "rules": [
//         { "name": "Tendencia", "value": "1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Agulhada", "value": "1" },
//         { "name": "Bollinger", "value": "1" }
//       ]
//     },
//     agulhadaCompraSanta: {
//       "message": "deu agulhada SANTA de compra",
//       "active": true,
//       "tendenciaCompra": true,
//       "tendenciaVenda": false,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "agulhado": 1,
//       "bollingerStatus": 1,
//       "trixStatus": 1,
//       "stockStatus": 1,
//       "rules": [
//         { "name": "Tendencia", "value": "1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Agulhada", "value": "1" },
//         { "name": "Bollinger", "value": "1" },
//         { "name": "Stochastic", "value": "1" },
//         { "name": "Trix", "value": "1" }
//       ]
//     },
//     agulhadaVenda: {
//       "message": "deu agulhada de venda",
//       "active": true,
//       "tendenciaCompra": false,
//       "tendenciaVenda": true,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "agulhado": -1,
//       "bollingerStatus": 1,
//       "rules": [
//         { "name": "Tendencia", "value": "-1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Agulhada", "value": "-1" },
//         { "name": "Bollinger", "value": "1" },
//       ]
//     },
//     agulhadaVendaSanta: {
//       "message": "deu agulhada SANTA de venda",
//       "active": true,
//       "tendenciaCompra": false,
//       "tendenciaVenda": true,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "agulhado": -1,
//       "bollingerStatus": 1,
//       "trixStatus": -1,
//       "stockStatus": -1,
//       "rules": [
//         { "name": "Tendencia", "value": "-1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "Agulhada", "value": "-1" },
//         { "name": "Bollinger", "value": "1" },
//         { "name": "Stochastic", "value": "-1" },
//         { "name": "Trix", "value": "-1" }
//       ]
//     },
//     compraEntrante: {
//       "message": "entrou em tendência de compra",
//       "active": true,
//       "tendenciaEntrante": 1,
//       "bollingerStatus": 1,
//       "tendenciaCompra": true,
//       "tendenciaVenda": false,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "rules": [
//         { "name": "Tendencia", "value": "1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "TendenciaEntrante", "value": "1" },
//         { "name": "Bollinger", "value": "1" }
//       ]
//     },
//     vendaEntrante: {
//       "message": "entrou em tendência de venda",
//       "active": true,
//       "tendenciaEntrante": 1,
//       "bollingerStatus": 1,
//       "tendenciaCompra": false,
//       "tendenciaVenda": true,
//       "temTendencia": true,
//       "tendenciaAcelerante": true,
//       "rules": [
//         { "name": "Tendencia", "value": "-1" },
//         { "name": "TendenciaAcelerante", "value": "true" },
//         { "name": "TendenciaEntrante", "value": "1" },
//         { "name": "Bollinger", "value": "1" }
//       ],
//     },
//     saidaCompra: {
//       "message": "tem 4 sinais para sair da compra",
//       "active": true,
//       "tendenciaAcelerante": false,
//       "bollingerStatus": -1,
//       "stockStatus": -1,
//       "trixStatus": -1,
//       "rules": [
//         { "name": 'TendenciaAcelerante', "value": 'false' },
//         { "name": "Bollinger", "value": "-1" },
//         { "name": "Stochastic", "value": "-1" },
//         { "name": "Trix", "value": "-1" }
//       ],
//     },
//     saidaVenda: {
//       "message": "tem 4 sinais para sair da venda",
//       "active": true,
//       "tendenciaAcelerante": false,
//       "bollingerStatus": -1,
//       "stockStatus": 1,
//       "trixStatus": 1,
//       "rules": [
//         { "name": 'TendenciaAcelerante', "value": 'false' },
//         { "name": "Bollinger", "value": "-1" },
//         { "name": "Stochastic", "value": "1" },
//         { "name": "Trix", "value": "1" }
//       ],
//     },
//     saidaCompraDidi: {
//       "message": "deu saída da compra pelo Didi index",
//       "active": true,
//       "temTendencia": true,
//       "alerta": -1,
//       "pontofalso": 0,
//       "rules": [
//           { "name": "Alerta", "value": "-1" },
//           { "name": "TendenciaExistente", "value": "true" },
//           { "name": "PontoFalso", "value": "0" }
//       ],
//     },
//     saidaVendaDidi: {
//       "message": "deu saída da venda pelo Didi index",
//       "active": true,
//       "temTendencia": true,
//       "alerta": 1,
//       "pontofalso": 0,
//       "rules": [
//           { "name": "Alerta", "value": "1" },
//           { "name": "TendenciaExistente", "value": "true" },
//           { "name": "PontoFalso", "value": "0" }
//       ],
//     }
//   }



//   return templates[key]
// }

