import { NavLink } from "react-router-dom";
import styles from './Alerts.module.scss';


export default function AlertsMenu() {
    return (
        <ul className={`${styles.menu} grid sm:grid-cols-4 grid-cols-2 mb-4 max-w-5xl mx-auto text-center`}>
            <li><NavLink to="/notificacoes" className={({ isActive }) => isActive ? `${styles.link} ${styles.active}` : `${styles.link}` }>Notificações</NavLink></li> 
            <li><NavLink to="/templates" className={({ isActive }) => isActive ? `${styles.link} ${styles.active}` : `${styles.link}` }>Templates</NavLink></li> 
            <li><NavLink to="/alertas" className={({ isActive }) => isActive ? `${styles.link} ${styles.active}` : `${styles.link}` }>Alertas</NavLink> </li>
            <li><NavLink to="/dispositivos" className={({ isActive }) => isActive ? `${styles.link} ${styles.active}` : `${styles.link}` }>Dispositivos</NavLink></li> 
        </ul>
    )
}