import React, {useContext, useEffect, useMemo, useState} from 'react';
import { UserContext } from '../UserContext';
import { NavLink } from 'react-router-dom';
import Seo from '../components/Seo/Seo';
import styles from './Pages.module.scss';
import { ReactComponent as Logo } from "../resources/logo_agulhada_reverse.svg";


const Home = () => {
	const user = useContext(UserContext)
	const [agulhadas, setAgulhadas] = useState({"venda": "", "compra":""})
	const [showAssineja, setShowAssineja] = useState(false)

	useEffect(() => {
		const dataFetch = async () => {
			const agulhadas = await (
				   await fetch(
					   "/api/getTotalAgulhadas"
				   )
			   ).json();

			   const deVenda = agulhadas.filter(a=>a.direcao==="venda")[0].total
			   const deCompra = agulhadas.filter(a=>a.direcao==="compra")[0].total
			   setAgulhadas({"venda": deVenda, "compra":deCompra})
		   };
		dataFetch();
	}, [setAgulhadas])

	useMemo(()=>{
		if(user.assinaturas.length > 0 && !user.assinaturas.find(a=>(a.status === "active" || a.status === "trialing"))) {
			setShowAssineja(true)
		}

	}, [user.assinaturas])

		return (
		<>
			<Seo
				title="Agulhada.com"
				description="Ache as melhores oportunidades de trade e agulhadas do Didi Aguiar"
				name="Agulhada.com"
				type="website" />
			<header className="header">
				<h1 className="page-name text-center">
					<Logo className="h-14 inline-block" />
				</h1>
				{ user.clientPrincipal && <a className='calltoaction' href="/.auth/logout" rel="noreferrer">Sair</a> }
				{ !user.clientPrincipal && <a className='calltoaction' href="/.auth/login/aadb2c" rel="noreferrer">Entrar</a> }
			</header>
			<div className={styles.container}>
				<div className={styles.home_banner} bp="grid">
					<div className={styles.hero} bp="12 6@md"></div>
					<div className={`card ${styles.card}`} bp="12 6@md">
						<h2 className='font-bold leading-5 my-8'>
						{user.displayName && <>{user.displayName}, <br /></>}
						Ache as melhores oportunidades de trade.<br />
						Com velocidade máxima!
						</h2>
						
						{ showAssineja &&
						<>
							<p>Novas assinaturas tem 7 dias para testar os alarmes e outros recursos.</p>
							<p className='card my-4'>
								<NavLink to="/assinatura" className='calltoaction' > Assine já </NavLink>
							</p>
						</>
						}
						
						
						<p>Nosso robô rastreador de Agulhadas do Didi tem agora <br/> <strong>{agulhadas.compra} agulhadas de compra e <br/>{agulhadas.venda} de venda</strong> pra você.</p>
						{ !user.clientPrincipal &&
							<p className='card my-4'>
								<a className='calltoaction' href="/.auth/login/aadb2c" rel="noreferrer">Cadastre-se grátis</a></p>
						}
						{ user.clientPrincipal &&
						<>
							<p className='card my-4'>
								<NavLink to="/app/BMFBOVESPA/stock" className='calltoaction' > Ver oportunidades </NavLink>
							</p>
						</>
						
						}
					</div>
				</div>

				<div className={`${styles.home_feature}`}> 
				<h2 className='my-8 text-center text-3xl' >Por que usar o agulhada.com</h2>
				<div bp="grid 12@sm 6@md 3@lg">
				<div className={`card ${styles.card}`} bp="text-center">
					<h3 className='text-2xl'>Mais de 600 ativos</h3>
					<p>O agulhada.com monitora centenas de ativos entre B3, FOREX, EUA, Crypto, Índices e Commodities.</p>
				</div>
				<div className={`card ${styles.card}`} bp="text-center">
					<h3 className='text-2xl'>O uso é gratuito e não requer instalação</h3>
					<p>Você só paga para usar as <NavLink to="/assinatura">funcionalidades avançadas</NavLink>.<br/>Você usa no seu computador ou celular sem necessidade de instalações complicadas.</p>
				</div>
				<div className={`card ${styles.card}`} bp="text-center">
					<h3 className='text-2xl'>Economize o seu tempo</h3>
					<p>Você gasta menos de 1 minuto para ver os indicadores do Didi em dezenas de ativos em 4 ou 5 tempos-gráficos.</p>
				</div>
				<div className={`card ${styles.card}`} bp="text-center">
					<h3 className='text-2xl'>Notificação de agulhadas*</h3>
					<p>Receba um alerta quando uma agulhada acontecer nos ativos de sua escolha.<br />* Serviço somente para <NavLink to="/assinatura">assinantes</NavLink>. </p>
				</div>
				</div>

				<h2 className='my-8 text-3xl'>O que é o agulhada.com</h2>
				<p className='mb-4'>
					O agulhada.com é um robô que varre uma lista com mais de 600 ativos nacionais e internacionais, lê e interpreta os indicadores DMI, Bandas de Bollinger, Didi Index, Estocástico e TRIX e salva as informações de 15 em 15 minutos.
					Foi criado por um ex-aluno do Didi Aguiar para ajudar os traders que usam o método CST a achar as agulhadas do Didi e as melhores oportunidades de trade no mercado financeiro.
				</p>
				<p className='mb-4'>
					Com uma interface visual super fácil de utilizar, fica mais rápida a busca por bons trades e agulhadas nas bolsas ao redor do mundo.
				</p>
				<p className='mb-4'>
					<NavLink to="/saiba-mais">Veja como usar o agulhada.com</NavLink>  { } (e assista a um video explicativo)
				</p>
				</div>
				<div bp="grid 4@md 12@sm" className={styles.footer}>
					<div>
						© 2023 Agulhada.com
					</div>
					<div bp="text-left	">
						Dúvidas ou reclamações? contato@agulhada.com
					</div>
					<div bp="text-right">
						Instagram: @agulhada_com | Twitter: @vitorgomes
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
