import {useContext, useEffect, useState } from 'react';
import styles from './Alerts.module.scss';
import stylesPage from '../../pages/Pages.module.scss';
import AlertsMenu from './AlertsMenu';
import { UserContext } from '../../UserContext';
import { Permission } from './Permission';
import { useUserDetails } from '../../hooks/useUserDetails';
import ButtonLoad from '../Ui/ButtonLoad';
import { isGranted } from './Alerts';
import Seo from '../Seo/Seo';


export default function Devices(props) {
    const user = useContext(UserContext)
    const [autorizado, setAutorizado] = useState(isGranted())
    const [devices, setDevices] = useState(user.devices || []);
    const [telegramId, setTelegramId] = useState(user.telegram.id || "");
    const [telegramActive, setTelegramActive] = useState((user.telegram.active === true) ? true : false);
    const [canSubscribe, setCanSubscribe] = useState(true)
    const {mutateUser} = useUserDetails()


    useEffect(()=>{
        setTelegramId(user.telegram.id || "")
        setTelegramActive((user.telegram.active === true) ? true : false)
        // console.log(user.devices)
        setDevices(user.devices || [])
    }, [user])

    async function toggleSubscription(id, active) {
        const response = await fetch("/api/saveSubscription", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id, active })
        })
        const r = await response.json();
        user.setDevices(r.value?.subscriptions);
    }
    
    async function deleteSubscription(id) {
        const response = await fetch("/api/saveSubscription", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id })
        })
        const r = await response.json();
        user.setDevices(r.value?.subscriptions)
    }

    async function saveTelegram() {
        console.log({ id: telegramId, active: telegramActive })
        const response = await fetch("/api/saveTelegram", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: telegramId, active: telegramActive })
        })
        const r = await response.json();
        return r
    }

    // async function toggleTelegram(active) {
    //     const response = await fetch("/api/saveTelegram", {
    //         method: "PUT",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ active })
    //     })
    //     const r = await response.json();
    //     console.log(r)
    //     setTelegramActive(r.value?.telegram.active);
    // }

    async function subscribe() {
        if (("Navigator" in window)) {
            setCanSubscribe(false)
            const resgistration = await window.navigator.serviceWorker.getRegistration(`${window.location.origin}/alerts-sw.js`);            
            if (!resgistration) {
                await navigator.serviceWorker.register(`./alerts-sw.js`);
            }
            window.navigator.serviceWorker.controller.postMessage('subscribe');
            setTimeout(async () => {
                mutateUser('/api/getUserInfo');
                await fetch('/api/saveSubscription?title=Parabéns!&body=Seu navegador foi cadastrado.')
            }, 2000);
        }
    }

    async function testNotification() {
        await fetch('/api/saveSubscription?title=Funcionou!&body=Essa notificação foi enviada como teste.')
        setTimeout(async () => {
            mutateUser('/api/getUserInfo');
        }, 1000);
    }

    return (<>
    <Seo
        title="Dispositivos"
        description="Configure os dispositivos em que você quer receber seus alertas"
        name="Agulhada.com"
        type="website" />
        <header className={`header`}>
            <h1 className="page-name">Alertas</h1>
        </header>
        <div className={stylesPage.container}>
            <AlertsMenu />
            <h2 className='text-2xl font-medium my-4'>Dipositivos</h2>

            <div className={styles["device-form-card"]}>
                <h3 className='text-xl'>Telegram</h3>
                <label>
                    <div>Digite o código informado pelo <a href="https://t.me/Agulhadacom_bot" target="_blank" rel="noreferrer">bot Telegram</a>:</div>
                    <div><input type="text" value={telegramId} onChange={(e)=>{setTelegramId(e.currentTarget.value)}} /></div>
                </label>
                <label>
                    <input type="checkbox" checked={telegramActive} onChange={async (e)=>{setTelegramActive(e.currentTarget.checked)}} /> { }
                    Receber notificação no Telegram
                </label>
                <div>
                    <ButtonLoad onClick={saveTelegram} className={styles["alert-button"]} style={{margin: "1em 0"}}
                    svg={{height:"26px", width:"60px", style:{margin:"1em 0"}}} >Salvar</ButtonLoad>
                </div>
            </div>
            {autorizado === false ?
                <Permission setAutorizado={setAutorizado} />
                :
                <div className={styles["device-form-card"]}>
                    <h3 className='text-xl'>Autorizações </h3><button className='text-amber-500' onClick={()=>{mutateUser('/api/getUserInfo')}}>atualizar</button>
                    <div>
                    { canSubscribe ?
                        <button className={styles["alert-button"]} onClick={subscribe}>Cadastrar este navegador</button>
                    :
                        <div className={styles["note"]}>Se você recebeu uma notificação mas não vê o novo dispositivo na lista, clique em "atualizar". Pode demorar alguns instantes.</div>
                    }
                    </div>
                    <ul bp="grid 12@sm 6@md 4@lg">
                        { devices.map((device, i)=>{
                            const isInactive = device.active === undefined ? false : !device.active;
                            return (
                                <li key={device.id}>
                                    <label>
                                        <input type="checkbox" disabled={device.invalid===true} checked={!isInactive} onChange={async (e)=>{toggleSubscription(device.id, e.currentTarget.checked)}} />
                                        { } <span>{device.name} - adicionado em {new Date(device.date).toLocaleString()}</span>
                                        { device.invalid &&
                                            <div>Erro ao enviar notificação:<div style={{fontSize:"0.8em"}}> "{device.error?.body}".</div>Tente cadastrar este navegador novamente.</div>
                                        }
                                    </label>
                                    <span>
                                        <button onClick={async ()=>{deleteSubscription(device.id)}}>&#x2715;</button>
                                    </span>
                                </li>
                            )
                        }) }
                    </ul>
                    { devices.length > 0 && 
                        <div><button className={styles["alert-button"]} onClick={testNotification}>Testar cadastros</button></div>
                    }
                </div>
            }

            
        </div>
    </>)
}