import piximage from './pixqr.png';

const pix = `
<div class="ad">
<h3>Gostou do agulhada.com?<br/>Faz um PIX e ajude o Vitor a comprar um Fusquinha.</h3>
<img class="mx-auto my-4 w-48" height="190" src=${piximage} alt="QR Code" />
<p>Chave: vitorleo@gmail.com</p>
</div>
`

export default pix;