import tradingview30 from "./Tradingview30";
import didius from "./Didius";
import stoploss from "./StopLoss";
import ib from "./Ib";
import pix from "./Pix"
import candlestick from "./CandleStick"
import markdouglas from "./Markdouglas"
import didiindex from "./DidiIndex"
import proptrader from "./PropTrader";
import amazonfutebol from "./AmazonBrasileirao";
import cj_nordvpn from "./NordVpn";
import albrooks from "./Albrooks";
import lojinha from "./Lojinha";
import axitrader from "./Axitrader";
import touro from "./Tourinho";
import btc from "./Btc";
import { assine1, assine2, assine3, assine4 } from "./Assine";;



const anuncios = [
    {
        "id": 0,
        "name": "TradingView 30",
        "code": {"__html": tradingview30}
    },
    {
        "id": 1,
        "name": "Didius",
        "code": {"__html": didius}
    },
    {
        "id": 2,
        "name": "IB",
        "code": {"__html": ib}
    },
    {
        "id": 3,
        "name": "Stop",
        "code": {"__html": stoploss}
    },
    {
        "id": 4,
        "name": "PIX",
        "code": {"__html": pix}
    },
    {
        "id": 5,
        "name": "CandleStick",
        "code": {"__html": candlestick}
    },
    {
        "id": 6,
        "name": "Markdouglas",
        "code": {"__html": markdouglas}
    },
    {
        "id": 7,
        "name": "DidiIndex",
        "code": {"__html": didiindex}
    },
    {
        "id": 8,
        "name": "PropTrader",
        "code": {"__html": proptrader}
    },
    {
        "id": 9,
        "name": "AmazonFutebol",
        "code": {"__html": amazonfutebol}
    },
    {
        "id": 10,
        "name": "CJ_NordVPN",
        "code": {"__html": cj_nordvpn}
    },
    {
        "id": 11,
        "name": "AlBrooks",
        "code": {"__html": albrooks}
    },
    {
        "id": 12,
        "name": "Lojinha",
        "code": {"__html": lojinha}
    },
    {
        "id": 13,
        "name": "Axitrader",
        "code": {"__html": axitrader}
    },
    {
        "id": 14,
        "name": "Touro",
        "code": {"__html": touro}
    },
    {
        "id": 15,
        "name": "Btc",
        "code": {"__html": btc}
    },
    {
        "id": 16,
        "name": "Telegram",
        "code": {"__html": assine1}
    },
    {
        "id": 17,
        "name": "Chrome",
        "code": {"__html": assine2}
    },
    {
        "id": 18,
        "name": "Chrome",
        "code": {"__html": assine3}
    }
    ,
    {
        "id": 19,
        "name": "Delay",
        "code": {"__html": assine4}
    }
]

export default anuncios