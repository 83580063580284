import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../../UserContext';
import { useMatch, useLocation, NavLink } from 'react-router-dom';
// import { useLocalStorage } from "../../hooks/useLocalStorage";
import Seo from '../Seo/Seo';
import RequestLogin	from '../RequestLogin'
//import ReactPullToRefresh from "react-pull-to-refresh"
import AssetList from '../AssetList/AssetList';
import AgulhadaList from '../AgulhadaList/AgulhadaList';
import ControlBar from '../ControlBar/ControlBar';
import PopupDialog from '../PopupDialog/PopupDialog';
import ControlPanel from '../ControlPanel/ControlPanel';
import SyncFavorites from '../SyncFavorites/SyncFavorites';
import styles from './List.module.scss';
import pageStyles from '../../pages/Pages.module.scss';
import { useDetectScroll } from "@smakss/react-scroll-direction";
import { ReactComponent as IconSearch } from "../../resources/search.svg";
import { ReactComponent as IconCross } from "../../resources/cross.svg";
import { ReactComponent as IconConfig } from "../../resources/config.svg";
import { ReactComponent as IconSort } from "../../resources/sort.svg";
import { ReactComponent as IconCloud } from "../../resources/cloud.svg";

// import { ReactComponent as IconLists } from "../../resources/lists.svg";
import { useAllAssets } from '../../hooks/useAllAssets';
import { deleteAlert, saveAlert } from '../Alerts/AlertsApi';


function List(props) {
  const user = useContext(UserContext)
  const [filteredData, setFilteredData] = useState([]);
  const [isRedirected, setIsRedirect] = useState(false)
  const [query, setQuery] = useState('');
  const inputQuery = useRef(null);
  const [showBusca, setShowBusca] = useState(false);
  const [showSyncFavoritos, setShowSyncFavoritos] = useState(false)
  const [scrollDir] = useDetectScroll({});
  const showHideClass = scrollDir === "down" ? "header--hidden" : "";
  const urlLocation = useMatch('/:list/:exchange/*')
  const urlType = useMatch('/:list/:exchange/:assetType')
  const urlList = useMatch('/:list/*')
  const location = useLocation();
  const [isSubscriber, setIsSubscriber] = useState(false)

  const firstListId = user.favoriteList[0]?.id || 0
  const [currentFavoritesList, setCurrentFavoritesList] = useState(firstListId)
  const [currentListName, setCurrentListName] = useState(user.favoriteList[0]?.name || "Favoritos")
  const allowFavorites = user.clientPrincipal?.userRoles?.includes('VIP') || user.clientPrincipal?.userRoles?.includes('Assinante') ;
  const [sortBy, setSortBy] = useState("agulhada")

  let exchanges = urlLocation?.params?.exchange?.toUpperCase().split(",") || ["BMFBOVESPA"];
  if(urlLocation?.params?.exchange?.toUpperCase() === "FAVORITOS") {
    exchanges = undefined
  }
  const { listData, isLoading, mutateAssets, isValidating, isError } = useAllAssets(exchanges, props.userAssets)

  let page_title = "Lista de ativos";
  if(urlList?.params?.list === "lista-de-agulhadas-do-didi") {
    page_title = "Todas as agulhadas";
  }
  if(props.userAssets) {
    page_title = "Meus ativos";
  }



  // Filter data when filter changes
  useEffect(() => {
    // console.log(urlLocation?.params?.exchange?.toUpperCase())
    // console.log(user.remoteFavorites, user.favoriteList)
    if(!isLoading && listData && !isError) {
      if(urlLocation?.params?.exchange?.toUpperCase() === "FAVORITOS") {
        filterByFavorites();
      } else {
        filterbyExchange(urlLocation?.params?.exchange?.toUpperCase().split(",") || ["BMFBOVESPA"])
      }

      if(urlType?.params?.assetType) {
        filterbyAssetType(urlType?.params?.assetType.toLowerCase() || false)
      }

      if (user.clientPrincipal?.userRoles?.includes('VIP') || user.clientPrincipal?.userRoles?.includes('Assinante') ) {
        setIsSubscriber(true)
      }

      window.gtag('event', 'page_view', {
        page_title: `List ${urlLocation?.params?.exchange || "B3"}`,
        page_path: window.location.pathname + window.location.search,
        page_location: window.location.href
      })
      // ReactGA.pageview(window.location.pathname + window.location.search, [], "Lista");
    }

    if(isError) {
      setIsRedirect(true)
    }
    // eslint-disable-next-line
  }, [listData, location, isLoading, isError, user.remoteFavorites, user.favoriteList, currentFavoritesList])


  useEffect(()=>{
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [location])

  useEffect(() => {
    if(!isLoading && listData) {
      const fData = listData.filter((d) => {
        if (query.length <= 2 ) {
          return urlLocation?.params?.exchange?.toUpperCase().split(",").indexOf(d.asset.substring(0, d.asset.indexOf(":"))) > -1;
        }
        return d.asset.toLowerCase().includes(query.toLowerCase()) || d.nome.toLowerCase().includes(query.toLowerCase())
      })
      setFilteredData(fData);
    }
    // eslint-disable-next-line
  }, [query])

  const filterbyExchange = (fArray) => {
    if(!isLoading && listData) {
      let fData = listData.filter((d) => {
        return fArray.indexOf(d.asset.substring(0, d.asset.indexOf(":"))) > -1;
      })
      if(fArray.includes('FUTUROS')){
        fData = fData.filter((d)=>{
          return d.asset.includes("!")
        })
      }
      setFilteredData(fData);
    }
  }

  const filterbyAssetType = (assetType) => {
    if(!isLoading && listData) {
      let fData = listData.filter((d) => {
        const atArray = assetType.split(",");
        return atArray.includes(d.assetType) // === assetType;
      })
      setFilteredData(fData);
    }
  }

  const countClick = (ev) => {
    window.gtag('event', ev, {
      'event_category': 'ui_interection',
   });
  }

  const filterByName = (e) => {
    setQuery(e.target.value);
  }

  const toggleBusca = () => {
    if(showBusca) {
      inputQuery.current.blur();
    } else {
      inputQuery.current.focus();
    }
    setShowBusca(!showBusca);
    setQuery("");
  }

  const toggleFavorite = (asset, listId) => {
    if(user.favoriteList.length === 0) {
      alert(`A lista de favoritos não é compartilhada entre os navegadores dos seus dispositivos.\nAo abrir o agulhadas.com em outro navegador, você verá outra lista de favoritos.`)
    }

    let list = user.favoriteList.find(l=>l.id===listId).assets
    if(!list.includes(asset)) {
      list.push(asset)
    } else {
      list = list.filter(item=>item!==asset)
    }

    const lFavorites = updateFavoriteArray(user.favoriteList, listId, currentListName, list);
    user.setFavoriteList(lFavorites)
  }

  const filterByFavorites = () => {
    const fData = listData.filter((d) => {
      const list = user.favoriteList.find(l=>l.id===currentFavoritesList)
      setCurrentListName(list?.name || "Favoritos")
      const assetList = list?.assets || []
      return assetList.indexOf(d.asset) > -1;
    })
    setFilteredData(fData);
    window.gtag('event', 'favorites', {
      'event_category': 'ui_interection',
    });
  }

  function updateFavoriteArray(arrOfList, listId, listName, assetList) {
    const listsArr = [...arrOfList];
      let exists = false;
      for (let favoriteList of listsArr) {
        if(favoriteList.id === listId) {
          favoriteList.assets = assetList
          exists = true;
        }
      }
      if(exists === false) {
        listsArr.push({
          id: listId,
          name: listName, 
          assets: assetList
        })
      } 
    return listsArr
  }

  function updateLocalFavorites(p) {
    const lFavorites = updateFavoriteArray(user.favoriteList, currentFavoritesList, currentListName, p);
    user.setFavoriteList(lFavorites)
  }

  async function updateRemoteFavorites(p) {
    const response = await fetch("/api/syncFavorites", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "listId": currentFavoritesList,
        "listName": currentListName,
        "favorites": p
      })
    })
    const r = await response.json();
    if(r.matchedCount === 1) {
      const rFavorites = updateFavoriteArray(user.remoteFavorites, currentFavoritesList, currentListName, p);
      user.setRemoteFavorites(rFavorites)
    }
  }

  async function renameList(id, newName) {
    // const newName = newNameInput.current.value
    const listsArr = [...user.favoriteList];
    for (let favoriteList of listsArr) {
      if(favoriteList.id === id) {
        favoriteList.name = newName;
        user.setFavoriteList(listsArr);

        // await updateRemoteFavorites(listsArr)
        // const remoteFavorites = await (
        //   await fetch("/api/syncFavorites")
        // ).json()
        user.setRemoteFavorites()
      }
    }
  }

  async function addComment(asset, comment) {
    user.setComments([...user.comments, {id:"dirty", date:new Date(), comment, asset}])
    const response = await fetch("/api/getComments", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify( { asset, comment })
    })
    const r = await response.json();
    user.setComments(r.comments)
    return r.comments
  }

  async function deleteComment(id) {
    const response = await fetch("/api/getComments", {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify( { id })
    })
    const r = await response.json();
    user.setComments(r.comments)
  }

  // async function saveAlert(asset, timeframe, alertConfig ) {
  //   const method = alertConfig.id ? "POST" : "PUT"
  //   const response = await fetch("/api/userAlerts", {
  //     method: method,
  //     headers: {
  //         "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify( { asset, timeframe, alertConfig  })
  //   })
  //   const r = await response.json();
  //   return r;
  // }

  // async function deleteAlert(asset, timeframe, id) {
  //   const response = await fetch("/api/userAlerts", {
  //     method: "DELETE",
  //     headers: {
  //         "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify( { asset, timeframe, id })
  //   })
  //   const r = await response.json();
  //   return r
  // }

  return (
    <>
      <Seo
        title={`Lista com oportunidades de trade - ${urlLocation?.params?.exchange || "B3"}`}
        description={`'Ache as melhores oportunidades de trade e as agulhadas do Didi - ${urlLocation?.params?.exchange}'`}
        name="Agulhada.com"
        type="website" />

      <header className={`header`}>
        <h1 className={`page-name`}>
          <span>{page_title}</span>
          {/* {(urlList?.params?.list === "lista-de-agulhadas-do-didi" && <span>Todas as agulhadas </span>) || (<span>Lista de ativos </span>)} */}
          {/* &ndash; {urlLocation?.params?.exchange?.toUpperCase() || "Brasil"} */}
        </h1>
      </header>

      
      {!isRedirected && 
      <div className={styles.list}>
        <div className={`${showHideClass}  ${styles["control_bar"]}`}>
          <div className={`${styles["busca"]} ${showBusca?styles["show"]:""}`}>
              <input id="filter"
                name="filter"
                type="search"
                placeholder="Digite o ticker ou nome to ativo"
                value={query}
                onChange={filterByName}
                ref={inputQuery}
                />
          </div>

          {/* ------- Busca ---------- */}
          <button type="button" className={`${styles["reload__button"]}`} onClick={toggleBusca}>
            {showBusca?<IconCross className='mx-auto' />:<IconSearch className='mx-auto' />}
          </button>

          {/* ------- Painel de controle ---------- */}          
          { user.clientPrincipal?.userRoles?.includes('Registered') &&
            <PopupDialog icon={<IconConfig />}>
              <div>
                <ControlPanel
                  userOptions={props.userOptions}
                  timeframesHandler={props.timeframesHandler}
                  tvLayoutHandler={props.tvLayoutHandler}
                />
              </div>
            </PopupDialog>
          }

          {/* ------- Sort ---------- */}          
          <PopupDialog icon={<IconSort />} hidePopUpOnClick={true}>
            {isSubscriber ? 
            <>
              <h4 className={styles["pop-options-list-header"]}>Ordernar por</h4>
              <ul className={styles["pop-options-list"]}>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "agulhada" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("agulhada");countClick('sort_agulhada');}}>Agulhadas</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "nome" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("nome");countClick('sort_nome');}}>Nome</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "tendencia" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("tendencia");countClick('sort_tendencia');}}>Tendência entrante</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "bollinger" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("bollinger");countClick('sort_bollinger');}}>Bollinger abriu</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "alertac" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("alertac");countClick('sort_alerta_compra');}}>Alerta de compra</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "alertav" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("alertav");countClick('sort_alerta_venda');}}>Alerta de venda</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "confirmac" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("confirmac");countClick('sort_confirmacao_compra');}}>Confirmação de compra</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "confirmav" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("confirmav");countClick('sort_confirmacao_venda');}}>Confirmação de venda</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "pfc" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("pfc");countClick('sort_ponto_falso_compra');}}>Compra falsa</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "pfv" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("pfv");countClick('sort_ponto_falso_venda');}}>Venda falsa</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "barato" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("barato");countClick('sort_baratos');}}>Preço - mais baratos</li>
                <li className={`${styles["pop-options-list__item"]} ${sortBy === "caro" ? styles["pop-options-list__item--selected"] : ""}`} onClick={()=>{setSortBy("caro");countClick('sort_caros');}}>Preço - mais caros</li>
              </ul>
            </>
            : 
            <>
              <h4 className={styles["pop-options-list-header"]}>Ordernar por</h4>
              <div className={styles["pop-options-list"]}>
              <p>A ordenação de ativos está disponível apenas para assinantes e convidados.</p>
              <NavLink className={`${styles["asset-alerts__link-button"]}`} to="/assinatura">Saiba mais</NavLink>
              </div>
            </>
            }
          </PopupDialog>

          {/* -------- Favoritos config */}
          { urlLocation?.params?.exchange?.toUpperCase() === "FAVORITOS" && allowFavorites &&
          <button type="button" className={styles["reload__button"]} onClick={() => { setShowSyncFavoritos(!showSyncFavoritos); }}>
            {showSyncFavoritos?<IconCross />:<IconCloud />}
          </button>
          }

          {/* ------- Refresh ---------- */}          
          <button type="button" className={styles["reload__button"]} onClick={() => { mutateAssets(); countClick('reload'); }}>
            <div className={`mx-auto ${styles["reload-icon"]} ${(isLoading||isValidating) ? styles["loading"] : ""}`}></div>
          </button>
        </div>

        {/* ------- Favoritos ---------- */}
        { urlLocation?.params?.exchange?.toUpperCase() === "FAVORITOS" && allowFavorites && showSyncFavoritos &&
          <>
            
            <SyncFavorites
              favoriteList={user.favoriteList}
              remoteFavoritesList={user.remoteFavorites}
              currentFavoritesList={currentFavoritesList}
              currentListName={currentListName}
              updateLocalFavorites={updateLocalFavorites}
              updateRemoteFavorites={updateRemoteFavorites}
            />
          </>
        }

        {!props.soAgulhadas &&
           <AssetList
              list={filteredData}
              localFavorites={user.favoriteList.find(l=>l.id===currentFavoritesList)?.assets || []}
              toggleFavorite={toggleFavorite}
              favoriteLists={user.favoriteList}
              allowFavorites={allowFavorites}
              loadingState={isLoading}
              showAds={!isSubscriber}
              sortBy={sortBy}
              addComment={addComment}
              deleteComment={deleteComment}
              saveAlert={saveAlert}
              deleteAlert={deleteAlert}
            />
          }

          { props.userAssets &&
            <div className='mx-4'>
              <p>Copie e cole o webhook no indicador "Agulhada VIP" do TradingView:</p>
              <p className='border border-orange-600 p-2'>https://agulhada.azurewebsites.net/api/useralert?u={user.clientPrincipal.userId}&code=URmeUJiyDEC83Yxgb9GxayIPf6BfKhmVCAkA_01DoSssAzFurqfmaQ==</p>
              <p>Solicite acesso enviando email para contato@agulhada.com</p>
            </div>

          }


        { props.soAgulhadas &&
          <AgulhadaList list={filteredData} showAds={!isSubscriber} />
        }

        <ControlBar
          selectedExchanges={urlLocation?.params?.exchange?.toUpperCase().split(",") || ["BMFBOVESPA"]}
          currentListName={currentListName}
          currentFavoritesList={currentFavoritesList}
          setCurrentFavoritesList={setCurrentFavoritesList}
          favoriteLists={user.favoriteList}
          allowFavorites={allowFavorites}
          renameList={renameList}
          showBusca={showBusca}
        />
      </div>
      } 

      {isRedirected && 
      <div className={pageStyles.container}>
        <RequestLogin
          header="Ocorreu um erro"
          p1="Atualize a pagina ou reinicie o aplicativo."
          p2="Verifique se você está cadastrado e logado."
          cta={true}
        />
        <div>Ou tente <a href="#top" onClick={()=>{window.location.reload();}}>recarregar</a> a pagina.</div>
      </div>
      }
    </>);
}



export default List;
