import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Pages.module.scss';
import { CartContext } from '../CartContext';


const Cancel = () => {
    const cart = useContext(CartContext)
	const produtsCount = cart.items.reduce((sum, product) => sum+product.quantity, 0)

    return (
        <>
        <header className={`header`}>
            <h1 className="page-name">Pagamento cancelado!</h1>
            <NavLink to="/lojinha/carrinho"
				className={styles["button__cart"]}>
				{produtsCount === 0 && <>Carrinho vazio</>}
				{produtsCount === 1 && <>1 item no carrinho</>}
				{produtsCount > 1 && <>{produtsCount} itens no carrinho</>}
			</NavLink>
        </header>
        <div className='container max-w-5xl mx-auto p-4 py-20'>
            <h2 className='text-2xl font-medium my-4'>Um pena que você cancelou o seu pagamento...</h2>
            <p className='mb-4'>Seus itens continuam no <NavLink to="/lojinha/carrinho">carrinho</NavLink>, caso você mude de idéia.</p>
        </div>
    </>
    )
}

export default Cancel