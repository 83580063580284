import styles from './AssetAlert.module.scss'
import { NavLink } from 'react-router-dom';


const AssetAlert = (props) => {
    return (
        <div className={`${styles["asset-alerts"]}`}>
            <h3>Alertas</h3>
            <p>Os alertas estão disponíveis apenas para assinantes e convidados.</p>
            <NavLink className={`${styles["asset-alerts__link-button"]}`} to="/assinatura">Saiba mais</NavLink>
        </div>
    )
}

export default AssetAlert