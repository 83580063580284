import useSWR from "swr"

const fetcher = (...args) => fetch(...args).then(res => res.json())

export function useAllAssets (exchange, userAssets) {
    let excahngeQuery = "";
    if(exchange) {
      excahngeQuery = "exchange="+exchange
    }

    let userQuery = "";
    if(userAssets === true) {
      userQuery = "&userassets=1"
    }

    const { data, error, isLoading, mutate, isValidating } = useSWR(`/api/getAllAssets/?${excahngeQuery}${userQuery}`, fetcher)
   
    return {
      listData: data,
      isLoading,
      isError: error,
      mutateAssets: mutate,
      isValidating
    }
}