


const RequestLogin = (props) => {  
    const {header, p1, p2, cta, ctaLabel} = props
return (<>
<div className='card'>
    { header && <h2 className='my-4'>{header}</h2> }
    { p1 && <p>{p1}</p> }
    { p2 && <p>{p2}</p> }
    { cta && <p><a className='calltoaction' href="/.auth/login/aadb2c" rel="noreferrer">{ctaLabel || "Entrar"}</a></p> }    
</div>
</>)
}

export default RequestLogin 


// {/* <h2>Agora o Agulhada.com é só para usuários cadastrados</h2>
// <h2>Agora o Agulhada.com é só para usuários cadastrados</h2>
// <p>O Agulhada.com continua gratuito!</p>
// <p>Para acessar a lista automática com melhores oportunidades de trade e agulhadas do Didi, você precisar se cadastrar e fazer o login.</p>
// <p><a className='calltoaction' href="/.auth/login/aadb2c" rel="noreferrer">Entrar</a></p> */}

