import { useState } from 'react'
import styles from './Favorites.module.scss'
import { ReactComponent as IconCross } from "../../resources/cross.svg";
import { ReactComponent as IconEdit } from "../../resources/icon_pencil.svg";


export default function Favorites(props) {
    const {favoriteLists, currentLists, className,
        setShowFavorites, onChangeFavorites, 
        renameList, showEditButton} = props
    const [newName, setNewName] = useState("");
    const [renamingId, setRenamingId] = useState(null);
    
    function formRename(id, initialName) {
        setRenamingId(id)
        setNewName(initialName)
    }

    function saveNewName() {
        renameList(renamingId, newName)
        setRenamingId(null)
    }
    
    return (<>
    <div className={`${styles["favorites"]} ${className}`}>
        <button className={`${styles["favorites__close-button"]}`} onClick={()=>{setShowFavorites(false)}}>
            <IconCross />
        </button>
        <ul className={styles["pop-options-list"]}>
        {
          favoriteLists.map(function(list, i){
            return <li key={list.id} className={`${styles["pop-options-list__item"]} ${currentLists?.includes(list.id) ? styles["pop-options-list__item--selected"] : ""}`}
                        onClick={()=>{onChangeFavorites(list.id); setShowFavorites(false)}}
                        bp="flex"
                    >
                    { renamingId === list.id ?
                    <>
                        <input value={newName} onClick={(e)=>{e.stopPropagation()}} onChange={(e)=>{setNewName(e.currentTarget.value)}} />
                        <button onClick={(e)=>{e.stopPropagation(); saveNewName()}}>salvar</button>
                    </>
                    :
                    <>
                        <span bp="fill 3">{list.name}</span>
                        {showEditButton &&
                        <button bp="fit" className={`${styles["favorites__edit-button"]}`} onClick={(e)=>{e.stopPropagation(); formRename(list.id, list.name)}}>
                            <IconEdit />
                        </button>}
                    </>
                    }
                    </li>
          })
        }
        </ul>

    </div>
    </>)
}