import styles from './Alerts.module.scss';


export function Permission(props) {

    function testNotify(title, body) {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("Notificações push não funcionam com este navegador");
          } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            props.setAutorizado(true)
            new Notification(title,
            {
                icon: "https://www.agulhada.com/logo512.png",
                body: body,
                vibrate: [200, 100, 200, 100, 200, 100, 200],
            });
            // console.log(notification)
            // …
          } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then(async (permission) => {
              // If the user accepts, let's create a notification
              registerServiceworker()
              if (permission === "granted") {
                props.setAutorizado(true)
                // …
              } else {
                props.setAutorizado(false)
              }
            });
          }
    }

    async function registerServiceworker() {
        if (("Navigator" in window)) {
          if(!navigator.serviceWorker.controller) {
            await navigator.serviceWorker.register(`./alerts-sw.js`);
          }
        }
    }

    if(("Notification" in window)) {
      return (
        <>
            <div style={{padding: "1em", textAlign: "center"}}>
              Para receber alertas neste dispositivo, o seu navegador deve estar aurtorizado para aceitar notificações.
            </div>
            { Notification.permission === "denied" ? 
              <>
                <div style={{padding: "1em", textAlign: "center"}}>
                  Clique no icone da barra de endereço do browser para reconfigurar as permissões e aceitar notificações push.
                </div>
              </>
              : 
              <>
                <button className={styles["alert-button"]} onClick={()=>{testNotify("Se você está lendo isto é porque seu navegador pode receber notificações.")}}>Requisitar autorização.</button>
              </>
            }
        </>
      )
    } else {
      return(<></>)
    }
    
}