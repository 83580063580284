import React, { useContext } from 'react';
import { UserContext } from '../../UserContext';
import styles from './AssetMock.module.scss'

const Asset = () => {
    const user = useContext(UserContext)
    const userOptions = user.options;
    const userTimeFrames = [...userOptions.timeframes]

    return (
        <div className={styles["asset-item"]}>
            <h2 className={styles["asset-header"]}>
                <span className={styles.symbol}>
                    &nbsp;<br />
                    <span className={styles.exchange}>&nbsp;</span>
                </span>
                <span className={styles.name}>&nbsp;</span>
                <div className={`${styles.favorite}`} >
                </div>
            </h2>
            <div className={`${styles["asset-info"]}`}>
                {userTimeFrames.indexOf("W") > -1 && <TempoGraficoRow />}
                {userTimeFrames.indexOf("D") > -1 && <TempoGraficoRow />}
                {userTimeFrames.indexOf("H") > -1 && <TempoGraficoRow />}
                {userTimeFrames.indexOf("15") > -1 && <TempoGraficoRow />}
                <div className={`${styles["asset-date"]}`}>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </div>
            </div>
        </div>
    )
}

const TempoGraficoRow = (props) => {
    return (
        <div className={styles["tempo-grafico"]}>
            <Adx />
            <Didi />
            <Bollinger />
            <Saida />
            <MessageComponent />
            <TempoGraficoLetter />
        </div>
    )
}

const MessageComponent = () => {
    return (
        <div className={`${styles.message}`}>
        </div>
    )
}

const TempoGraficoLetter = () => {
    return (
        <div className={`${styles.tfletter}`}></div>
    )
}

const Adx = () => {
    return (
        <div className={`${styles["trend__square"]}`}>
        </div>
    )
}

const Bollinger = () => {
    return (
        <div className={styles.bollinger}>
            <div className={`${styles.light} ${styles.closed}`}>
            </div>
            <div className={`${styles.light} ${styles.open}`}>
            </div>
        </div>
    )
}

const Didi = () => {
    return (
        <div 
            className={`${styles.didi}`}>
        </div>
    )
}

const Saida = () => {
    return (
        <div className={`${styles.saida}`}>
            <div className={`${styles.trix}`}>STOC</div>
            <div className={`${styles.stoc}`}>TRIX</div>
        </div>
    )
}


export default Asset