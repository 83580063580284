import { createContext, useState } from "react";
import { useLocalStorage } from "./hooks/useLocalStorage";


export const UserContext = createContext({
    id: null,
    displayName: "",
    roles: [],
    dataLoaded: false,
    clientPrincipal: null,
    favoriteList: [],
    localFavorites: [],
    remoteFavorites: [],
    comments: [],
    alerts: [],
    devices: [],
    assinaturas: [],
    telegram: {},
    options: {},
    setDataLoaded: () => {},
    setUserInfo: () => {},
    setClientPrincipal: () => {},
    setRoles: () => {},
    setFavoriteList: () => {},
    setLocalFavorites: ()=> {},
    setRemoteFavorites: ()=> {},
    setComments: () => {},
    setAlerts: () => {},
    setDevices: () => {},
    setAssinaturas: () => {},
    setTelegram: () => {},
    setOptions: () => {},
})

export const UserProvider = ({children}) => {
    const [userInfo, setUserInfo] = useState({userId:null, displayName: ""});
    const [roles, setRoles] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [clientPrincipal, setClientPrincipal] = useState(null);
    const [favoriteList, setFavoriteList] = useLocalStorage("favoriteList", []);
    const [localFavorites, setLocalFavorites] = useLocalStorage("localFavorites", []);
    const [remoteFavorites, setRemoteFavorites] = useState([]);
    const [comments, setComments] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [assinaturas, setAssinaturas] = useState([]);
    const [telegram, setTelegram] = useState({});
    const [options, setOptions] = useLocalStorage("userOptions", { tvlayout: false, timeframes: ["W", "D", "240", "H", "15"], favorites: [] })

    const contextValue = {
        id: userInfo.userId,
        displayName: userInfo.displayName,
        roles: roles,
        clientPrincipal: clientPrincipal,
        dataLoaded: dataLoaded,
        favoriteList,
        localFavorites,
        remoteFavorites,
        comments,
        alerts,
        devices,
        assinaturas,
        telegram,
        options,
        setDataLoaded,
        setUserInfo,
        setClientPrincipal,
        setRoles,
        setFavoriteList,
        setLocalFavorites,
        setRemoteFavorites,
        setComments,
        setAlerts,
        setDevices,
        setAssinaturas,
        setTelegram,
        setOptions,
    }

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;