import React, {useEffect, useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from "../UserContext";
import RequestLogin from '../components/RequestLogin';
import Voucher from '../components/Voucher/Voucher';


const postObjects = {
	assinante_m: {
		price_id: "price_1Oh2PaKSzzRHne9EsWmUKeBz", //price_1OgNcKKSzzRHne9EFxUqp4D4 - price_1Oh2PaKSzzRHne9EsWmUKeBz
		metadata: {
			plan: "Assinante",
			period: "M"
		}
	},
	assinante_a: {
		price_id: "price_1Oh2PaKSzzRHne9EwdHwwmMD",
		metadata: {
			plan: "Assinante",
			period: "A"
		}
	},
	vip_m: {
		price_id: "price_1Oh2PJKSzzRHne9EVHGUROKQ", //price_1OgNeeKSzzRHne9EKfcrLzof - price_1Oh2PJKSzzRHne9EVHGUROKQ
		metadata: {
			plan: "VIP",
			period: "M"
		}
	},
	vip_a: {
		price_id: "price_1Oh2PJKSzzRHne9EEdFeWwNN", //price_1OgNeeKSzzRHne9EcMGUOLxL - price_1Oh2PJKSzzRHne9EEdFeWwNN
		metadata: {
			plan: "VIP",
			period: "A"
		}
	},
}


const Assine = () => {
	const user = useContext(UserContext);
	const showButtons = user.clientPrincipal?.userRoles?.includes("Registered");
	const [hasVoucher, setHasVoucher] = useState();
	const [checkingVoucher, setCheckingVoucher] = useState(true)

	useEffect(() => {
		window.scrollTo(0, 0);
		checkVoucher();
	// eslint-disable-next-line
	}, []);

	async function checkVoucher() {
		const result = await (
			await fetch("/api/getCoupon")
		 ).json()
		 setHasVoucher(result.isListed)
		 setCheckingVoucher(false)
	}

	const checkout = async (plano) => {

		const post_obj = postObjects[plano]
		post_obj.hasVoucher = hasVoucher

        await fetch(`/api/getStripeSubscriptionUrl`, {
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify(post_obj)
        })
        .then((response)=>{
            return response.json()
        })
        .then((response)=>{
            if(response.url) {
                window.location.assign(response.url)
            }
        })
    }

	return (
		<>
		<div className='container max-w-5xl mx-auto p-4 py-20' >
			<div>
			{!checkingVoucher && user.clientPrincipal &&
				<Voucher hasVoucher={hasVoucher} email={user.clientPrincipal.userDetails} onSubmit={checkVoucher} />
			}
			</div>

			<div className="title">
				<h2 className='text-3xl font-medium my-4 text-center'>Além do acesso gratuito, o Agulhada.com pode fazer mais para você.</h2>
				<p className='mb-4 text-center'>Veja abaixo os preços para algumas funcionalidades que estão diponíveis apenas para assinantes e usuários convidados.</p>					
				{/* {showButtons &&
				<h3 className='text-orange-500 text-sm mb-4' >
					ATENÇÃO: As novas asssinaturas ainda estão em fase de teste.
					Qualquer problema favor comunicar e resolveremos assim que possível:
					contato@agulhada.com</h3>
				} */}
			</div>
			<div className='grid sm:grid-cols-2 gap-4 grid-cols-1'>
				<div className='border border-orange-500 bg-slate-950 rounded-2xl p-4'>
					<h2 className='text-3xl font-medium mb-4 text-center text-orange-600'>Plano Assinante</h2>
					<p className='mb-4'>Para uma experiência melhor do que você já tem:</p>
					<ul className='list-disc ml-4'>
						<li>Configuração de alertas "push" em até 10 ativos de sua escolha</li>
						<li>Sincronização de lista de favoritos</li>
						<li>Ordenação de ativos</li>
						<li>Sem "data delay" nos ativos</li>
						<li>Sem os anúncios no meio da tela</li>
					</ul>
					{!showButtons &&
					<div className='my-4 text-center' >
					<p  className='text-2xl text-orange-600'>R$ 150,00 / Mês</p>
					<p>ou R$ 1.500,00 / Ano</p>
					</div>
					}
					<p className='m-4 font-bold text-center'>7 dias grátis para você testar</p>
					{ showButtons &&
					<div  className='grid md:grid-cols-7 grid-cols-1 gap-4 text-center p-4'>
						<p className='mb-4 text-sm md:col-span-7 grid-cols-1'>Ao clicar nos botões abaixo, você será encaminhado para o Stripe – portal de pagamentos. Seu cartão só será cobrado após o período de teste.</p>
						<button className="calltoaction md:col-span-3 " style={{fontSize: "1em", border: 0}} onClick={()=>{checkout('assinante_m')}}>R$ 150,00 / Mês</button>
						<span> ou </span>
						<button className="calltoaction md:col-span-3 " style={{fontSize: "1em", border: 0}} onClick={()=>{checkout('assinante_a')}}>R$ 1.500,00 / Ano</button>
					</div>
					}
				</div>
				<div className='border border-orange-500 bg-slate-950 rounded-2xl p-4'>
					<h2 className='text-3xl font-medium mb-4 text-center text-orange-600'>Plano VIP</h2>
					<p className='mb-4'>Todos os benefícios do <strong>Plano Assinante</strong> e mais:</p>
					<ul className='list-disc ml-4'>
						<li>Configuração de alertas "push" ilimitatos</li>
						<li>Configuração de alertas Telegram ilimitados</li>
						<li>Criação de até 5 listas de favoritos - sincronizadas entre dispositivos</li>
						<li>Comentários pessoais em ativos</li>
						<li>Solicitação de inclusão de ativos monitorados</li>
						<li>Atualização automática</li>
						<li>Acesso prévio a novas funcionalidades</li>
						<li>Ganhe um produto de sua escolha da { }
							<NavLink to="/lojinha">nossa lojinha</NavLink> 
						{ } ao fazer a assinatura anual</li>
					</ul>
					{!showButtons &&
					<div className='my-4 text-center ' >
					<p  className='text-2xl text-orange-600'>R$ 200,00 / Mês</p>
					<p>ou R$ 2.200,00 / Ano</p>
					</div>
					}
					<p className='m-4 font-bold text-center'>7 dias grátis para você testar</p>
					{showButtons && 
					<div  className='grid md:grid-cols-7 grid-cols-1 gap-4 text-center p-4'>
						<p className='mb-4 text-sm md:col-span-7 grid-cols-1'>Ao clicar nos botões abaixo, você será encaminhado para o Stripe – portal de pagamentos. Seu cartão só será cobrado após o período de teste.</p>
						<button  className="calltoaction md:col-span-3 " style={{fontSize: "1em", border: 0}} onClick={()=>{checkout('vip_m')}}>R$ 200,00 / Mês</button>
						<span> ou </span>
						<button className="calltoaction md:col-span-3 " style={{fontSize: "1em", border: 0}} onClick={()=>{checkout('vip_a')}}>R$ 2.000,00 / Ano</button>
					</div>
					}					
				</div>
				{!showButtons && 
				<div className='mb-4 font-bold text-center p-4 sm:col-span-2 col-span-1'>
					<RequestLogin
						header="Para assinar você precisa estar cadastrado e logado."
						cta={true}
					/>
				</div>
				}
			</div>
		</div>
		</>
	);
};

export default Assine;