import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import ReactGA from "react-ga";
import Seo from '../components/Seo/Seo';
import styles from './Pages.module.scss';

import { CartContext } from '../CartContext';

import ProductCard from '../components/Product/ProductCard';


const Shop = () => {
	const cart = useContext(CartContext)
	const produtsCount = cart.items.reduce((sum, product) => sum+product.quantity, 0)

	const getProducts = async () => {
        const stripeProducts = await (await fetch(`/api/getStripeProducts`)).json();
		console.log(stripeProducts)
		cart.setAllProducts(stripeProducts.products);
		cart.setAllPrices(stripeProducts.prices.data);
    }

	useEffect(()=>{
		(async function () {
			await getProducts();
		  })();
	// eslint-disable-next-line
	},[])

	return (
		<>
		<Seo
			title="Lojinha agulhada.com"
			description="Camisetas, canecas e outras coisinhas mais."
			name="Agulhada.com"
			type="website" />
		<header className={`header`}>
			<h1 className="page-name">Lojinha</h1>
		    <NavLink to="/lojinha/carrinho"
				className={styles["button__cart"]}>
				{produtsCount === 0 && <>Carrinho vazio</>}
				{produtsCount === 1 && <>1 item no carrinho</>}
				{produtsCount > 1 && <>{produtsCount} itens no carrinho</>}
			</NavLink>
        </header>
		<div className={`${styles.container} ${styles.lojinha}`}>
			<div bp="grid 12@sm 6@md 4@lg">
				{ cart.products.map((product, i) => <ProductCard {...product} key={`${product.id}_${i}`} />) }
			</div>
		</div>
		</>
	);
};

export default Shop;