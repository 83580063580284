import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Pages.module.scss';

import { CartContext } from '../CartContext';

import ProductCart from '../components/Product/ProductCart';

const Cart = () => {
	const cart = useContext(CartContext)
	const produtsCount = cart.items.reduce((sum, product) => sum+product.quantity, 0)

    const checkout = async () => {
        await fetch(`/api/getStripeCheckoutUrl`, {
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({items: cart.items})
        })
        .then((response)=>{
            return response.json()
        })
        .then((response)=>{
            if(response.url) {
                window.location.assign(response.url)
            }
        })
    }

    return (
        <>
            <header className={`header`}>
                <h1 className="page-name">Carrinho de compras</h1>
            </header>
            <div className={`${styles.container} ${styles.lojinha}`}>
                { produtsCount > 0 ?
                <>
                    <h2 className='text-2xl font-medium my-4'>Total: {cart.getTotalCost().toFixed(2)}</h2>
                    <button className={`${styles['button']} ${styles['button--add']}`} onClick={checkout}>Fechar pedido</button>
                    <div>Voce será direcionado para o gateway de pagamento Stripe.</div>
                    <hr></hr>
                    { cart.items.map((item, i)=>{
                        return <ProductCart id={item.id} quantity={item.quantity} product={item.data} key={item.id}></ProductCart> 
                    })}
                    <h2 className='text-2xl font-medium my-4'>Total: {cart.getTotalCost().toFixed(2)}</h2>
                    <button className={`${styles['button']} ${styles['button--add']}`} onClick={checkout}>Fechar pedido</button>
                    <div>Voce será direcionado para o gateway de pagamento Stripe.</div>
                    <p className='mb-4'><NavLink to="/lojinha">Ou continue comprando </NavLink> no agulhada.com</p>

                </>    
                :
                <div>
                <h2>Nenhum item no carrinho</h2>
                <p>Visite o <NavLink to="/lojinha">catálogo de produtos</NavLink> disponíveis no agulhada.com</p>
                </div>
                }
            </div>
        </>
    )
}

export default Cart