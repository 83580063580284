import { useContext, useEffect, useState } from "react";
import Assine from "../../pages/Assine";
import ButtonLoad from '../Ui/ButtonLoad';
import { UserContext } from "../../UserContext";
import Seo from "../Seo/Seo";
import styles from '../../pages/Pages.module.scss'



export default function Assinatura() {
    const user = useContext(UserContext)
    const userRoles = user.clientPrincipal?.userRoles || []
    const [assinaturas, setAssinaturas] = useState(user.assinaturas || [])

    useEffect(()=>{
        setAssinaturas(user.assinaturas || [])
    },[user, setAssinaturas])

    async function getStripePortalUrl() {
        await fetch(`/api/getStripeCustomerPortalUrl`, {
            method: "POST",
        })
        .then((response)=>{
            return response.json()
        })
        .then((response)=>{
            if(response.url) {
                window.location.assign(response.url)
            }
        })
    }

    const isSubscriber = (userRoles.includes('VIP') || userRoles.includes('Assinante'));

    return (<>
        <Seo
            title="Assine o agulhada.com"
            description="Assine a melhor ferramenta para achar as agulhadas do Didi."
            name="Agulhada.com"
            type="website" />
        <header className={`header`}>
            <h1 className="page-name">Assinatura</h1>
        </header>
        { assinaturas && <>
            { isSubscriber && assinaturas.length > 0 ?
                <>
                    <div className={styles.container}>
                        { assinaturas.map((assinatura)=>{
                            return (
                            <div key={assinatura.subId}>
                                <div  className="card border-b-2 pb-4 border-gray-500">
                                <h2 className="text-3xl my-2">
                                    Assinatura {translateStatus(assinatura.status)}
                                </h2>
                                <p> Plano { }
                                    {assinatura.level}
                                </p>
                                {/* <p> Assinante desde {convertDate(assinatura.created)} </p>
                                <p> Período pago: {convertDate(assinatura.current_period_start)} até {convertDate(assinatura.current_period_end)} </p> */}
                                { (assinatura.status === "trialing" || assinatura.status === "active") &&
                                <>
                                    <p> Valida até {convertDate(assinatura.expiry)} </p>
                                </>
                                }
                                <p>
                                    Gerencie sua assinatura no { }
                                    <ButtonLoad onClick={getStripePortalUrl}  className="calltoaction" keeploading={true}
                                        svg={{style: {margin: "0 0 -12px 10px", padding: "2px"}}} >
                                            Portal Stripe
                                    </ButtonLoad>
                                </p>
                                
                                </div>
                                {
                                // (assinatura.status === "trialing" || assinatura.status === "active") ?  
                                //     <p>
                                //         Entre em contato conosco para renovar a sua assinatura: contato@agulhada.com
                                //         {/* <Assine /> */}
                                //         {/* <button  className={"calltoaction"} onClick={()=>{cancelarAssinatura(assinatura.subId)}}>Cancelar assinatura</button> */}
                                //     </p>
                                //     : 
                                //     <p style={{fontWeight: "bold"}}>
                                //         Entre em contato conosco para renovar a sua assinatura: contato@agulhada.com
                                //     </p>
                                } 
                            </div>
                        )
                        })}
                    </div>
                    <div>
                        <h2 className="text-3xl my-2 mt-8">Grupo de usu&aacute;rios do agulhada.com</h2>
                        <p>
                            <a href="https://t.me/agulheiros" target="_blank" rel="noreferrer" >https://t.me/agulheiros</a>
                        </p>
                    </div>
                </>
                :
                <>
                    <Assine />
                </>
                }
        </> }
    </>)
}

export async function getStripeSubscriptionDetails() {
    const response = await fetch("/api/getStripeSubscriptionDetails", {
        method: "GET",
    })
    const r = await response.json();
    return r
}

function convertDate(d) {
    return new Date((d * 1000)).toLocaleDateString()
}

function translateStatus(s) {
    let r = ""
    //incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
    switch(s) {
        case "incomplete" :
            r = "incompleta"
        break;
        case "incomplete_expired" :
            r = "incompleta e vencida"
        break;
        case "trialing" :
            r = "em fase de teste"
        break;
        case "active" :
            r = "ativa"
        break;
        case "past_due" :
            r = "vencida"
        break;
        case "cancelled" :
            r = "cancelada"
        break;
        case "unpaid" :
            r = "inadimplente"
        break;

        default:
            r = ""
        
    }
    return r
}