export async function saveAlert(asset, timeframe, alertConfig) {
    const method = alertConfig.id ?  "PUT" : "POST"
    const response = await fetch("/api/userAlerts", {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ asset, timeframe, alertConfig })
    })
    const r = await response.json();
    return r;
}

export async function saveMultipleAlerts(alertUpdates) {
    const response = await fetch("/api/multipleAlerts", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({alertUpdates})
    })
    const r = await response.json();
    return r;
}


export async function deleteMultipleAlerts(alerts) {
    const response = await fetch("/api/multipleAlerts", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({alerts})
    })
    const r = await response.json();
    return r;
}

export async function deleteAlert(asset, timeframe, id) {
    const response = await fetch("/api/userAlerts", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ asset, timeframe, id })
    })
    const r = await response.json();
    return r
}

export async function getAllNotifications(asset, timeframe, id) {
    const response = await fetch("/api/userNotifications", {
        method: "GET",
    })
    const r = await response.json();
    return r
}

export function timeframeLabel(tf) {
    let timeFrameLabel = ""
    switch(tf) {
        case '15':
            timeFrameLabel = "nos 15 minutos"
            break;
        case 'H':
            timeFrameLabel = "nos 60 minutos (H)"
            break;
        case '240':
            timeFrameLabel = "nas 4H"
            break;
        case 'D':
            timeFrameLabel = "no diário"
            break;
        case 'W':
            timeFrameLabel = "no semanal"
            break;
        default:
            timeFrameLabel = ""
    }

    return timeFrameLabel;
}