import React, {useState} from 'react';
import ButtonLoad from '../Ui/ButtonLoad';

const Voucher = (props) => {
	const {hasVoucher, onSubmit, email} = props;
	const [codigo, setCodigo] = useState("");
	const [messageResult, setMessageResult] = useState("")

    async function getPromotionCode() {
		setMessageResult("");
        const response = await fetch("/api/getCoupon", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				codigo
			})
		})
		const r = await response.json();
		
		if(r.isRegistered) {
			await onSubmit();
		} else {
			setMessageResult(`O código secreto "${codigo}" não é válido`)
		}
    }

	return (
		<>
            {!hasVoucher &&
                <div>
					<div className='border border-orange-400 rounded-2xl p-4 text-center'>
						<label htmlFor="csecreto" className=''>Digite o código secreto para ganhar um voucher de $200,00: </label>
						<input value={codigo} onChange={(e)=>{setMessageResult(""); setCodigo(e.target.value)}} id="csecreto" className='text-black p-1 px-2' type='text' />
						<ButtonLoad className='calltoaction mx-4' onClick={getPromotionCode}>Quero meu voucher</ButtonLoad>
						{messageResult.length > 0 &&
							<div className='bg-orange-700 p-2 mt-4 mx-auto'> {messageResult} </div>
						}
					</div>
                </div>
            }
			{props.hasVoucher &&
				<h3 className='text-xl text-center m-auto font-bold my-4 bg-orange-400 text-slate-900 max-w-80 p-2'>
					<div>Você ganhou um voucher de</div> <div className='text-3xl'>R$200</div> <div>para usar no primeiro mês da sua assinatura.</div>
				</h3>
			}
		</>
	);
};

export default Voucher;