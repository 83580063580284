// App.js
import React, {useEffect, useContext} from 'react';
import { Routes, Route, redirect } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import CartProvider from './CartContext'
import { UserContext } from './UserContext'
import ReactGA from 'react-ga';
import { useUserDetails } from './hooks/useUserDetails';

// import { useLocalStorage } from "./hooks/useLocalStorage";

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Shop from './pages/Shop';
import Cancel from './pages/Cancel';
import Success from './pages/Success';
import Cart from './pages/Cart';
import PropTrader from './pages/PropTrader';
import Contact from './pages/Contact';
import Privacidade from './pages/Privacidade';
import Contratar from './pages/Contratar';
import FourOFour from './pages/FourOFour';
import Vix from './pages/Vix';
import Telegram from './pages/Telegram';


// Components
import List from './components/List/List';
import NavBar from './components/NavBar/NavBar';
import AlertsPage from './components/Alerts/AllAlerts';
import Log from './components/Alerts/Log';
import Devices from './components/Alerts/Devices';
import Templates from './components/Alerts/Templates';
import Assinatura from './components/Assinatura/Assinatura';
import Assinou from './components/Assinou/Assinou';
import Faq from './pages/Faq';

// import CookieConsent, {
//   getCookieConsentValue
// } from "react-cookie-consent";

const TRACKING_ID = "G-J12BZVY018"; // OUR_TRACKING_ID
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.initialize(TRACKING_ID);

const App = () => {
   const user = useContext(UserContext)
   
   const { userDetails, isLoadingUser, isUserError } = useUserDetails()

   function migrateFavorites() {
      const local = [...user.localFavorites];
      const list = [...user.favoriteList];
      if(local.length > 0 && list.length === 0) {
         list.push({
            "id": -1,
            "name": "Favoritos",
            "assets": local
         })
      }
      for(let i=0;i<5;i++) {
         if(!list[i]) {
            list.push({"id": i, name: "Lista " + Number(i+1), assets: []})
         } else {
            list[i].id = i
         }
      }
      user.setFavoriteList(list)
   }

	useEffect(function appFirstUseEffect() {
		// fetch data
      const idFetch = async () => {
         const auth = await (
            await fetch("/.auth/me")
         ).json()

         user.setClientPrincipal(auth.clientPrincipal)
         const name = auth.clientPrincipal?.claims?.find(c=>c.typ === "name")?.val
         const userInfo = {userId:auth.clientPrincipal?.userId, displayName: name}
         user.setUserInfo(userInfo)

         if(auth.clientPrincipal) {
            // migrate local favotitos 
            migrateFavorites();
         }
      };

      idFetch();

		// dataFetch();
   // eslint-disable-next-line
	}, []);

   useEffect(function userDetailsEffect() {
      if(!isLoadingUser && !isUserError) {
         const remoteFavorites = userDetails.favoritos
         // console.log(userDetails)
         user.setRemoteFavorites(Array.isArray(remoteFavorites) ? remoteFavorites : [])
         user.setComments(Array.isArray(userDetails.comments) ? userDetails.comments : [])
         user.setAlerts(Array.isArray(userDetails.alerts) ? userDetails.alerts : [])
         user.setDevices(Array.isArray(userDetails.devices) ? userDetails.devices : [])
         user.setAssinaturas(Array.isArray(userDetails.assinaturas) ? userDetails.assinaturas : [])
         user.setTelegram(userDetails.telegram || {})
      }

   // eslint-disable-next-line
   }, [isLoadingUser, userDetails, isUserError])

   return (
      <CartProvider>
         <HelmetProvider>
            <NavBar />
               <Routes>
                  <Route index element={<Home />} />
                  <Route path="*"  element={<FourOFour />} />
                  <Route path="/404"  element={<FourOFour />} />
                  <Route path="/app/*"  element={<List />} />
                  <Route path="/app/*/*"  element={<List />} />
                  <Route path="/appuser/*"  element={<List userAssets={true} />} />
                  <Route path="/appuser/*/*"  element={<List userAssets={true} />} />
                  <Route path="/lista-de-agulhadas-do-didi/*" element={<List soAgulhadas={true} />}  />
                  <Route path="/lojinha" element={<Shop />} />
                  <Route path="/mesa-proprietaria" element={<PropTrader />} />
                  <Route path="/entre-em-contato" element={<Contact />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/saiba-mais" element={<About />} />
                  <Route path="/assinatura" element={<Assinatura />} />
                  <Route path="/assinou" element={<Assinou />} />
                  <Route path="/assinatura-preview" element={<Assinatura />} />
                  <Route path="/contratar" element={<Contratar />} />
                  <Route path="/politica-de-privacidade" element={<Privacidade />} />
                  <Route path="/lojinha/carrinho" element={<Cart />} />
                  <Route path="/lojinha/success" element={<Success />} />
                  <Route path="/lojinha/cancel" element={<Cancel />} />
                  <Route path="/vix" element={<Vix />} />
                  <Route path="/telegram" element={<Telegram />} />
                  <Route path="/templates" element={<Templates />} />
                  <Route path="/alertas" element={<AlertsPage />} />
                  <Route path="/notificacoes" element={<Log />} />
                  <Route path="/dispositivos" element={<Devices />} />
                  <Route path="/login" render={() => {return redirect("/.auth/login/aadb2c")}} />
                  <Route path="/logout" render={() => {return redirect("/.auth/logout")}} />
               </Routes>
            {/* <CookieConsent
               location="bottom"
               buttonText="ACEITO"
               cookieName="myAwesomeCookieName2"
               style={{ background: "#2B373B" }}
               buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
               expires={150}
               onAccept={handleAcceptCookie}
               >
               O Agulhada.com utiliza cookies e outras tecnologias para melhorar sua experiência.
               Ao continuar a usar o Agulhada.com, você aceita as condições de nossa { }
               <NavLink to="/politica-de-privacidade" style={{ color: "white" }}>Política de Privacidade</NavLink>
            </CookieConsent> */}
         </HelmetProvider>
      </CartProvider>
   );




};

export default App;
