import adimage from './axi_logo.jpg';
const axitrader = `
<div class="ad">
<h2>Quer operar CFDs?</h2>
<p>"Contract for Difference" – CFD é uma maneira de operar com pouco dinheiro e a Axi Trader é uma corretora campeã em CFDs.</p>
<p>Opere Forex, Ações, Índices, Commodities e Crypto.</p>
<a href="https://records.axiaffiliates.com/visit/?bta=36308&brand=axitrader" target="_top">
<img src=${adimage} width="200" alt="AXI Trader" border="0"/></a>
<br />
<a class="calltoaction" target="_blank" href="https://records.axiaffiliates.com/visit/?bta=36308&brand=axitrader">Abra sua conta</a>
</div>
`
export default axitrader;