import React from 'react';
import styles from './AgulhadaList.module.scss'
import AssetSingle from '../Asset/AssetSingle'

// import Asset from '../Asset/Asset'
// import anuncios from '../Ads/Data';

const AgulhadaList = (props) => {
    if(props.list.length === 0) {
        return (
            <div>Nada aqui ainda...</div>
        )
    }
    const list = props.list.filter(a=>a.agulhado !== 0)
    list.sort((a,b)=>{
        return (b.agulhado-a.agulhado)||(new Date(b.lastUpdate)-new Date(a.lastUpdate))
    })

    return (
        <ul className={styles["asset-list"]} bp="grid 12@sm 6@md 4@lg">
        {
            list.map((a)=>{
                return <li key={a._id}><AssetSingle {...a} mode="single" /></li>
            })
        }
        </ul>
    )
}

export default AgulhadaList