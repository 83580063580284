import React, { useState, useContext, useRef, useEffect } from 'react';
import { UserContext } from '../../UserContext';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './Asset.module.scss';
import AssetAlert from './AssetAlert';

import { ReactComponent as IconStar } from "../../resources/star.svg";
import { ReactComponent as IconBell } from "../../resources/bell.svg";
import { ReactComponent as IconCross } from "../../resources/cross.svg";
import { ReactComponent as IconComment } from "../../resources/comment.svg";
import { ReactComponent as IconRolling } from "../../resources/rolling.svg";
import Favorites from '../Favorites/Favorites';
import Alerts from '../Alerts/Alerts';
import { NavLink } from 'react-router-dom';
import { useUserDetails } from '../../hooks/useUserDetails';


const Asset = (props) => {
    const { asset, allowFavorites, favoriteLists, toggleFavorite, addComment, deleteComment, timeFrames, saveAlert, deleteAlert, alerts } = props;
    const user = useContext(UserContext);
    const {mutateUser} = useUserDetails()
    const userOptions = user.options;
    const delay = (user.clientPrincipal?.userRoles?.includes('VIP') || user.clientPrincipal?.userRoles?.includes('Assinante')) ? false : true;
    const userTimeFrames = [...userOptions.timeframes];
    const lastUpdate = new Date(props.lastUpdate);
    const [comments, setComments] = useState(props.comments);
    const [savingComments, setSavingComments] = useState(false);
    // const isFavorite = props.localFavorites?.indexOf(props.asset) > -1 || false
    const [showFavorites, setShowFavorites] = useState(false);


    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0');
    const commentsPannel = useRef();
    const [backCardMinHeight, setBackCardMinHeight] = useState('0');
    // const assetContainer = useRef();
    const inputComment = useRef();
    const backCardContainer = useRef();
    const frontCardContainer = useRef();


    // const isAgulhadoCompra = (props.W.agulhado === 1 || props.D.agulhado === 1 || props.H.agulhado === 1 || props['15'].agulhado === 1) ? styles["agulhado--compra"] : ""
    // const isAgulhadoVenda = (props.W.agulhado === -1 || props.D.agulhado === -1 || props.H.agulhado === -1 || props['15'].agulhado === -1) ? styles["agulhado--venda"] : ""

    const highlightStyle = (new Date().getTime() - new Date(props["15"].lastUpdate).getTime()) > 900000 ? { "color": "white" } : {};

    const [assetFlip, setAssetFlip] = useState("0deg");
    const [backCard, setBackCard] = useState("none");
    const [alertTimeFrame, setAlertTimeFrame] = useState("D")
    const [alertsCount, setAlertCount] = useState({"total":0, "15": 0, "60": 0, "240": 0, "D": 0, "W": 0})

    const inFavoriteLists = favoriteLists.reduce(function collectListIds(ids, list) {
        if (list.assets?.includes(asset)) { ids.push(list.id) }
        return ids;
    }, [])

    function countAlerts() {
        const returnObject = {"total":0, "15": 0, "60": 0, "240": 0, "D": 0, "W": 0}
        let total = 0;
        for (const a of alerts) {
            if (a.alerts) {
                returnObject[a.timeframe] = a.alerts.length
                total += a.alerts.length
            }
        }
        returnObject.total = total
        setAlertCount(returnObject)
    }
    
    function resizePanel() {
        if (commentsPannel.current) {
            const contentHeight = commentsPannel.current.offsetHeight;
            setMaxHeight(contentHeight)
        }
    }

    function resizeContainer() {
        const backCardContent = backCardContainer.current.firstChild
        const frontCardContent = frontCardContainer.current.firstChild
        if (backCardContent) {
            if (backCard === "none") {
                setTimeout(function readjustAlertsContainerHeight() {
                    setBackCardMinHeight(frontCardContent.clientHeight)
                })
            } else {
                setTimeout(function readjustAlertsContainerHeight() {
                    setBackCardMinHeight(backCardContent.clientHeight)
                })
            }
        }
    }

    useEffect(() => {
        if (isOpen) {
            const contentHeight = commentsPannel.current.offsetHeight;
            setMaxHeight(contentHeight)
        } else {
            setMaxHeight('0px')
        }
    }, [commentsPannel, isOpen, comments]);

    useEffect(countAlerts,[alerts])

    const flipCard = (card = "none") => {
        setBackCard(card)
        if (card === "none") {
            setAssetFlip("0deg")
            setBackCardMinHeight("0")
        } else {
            setAssetFlip("180deg")
            resizeContainer()
        }
        setBackCard(card)
        mutateUser()
    }

    async function addNewComment() {
        setSavingComments(true)
        const newComments = await addComment(asset, inputComment.current.value)
        setComments(newComments.filter(c => c.asset === asset))
        inputComment.current.value = ""
        setSavingComments(false)
    }

    async function deleteThisComment(id) {
        if (window.confirm("Excluir comentário?")) {
            const cmts = comments.filter(c => c.id !== id)
            setComments(cmts)
            await deleteComment(id);
        }
    }

    // function countAlerts() {
    //     let count = 0
    //     console.log(alerts)
    //     for (const a of alerts) {
    //         if (a.alerts) {
    //             count += a.alerts.length
    //         }
    //     }
    //     return count
    // }

    function openAlertInTimeframe(tf) {
        setAlertTimeFrame(tf==="H"?"60":tf)
        flipCard("alert");
        setIsOpen(false)
    }

    return (
        <div className={styles["asset-item"]}>
            <h2 className={`${styles["asset-header"]}`}>

                {/* -------- Ticker, Exchande and asset name -------- */}
                <span className={styles.symbol}>
                    {getTicker(asset)}
                    {delay &&
                        <NavLink to={"/assinatura"} className={`${styles["data-delay"]}`} title="Dados com atraso de 15 minutos. Clique para assinar.">D</NavLink>
                    }
                    <br />
                    <span className={styles.exchange}>{getExchange(props.asset)}</span>
                </span>
                <span className={styles.name}>{props.nome}</span>


                {/* -------- Comments button -------- */}
                {allowFavorites &&
                    <button className={`${styles.favorite} ${comments.length > 0 ? styles.active : ""}`} style={{ "fontSize": "0.7em" }}
                        onClick={() => { flipCard("none"); setIsOpen(!isOpen) }}>
                        <span className={`${styles["favorite__number"]} ${styles["favorite__number--comment"]}`} >
                            {comments.length > 0 && comments.length}
                        </span>
                        <IconComment />
                    </button>
                }

                {/* -------- Alert button -------- */}
                {backCard !== 'alert'
                    ? <button className={`${styles.favorite} ${alertsCount.total > 0 ? styles.active : ""}`} onClick={() => { flipCard("alert"); setIsOpen(false); }}>
                        {alertsCount.total > 0 && allowFavorites &&
                            <span className={`${styles.favorite__number} text-slate-950 text-xs`}>
                                {alertsCount.total}
                            </span>
                        }
                        <IconBell />
                    </button>
                    : <button className={`${styles.favorite}`} onClick={() => { flipCard("none"); setIsOpen(false); }}>
                        <IconCross />
                    </button>
                }

                {/* -------- Favoritos button -------- */}
                <button className={`${styles.favorite} ${inFavoriteLists.length > 0 ? styles.active : ""}`}
                    onClick={() => {
                        if (allowFavorites) {
                            setShowFavorites(!showFavorites)
                        } else {
                            toggleFavorite(asset, 0)
                        }
                    }}>
                    {inFavoriteLists.length > 0 && allowFavorites && <span className={`${styles.favorite__number}`} >{inFavoriteLists.length}</span>}
                    <IconStar />
                </button>
                {showFavorites && allowFavorites &&
                    <Favorites
                        favoriteLists={favoriteLists}
                        currentLists={inFavoriteLists}
                        onChangeFavorites={(listId) => {
                            toggleFavorite(asset, listId);
                            setShowFavorites(false);
                        }}
                        setShowFavorites={setShowFavorites}
                        showEditButton={false}
                        className={`${styles["asset-item__favorites"]}`} />
                }
            </h2>

            {/* -------- ASSET BLOCK -------- */}
            <div className={`${styles["asset-info"]} ${styles["flip-card"]}`}>
                <div className={`${styles["flip-card-inner"]}`} style={{ "transform": `rotateY(${assetFlip})`, minHeight: backCardMinHeight + 40 }} >
                    
                    {/* -------- ASSET AND COMMENTS BLOCK -------- */}
                    <div className={`${styles["flip-card-front"]}`} ref={frontCardContainer}>

                        {/* -------- COMMENTS BLOCK -------- */}
                        {allowFavorites &&
                        <div className={`${styles["comments-panel"]} ${isOpen && styles["open"]}`} style={{ maxHeight: maxHeight }}>
                            <div ref={commentsPannel} className={styles["content"]}>
                                <ul className={styles["list"]}>
                                    {comments.map((c, i) => {
                                        return (
                                            <li key={i}>
                                                <span className={styles["date"]}>
                                                    {new Date(c.date).toLocaleString('pt-BR').split(",").map((item, idx) => <React.Fragment key={idx}>{item}<br /></React.Fragment>)}
                                                </span>
                                                <div className={styles["comment"]}>{c.comment}</div>
                                                <button className={styles["delete"]} onClick={() => { deleteThisComment(c.id) }}>&#x2715;</button>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className={styles["comment-form"]}>
                                    <div className={styles["comment-form__input"]} >
                                        <label>
                                            <TextareaAutosize
                                                minRows="1"
                                                maxRows="5"
                                                ref={inputComment}
                                                onHeightChange={resizePanel}
                                                className={styles["textarea-auto-size"]}
                                                placeholder='Novo comentário'
                                            >
                                            </TextareaAutosize>
                                        </label>
                                    </div>
                                    {savingComments ?
                                        <><IconRolling /></>
                                        :
                                        <button className={styles["button-add-comment"]} onClick={addNewComment}>Salvar</button>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {/* -------- ASSET INFO ROWS -------- */}
                        <div>
                        {userTimeFrames.indexOf("W") > -1 && <TempoGraficoRow {...props} tf="W" bellClick={allowFavorites?openAlertInTimeframe:null} nAlerts={alertsCount["W"]>0?alertsCount["W"]:""} />}
                        {userTimeFrames.indexOf("D") > -1 && <TempoGraficoRow {...props} tf="D" bellClick={allowFavorites?openAlertInTimeframe:null} nAlerts={alertsCount["D"]>0?alertsCount["D"]:""} />}
                        {(userTimeFrames.indexOf("240") > -1 && props["240"]) && <TempoGraficoRow {...props} tf="240" bellClick={allowFavorites?openAlertInTimeframe:null} nAlerts={alertsCount["240"]>0?alertsCount["240"]:""} />}
                        {userTimeFrames.indexOf("H") > -1 && <TempoGraficoRow {...props} tf="H" bellClick={allowFavorites?openAlertInTimeframe:null} nAlerts={alertsCount["60"]>0?alertsCount["60"]:""} />}
                        {userTimeFrames.indexOf("15") > -1 && <TempoGraficoRow {...props} tf="15" bellClick={allowFavorites?openAlertInTimeframe:null} nAlerts={alertsCount["15"]>0?alertsCount["15"]:""} />}
                        </div>

                        {/* -------- FOOTER -------- */}
                        <div className={`${styles["asset-date"]}`}>
                            <span>www.agulhada.com</span>
                            <span style={highlightStyle}>&Uacute;ltima captura: {lastUpdate.toLocaleString()} – ${props.preco}</span>
                        </div>
                    </div>


                    {/* -------- ALERTS BLOCK -------- */}
                    <div className={`${styles["flip-card-back"]}`} ref={backCardContainer} >
                        {allowFavorites ?
                            <Alerts
                                asset={asset}
                                resizeContainer={resizeContainer}
                                timeFrames={timeFrames}
                                alertTimeFrame={alertTimeFrame}
                                saveAlert={saveAlert}
                                deleteAlert={deleteAlert}
                                alerts={alerts}
                            />
                            :
                            <AssetAlert />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const TempoGraficoRow = (props) => {
    const {tf, bellClick, nAlerts} = props
    if (!props[tf]) { return <></> }
    const timeDiff = new Date().getTime() - new Date(props[tf]?.lastUpdate).getTime()
    let opacity = 1;
    // console.log(props["240"])
    if (tf === "W" && timeDiff > 604800000 && !props.fullOpacity) { opacity = 0.3 };
    if (tf === "D" && timeDiff > 86400000 && !props.fullOpacity) { opacity = 0.3 };
    if (tf === "240" && timeDiff > 14400000 && !props.fullOpacity) { opacity = 0.3 };
    if (tf === "H" && timeDiff > 3600000 && !props.fullOpacity) { opacity = 0.3 };
    if (tf === "15" && timeDiff > 900000 && !props.fullOpacity) { opacity = 0.3 };

    return (
        <div className={styles["tempo-grafico"]} style={{ "opacity": opacity }}>
            <Adx {...props} tf={tf} />
            <Didi {...props} tf={tf} />
            <Bollinger status={props[tf].bollingerStatus} />
            <Saida {...props} tf={tf} />
            <MessageComponent {...props} tf={tf} />
            {bellClick && 
                <button className={`${styles.favorite}`} style={{marginRight: "15px"}}
                    onClick={() => { props.bellClick(tf) }}
                >
                    <IconBell style={{padding:"2px", display:"inline-block"}} />
                    <span style={{color: "white", float:"right"}}>{nAlerts}</span>
                </button>
            }
            <TempoGraficoLetter {...props} tf={tf} />
        </div>
    )
}

const trendClass = (obj, tf) => {
    if (!obj) { return "" };
    const { tendenciaCompra, tendenciaVenda, tendenciaAcelerante } = obj;

    if (tendenciaCompra && tendenciaAcelerante) {
        return `trend__square-${tf}--compra-acelerante`
    }
    if (tendenciaVenda && tendenciaAcelerante) {
        return `trend__square-${tf}--venda-acelerante`
    }
    if (tendenciaCompra && !tendenciaAcelerante) {
        return `trend__square-${tf}--compra`
    }
    if (tendenciaVenda && !tendenciaAcelerante) {
        return `trend__square-${tf}--venda`
    }
    return '';
}

const MessageComponent = (props) => {
    const tf = props.tf === "S" ? "W" : props.tf
    return (
        <div className={`${styles.message}`}>
            {props[tf]?.message}&nbsp;
        </div>
    )
}

const TempoGraficoLetter = (props) => {
    const user = useContext(UserContext)
    const tf = props.tf
    const isAgulhado = (props[tf].agulhado === 1 || props[tf].agulhado === -1)
    const isAgulhadoCompra = (props[tf].agulhado === 1) ? styles["agulhado--compra"] : ""
    const isAgulhadoVenda = (props[tf].agulhado === -1) ? styles["agulhado--venda"] : ""
    const sharedLayout = user.options.tvlayout ? "" : "/Cot1ISUS"

    const gtagClick = (asset, tf, agulhada) => {
        window.gtag('event', 'click', {
            action: "Open chart",
            asset: asset,
            timeframe: tf,
            agulhada: agulhada
        })
    }

    return (
        <a className={`${styles.tfletter} ${isAgulhadoCompra} ${isAgulhadoVenda}`}
            href={`https://www.tradingview.com/chart${sharedLayout}/?symbol=${props.asset}&interval=${tf}&share_your_love=vitorleo`} rel="noreferrer" target="_blank"
            onClick={() => gtagClick(props.asset, tf, isAgulhado)}>
            {tf === "W" ? "S" : tf === "240" ? "4H" : tf}
        </a>
    )
}

const Adx = (props) => {
    const tf = props.tf
    let tClass = trendClass(props[tf], tf);
    const [adxAngle, setAdxAngle] = useState(Math.atan((props[tf].adxanterior - props[tf].adx) / 2) * 180 / Math.PI)
    const [adxVolume, setAdxVolume] = useState(Math.atan((props[tf].adxanterior - props[tf].adx) / 2) * 180 / Math.PI)
    
    useEffect(()=>{
        setAdxAngle(Math.atan((props[tf].adxanterior - props[tf].adx) / 2) * 180 / Math.PI)
        setAdxVolume(Math.round(props[tf].adx.toFixed(2)*0.28))
    }, [props, tf, setAdxAngle, setAdxVolume])
    
    // const adxAngle = Math.atan((props[tf].adxanterior - props[tf].adx) / 2) * 180 / Math.PI

    return (
        <>
        <div style={{height:"28px", background:'#757559', position:'relative',width: '5px'}} >
            <div style={{transition: 'height 500ms', height:adxVolume, width: '5px', position:'absolute', bottom:0, "alignSelf":"end"}} className={`${styles[tClass]} || ""`}></div>
        </div>
        <div title={`ADX: ${props[tf].adxanterior.toFixed(2)} -> ${props[tf].adx.toFixed(2)}`}
            className={`${styles["trend__square"]} ${styles["trend__square-" + tf]} ${styles[tClass] || ""}`}>
            {/* <span className={styles["trend__square-adx-number"]}>{props.adx}</span> */}
            <div className={styles["trend__square-adx"]} style={{ "rotate": `${adxAngle}deg` }}></div>
        </div>
        </>
    )
}

const Bollinger = (props) => {
    const closedOn = props.status === -1 ? styles.on : ""
    const openOn = props.status === 1 ? styles.on : ""
    const literal = props.status === -1 ? "fechado" : props.status === 1 ? "aberto" : "paralelo"
    return (
        <div title={`Bollinger ${literal}`}
            className={styles.bollinger}>
            <div className={`${styles.light} ${styles.closed} ${closedOn}`}>
            </div>
            <div className={`${styles.light} ${styles.open} ${openOn}`}>
            </div>
        </div>
    )
}

const Didi = (props) => {
    const tf = props.tf
    const confirmacao = props[tf]?.confirmacao !== 0 ? "C" : ""
    const alerta = props[tf]?.alerta !== 0 ? "A" : ""
    const pontofalso = props[tf]?.pontofalso !== 0 ? "F" : ""

    let dirClass = ""
    if (props[tf]?.confirmacao === 1 || props[tf]?.alerta === 1) {
        dirClass = styles["didi--compra"]
    }
    if (props[tf]?.confirmacao === -1 || props[tf]?.alerta === -1) {
        dirClass = styles["didi--venda"]
    }
    if (props[tf]?.pontofalso === -1 && pontofalso === "F") {
        dirClass = styles["didi--compra"]
    }
    if (props[tf]?.pontofalso === 1 && pontofalso === "F") {
        dirClass = styles["didi--venda"]
    }

    return (
        <div
            className={`${styles.didi} ${dirClass}`}>
            {pontofalso || confirmacao || alerta}
        </div>
    )
}

const Saida = (props) => {
    const tf = props.tf
    let dirStocClass = props[tf]?.stockStatus ? styles["stoc--compra"] : styles["stoc--venda"];
    let dirTrixClass = props[tf]?.trixStatus ? styles["trix--compra"] : styles["trix--venda"];
    return (
        <div className={`${styles.saida}`}>
            <div className={`${styles.trix} ${dirStocClass}`}>STOC</div>
            <div className={`${styles.stoc} ${dirTrixClass}`}>TRIX</div>
        </div>
    )
}


const getTicker = (s) => {
    return s.substring(s.indexOf(":") + 1)
}

const getExchange = (s) => {
    return s.substring(0, s.indexOf(":"))
}

// const longAgo = (d) => {
//     const diff = new Date() - new Date(d)
//     const days = Math.floor(diff / (1000*60*60*24))
//     const hours = Math.floor((diff % (1000*60*60*24)) / (1000*60*60))
//     const minutes = Math.floor((diff % (1000*60*60)) / (1000*60))
//     let message = `${days>0?days+"d":""} ${hours>0?hours+"h":"0h"} ${minutes>0?minutes+"m":"0m"}`
//     if(days===0 && hours===0 && minutes===0) message = "Just now"
//     if(!d) message = "no record"
//     return message
// }


export default Asset
