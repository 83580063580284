import React from 'react';
import styles from './Pages.module.scss';



const Vix = () => {

    return (
        <>
        <header className={`header`}>
            <h1 className="page-name">VIX</h1>
        </header>
        <div className={styles.container} style={{"textAlign":"center"}}>
        <p><img src = "/api/getVixChart?chart=futuro" alt="VIX Futuro" width="50%" /></p>
        <p> <img src = "/api/getVixChart?chart=puts" alt="VIX Puts" width="100%"/></p>
        <p><img src = "/api/getVixChart?chart=calls" alt="VIX Calls" width="100%"/></p>

        </div>
    </>
    )
}

export default Vix