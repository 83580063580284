const templates = {
    alertaCompra: {
      "message": "deu alerta de compra",
      "active": true,
      "tendenciaCompra": true,
      "tendenciaVenda": false,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "alerta": 1,
      "bollingerStatus": 1,
      "rules": [
        { "name": "Tendencia", "value": "1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Alerta", "value": "1" },
        { "name": "Bollinger", "value": "1" }
      ],
    },
    alertaVenda: {
      "message": "deu alerta de venda",
      "active": true,
      "tendenciaCompra": false,
      "tendenciaVenda": true,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "alerta": -1,
      "bollingerStatus": 1,
      "rules": [
        { "name": "Tendencia", "value": "-1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Alerta", "value": "-1" },
        { "name": "Bollinger", "value": "1" }
      ],
    },
    agulhadaCompra: {
      "message": "deu agulhada de compra",
      "active": true,
      "tendenciaCompra": true,
      "tendenciaVenda": false,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "agulhado": 1,
      "bollingerStatus": 1,
      "rules": [
        { "name": "Tendencia", "value": "1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Agulhada", "value": "1" },
        { "name": "Bollinger", "value": "1" }
      ]
    },
    agulhadaCompraSanta: {
      "message": "deu agulhada SANTA de compra",
      "active": true,
      "tendenciaCompra": true,
      "tendenciaVenda": false,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "agulhado": 1,
      "bollingerStatus": 1,
      "trixStatus": 1,
      "stockStatus": 1,
      "rules": [
        { "name": "Tendencia", "value": "1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Agulhada", "value": "1" },
        { "name": "Bollinger", "value": "1" },
        { "name": "Stochastic", "value": "1" },
        { "name": "Trix", "value": "1" }
      ]
    },
    agulhadaVenda: {
      "message": "deu agulhada de venda",
      "active": true,
      "tendenciaCompra": false,
      "tendenciaVenda": true,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "agulhado": -1,
      "bollingerStatus": 1,
      "rules": [
        { "name": "Tendencia", "value": "-1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Agulhada", "value": "-1" },
        { "name": "Bollinger", "value": "1" },
      ]
    },
    agulhadaVendaSanta: {
      "message": "deu agulhada SANTA de venda",
      "active": true,
      "tendenciaCompra": false,
      "tendenciaVenda": true,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "agulhado": -1,
      "bollingerStatus": 1,
      "trixStatus": -1,
      "stockStatus": -1,
      "rules": [
        { "name": "Tendencia", "value": "-1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "Agulhada", "value": "-1" },
        { "name": "Bollinger", "value": "1" },
        { "name": "Stochastic", "value": "-1" },
        { "name": "Trix", "value": "-1" }
      ]
    },
    compraEntrante: {
      "message": "entrou em tendência de compra",
      "active": true,
      "tendenciaEntrante": 1,
      "bollingerStatus": 1,
      "tendenciaCompra": true,
      "tendenciaVenda": false,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "rules": [
        { "name": "Tendencia", "value": "1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "TendenciaEntrante", "value": "1" },
        { "name": "Bollinger", "value": "1" }
      ]
    },
    vendaEntrante: {
      "message": "entrou em tendência de venda",
      "active": true,
      "tendenciaEntrante": 1,
      "bollingerStatus": 1,
      "tendenciaCompra": false,
      "tendenciaVenda": true,
      "temTendencia": true,
      "tendenciaAcelerante": true,
      "rules": [
        { "name": "Tendencia", "value": "-1" },
        { "name": "TendenciaAcelerante", "value": "true" },
        { "name": "TendenciaEntrante", "value": "1" },
        { "name": "Bollinger", "value": "1" }
      ],
    },
    saidaCompra: {
      "message": "tem 4 sinais para sair da compra",
      "active": true,
      "tendenciaAcelerante": false,
      "bollingerStatus": -1,
      "stockStatus": -1,
      "trixStatus": -1,
      "rules": [
        { "name": 'TendenciaAcelerante', "value": 'false' },
        { "name": "Bollinger", "value": "-1" },
        { "name": "Stochastic", "value": "-1" },
        { "name": "Trix", "value": "-1" }
      ],
    },
    saidaVenda: {
      "message": "tem 4 sinais para sair da venda",
      "active": true,
      "tendenciaAcelerante": false,
      "bollingerStatus": -1,
      "stockStatus": 1,
      "trixStatus": 1,
      "rules": [
        { "name": 'TendenciaAcelerante', "value": 'false' },
        { "name": "Bollinger", "value": "-1" },
        { "name": "Stochastic", "value": "1" },
        { "name": "Trix", "value": "1" }
      ],
    },
    saidaCompraDidi: {
      "message": "deu saída da compra pelo Didi index",
      "active": true,
      "temTendencia": true,
      "alerta": -1,
      "pontofalso": 0,
      "rules": [
          { "name": "Alerta", "value": "-1" },
          { "name": "TendenciaExistente", "value": "true" },
          { "name": "PontoFalso", "value": "0" }
      ],
    },
    saidaVendaDidi: {
      "message": "deu saída da venda pelo Didi index",
      "active": true,
      "temTendencia": true,
      "alerta": 1,
      "pontofalso": 0,
      "rules": [
          { "name": "Alerta", "value": "1" },
          { "name": "TendenciaExistente", "value": "true" },
          { "name": "PontoFalso", "value": "0" }
      ],
    }
  }

  export default templates;