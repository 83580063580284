import React from 'react';
// import ReactGA from 'react-ga';
// import { NavLink } from 'react-router-dom';
import Seo from '../components/Seo/Seo';
// import { useNavigate } from 'react-router-dom';
import styles from './Pages.module.scss';
// import { useDetectScroll } from "@smakss/react-scroll-direction";

const PropTrader = () => {
	// window.gtag('event', 'page_view', {
	// 	page_title: "Seja um prop trader",
	// 	page_path: window.location.pathname + window.location.search,
	// 	page_location: window.location.href
	// })

	return (
		<>
			<Seo
				title="Seja um prop trader na FTP"
				description="Opere com o dinheiro de umas das mais conceituadas mesas proprietárias de trading do mundo."
				name="Agulhada.com"
				type="website" />
			<header className={`header`}>
				<h1 className="page-name">Seja um prop trader</h1>
			</header>
			<div className={styles.container}>
				<h2 className='text-2xl font-medium my-4'>Opere com o dinheiro de uma mesa proprietária</h2>
				<p className='mb-4'>Temos uma parceria com uma das mais conceituadas mesas proprietárias, com traders de todo o mundo.</p>
                <p className='mb-4'>Fique ligado e volte depois para mais informações sobre o que é prop trading e sobre essa incrível oportunidade de operar com um volume maior de dinheiro.</p>
			</div>
		</>
	);
};

export default PropTrader;
