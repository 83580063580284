import React, { useEffect, useState } from 'react';
import styles from "./SyncFavorites.module.scss";
import { ReactComponent as IconCloud } from "../../resources/cloudup.svg";
import { ReactComponent as IconSave } from "../../resources/save.svg";
import { ReactComponent as IconCancel } from "../../resources/cancel.svg";


export default function SyncFavorites(props) {
    const { updateLocalFavorites, updateRemoteFavorites, remoteFavoritesList, currentFavoritesList, favoriteList, currentListName } = props;
    const rf = remoteFavoritesList.filter(f=>f.id===currentFavoritesList)[0]?.assets.sort() || []
    const lf = favoriteList.filter(f=>f.id===currentFavoritesList)[0]?.assets.sort() || []

    const [remoteFavorites, setRemoteFavorites] = useState(rf)
    const [localFavorites, setLocalFavorites] = useState(lf)
    // const diffLocal = diffArrays(localFavorites, remoteFavorites)
    // const diffRemote = diffArrays(remoteFavorites, localFavorites)

    const [localChanged, setLocalChanged] = useState(false)
    const [remoteChanged, setRemoteChanged] = useState(false)

    useEffect(() => {
        console.log('remote fav changed')
        setRemoteFavorites(remoteFavoritesList.filter(f=>f.id===currentFavoritesList)[0]?.assets.sort() || [])
    }, [remoteFavoritesList, currentFavoritesList])

    useEffect(() => {
        setLocalFavorites(favoriteList.filter(f=>f.id===currentFavoritesList)[0]?.assets.sort() || [])
    }, [favoriteList, currentFavoritesList])

    // function resetLists() {
    //     setRemoteFavorites(rf)
    //     setLocalFavorites(lf)
    // }

    // function saveLocal() {
    //     updateLocalFavorites(localFavorites)
    // }

    // function saveRemote() {
    //     updateRemoteFavorites(remoteFavorites)
    // }

    return (
        <div className={styles['sync-bar']}>
            <div bp="flex">
                <div bp="fill">
                    <div bp="flex" className={styles["sync-bar__header"]}>
                    <span>Nuvem</span>
                    { remoteChanged && 
                    <>
                        <button className={styles["sync-bar__button"]} onClick={()=>{updateRemoteFavorites(remoteFavorites); setRemoteChanged(false)}}><IconCloud /></button>
                        <button className={styles["sync-bar__button"]} onClick={()=>{setRemoteFavorites(rf); setRemoteChanged(false)}}><IconCancel /></button>
                    </>
                    }
                    </div>
                    <div className={styles["sync-bar__button-all"]}>
                        <button onClick={()=>{setLocalFavorites([...remoteFavorites]); setLocalChanged(true)}}>Copiar lista &gt;&gt;</button>
                    </div>
                    <div>
                        Nome: {remoteFavoritesList[currentFavoritesList]?.name}
                    </div>
                    <ul className={styles["sync-bar__list"]}>
                        {remoteFavorites.map(function showRemoteList(asset, i){
                            return (
                                <li key={`R_${asset}_${i}`} bp="flex">
                                    <div>{asset}</div>
                                    <div style={{"minWidth":"35px"}}>
                                        {!localFavorites.includes(asset) &&
                                        <button
                                            onClick={()=>{setLocalFavorites(addAssetToArray(asset, localFavorites)); setLocalChanged(true)}}>&gt;
                                        </button>}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div bp="fill">
                    <div bp="flex" className={styles["sync-bar__header"]}>
                    <span>Dispositivo</span>
                    { localChanged && 
                    <>
                        <button className={styles["sync-bar__button"]} onClick={()=>{updateLocalFavorites(localFavorites); setLocalChanged(false)}}><IconSave /></button>
                        <button className={styles["sync-bar__button"]} onClick={()=>{setLocalFavorites(lf); setLocalChanged(false)}}><IconCancel /></button>
                    </>
                    }
                    </div>
                    <div className={styles["sync-bar__button-all"]}>
                        <button onClick={()=>{setRemoteFavorites([...localFavorites]); setRemoteChanged(true)}}>&lt;&lt; Copiar lista</button> 
                    </div>
                    <div>
                        Nome: {currentListName}
                    </div>
                    <ul className={styles["sync-bar__list"]}>
                        
                        {localFavorites.map(function showRemoteList(asset, i){
                            return (
                                <li key={`L_${asset}_${i}`} bp="flex">
                                    <div style={{"minWidth":"35px"}}>
                                        {!remoteFavorites.includes(asset) &&
                                        <button
                                            onClick={()=>{setRemoteFavorites(addAssetToArray(asset, remoteFavorites)); setRemoteChanged(true)}}>&lt;
                                        </button>}
                                    </div>
                                    <div bp="fill">{asset}</div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {/* {
            (diffLocal>0 || diffRemote>0)
            ?
            <>
            <div className={styles['sync-bar__message']}>Sincronizar <strong>{currentListName}</strong> com a nuvem.</div>
            <div bp="flex"  className={styles['sync-bar__button-array']}>
                {diffRemote>0&&<button bp="fill 12" onClick={pull} className={styles['sync-bar__button']}>Trazer {diffRemote} ativo{diffRemote>1&&"s"} novo{diffRemote>1&&"s"} da nuvem</button>}
                {diffLocal>0&&<button bp="fill 6" onClick={push} className={styles['sync-bar__button']}>Adicionar {diffLocal} ativo{diffLocal>1&&"s"} novo{diffLocal>1&&"s"} à nuvem</button>}
                {localFavorites.length>0&&<button bp="fill 6" onClick={upload} className={styles['sync-bar__button']}>Transferir esta lista com {localFavorites.length} ativo{localFavorites.length>1&&"s"} para a nuvem</button>}
                {remoteFavorites.length>0&&<button bp="fill 6" onClick={download} className={styles['sync-bar__button']}>Transferir a lista de {remoteFavorites.length} ativo{remoteFavorites.length>1&&"s"} da nuvem para este dispositivo</button>}
            </div>
            </>
            :
            <div className={styles['sync-bar__message']}>A lista "{currentListName}" está sincronizada com a nuvem.</div> 
            } */}
        </div>
    )
}

// function addArrays(newArray, currentArray) {
//     for (const symbol of newArray) {
//         if(!currentArray.includes(symbol)) {
//             currentArray.push(symbol)
//         }
//     }
//     return [...currentArray]
// }

// function diffArrays(baseArray, comparingArray) {
//     let n = baseArray.length;
//     if(n > 0) {
//         for (const symbol of baseArray) {
//             n -= comparingArray.includes(symbol) ? 1 : 0;
//         }
//     }
//     return n
// }

function addAssetToArray(asset, arr) {
    const ar = [...arr]
    if(!ar.includes(asset)) {
        ar.push(asset)
        ar.sort();
    }
    return ar
}
